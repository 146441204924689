.desktop-security-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 200px;
        width: 100%;
        max-width: 1120px;
        margin-top: 32px;
    }
    .page-card-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 48px;
        padding-right: 56px;
        .top-card{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 24px;
            padding-bottom: 24px;
            width: 100%;
            max-width: 1120px;
            .failed-devices-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                .failed-image{
                    height: 72px;
                    width: 72px;
                }
                .message-title{
                    margin-top: 24px;
                    margin-bottom: 8px;
                    color: var(--neutralGrey85)
                }
                .message-text{
                    color: var(--neutralGrey75);
                }
            }    
            .left-side{
                display: flex;
                flex-direction: column;
                width: 202px;
                gap: 8px;
                margin-right: 54px;
                .sign-in-header{
                    color: var(--neutralGrey75);
                }
                .sign-in-text{
                    color: var(--neutralGrey55);
                }
            }
            .right-side{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: 16px;
                &.row{
                    flex-direction: row;
                    justify-content: space-between;
                }
                .device-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    max-width: 384px;
                    width: 100%;
                    .headers{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .header-text{
                            color: var(--neutralGrey85);
                            min-width: 104px;
                        }
                    }
                    .device-row-wrap{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .data-text{
                            color: var(--neutralGrey75);
                            min-width: 104px;
                        }
                    }
                }
                .edit-wrap{
                    display: flex;
                    align-items: center;
                    align-self: flex-start;
                    gap: 8px;
                    cursor: pointer;
                    .blue-text{
                        color: var(--primaryBrandBlue);
                    }
                }
                .user-row{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .left{
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        .username-header{
                            color: var(--neutralGrey75);
                        }
                        .username-text{
                            color: var(--neutralGrey85)
                        }
                    }
                    .right{
                        display: flex;
                        flex-direction: row;
                        .edit-pencil{
                            height: 19px;
                            width: 19px;
                            cursor: pointer;
                        }
                        .edit-text{
                            position: relative;
                            bottom: 2px;
                            padding-left: 10px;
                            color: var(--primaryBrandBlue);
                            height: 20px;
                            cursor: pointer;
                        }   
                    }
                }
            }
        }
    }
}