.account-verification-wrap{
    display: flex;
    flex-direction: column;
    // padding: 32px;
    &.Mobile{
        padding: 16px 0px;
    }
    .header-wrap{
        display: flex;
        flex-direction: column;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
        gap: 4px;
        .header{
            color: var(--neutralGrey55);
        }
        .sub-header{
            color: var(--neutralGrey85);
        }
        &.Mobile{
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
    .data-wrap{
        display: flex;
        align-items: center;
        gap: 48px;
        padding-left: 24px;
        padding-right: 24px;
        &.Mobile{
            flex-direction: column;
            gap: 32px;
        }
        .left-side{
            display: flex;
            flex-direction: column;
            // flex: 1;
            .title-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                .title-message{
                    color: var(--neutralGrey85);
                    margin-top: 32px;
                    
                }
            }
            .image-wrapper{
                display: flex;
                justify-content: center;
                width: 100%;
            }
            .image{
                width: 64px;
                height: 64px;
                margin-bottom: 24px;
                &.Mobile{
                    text-align: center;
                }
            }
            .number-wrap{
                display: flex;
                align-items: flex-start;
                gap: 16px;
                &.first{
                    margin-bottom: 24px;
                }
                // .number-circle{
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     height: 24px;
                //     width: 24px;
                //     min-width: 24px;
                //     border-radius: 12px;
                //     background-color: var(--primaryBrandBlue);
                //     .number{
                //         color: var(--white);
                //     }
                // }
            }
            .main-text{
                color: var(--neutralGrey75);
                &.margin{
                    margin-top: 32px;
                    margin-bottom: 16px;
                }
                &.Mobile{
                    text-align: center;
                    margin-top: 16px;
                }
            }
            // .button-wrapper{
            //     display: flex;
            //     justify-content: center;
            //     width: 100%;
            //     margin-top: 32px;
            //     .Button{
            //         &.desktop{
            //             width: 100%;
            //             max-width: 330px;
            //         }
            //     }
            // }
        }
        .right-side{
            display: flex;
            flex: 1;
            position: relative;
            .bank-statement{
                &.Mobile{
                    width: 100%;
                    height: auto;
                }
            }
            .icon-header-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                position: absolute;
                left: 32px;
                top: 17px;
                // min-width: 40%;
                &.Mobile{
                    left: 14%;
                    top: 3.5%;
                }
                .nickname{
                    // background-color: var(--white);
                }
            }
        }
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey5);
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .button-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-side{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            .blue-text{
                color: var(--primaryBrandBlue);
            }
        }
        .right-side{
            display: flex;
            gap: 16px;
        }
    }
    .Button{
        &.mobile{
            width: 100%;
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }
}