.Footer-Wrapper{ 
    width: 100%;
    min-height: 67px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: var(--neutralBackgroundGrey);
    border-top: 1px solid var(--grey2);
    padding-left: 48px;
    padding-right: 56px;
    &.Mobile{
        padding-left: 16px;
        padding-right: 16px;
    }
    .Nav-Bar{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .Left-Side{
            display: flex;
            flex-direction: row;
            gap: 32px;
            // margin-left: 40px;
            .Link-Text{
                color: var(--neutralGrey75);
                // margin-left: 32px;
                cursor: pointer;
            }    
        }
    }
    .Copyright-Text{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // margin-right: 40px;
        color: var(--neutralGrey75);
    }
    .hybrid-wrapper{
        display: flex;
        flex-direction: column;
        // align-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 29px;
        .first-row{
            display: flex;
            flex-direction: row;
            gap: 24px;
            &.Mobile{
                justify-content: center;
                margin-bottom: 16px;
            }
            .Link-Text{
                cursor: pointer;
                color: var(--neutralGrey75);
            }
        }
    }
} 
