.Desktop-Layout-Wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .middle {
    width: 100%;
    margin-top: 32px;
    // min-height: calc(100vh - var(--headerHeight));
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    .Side-Navigation {
      background-color: var(--white);
      max-width: 216px;
      @media only screen and (max-wdith: 808px){
        width: 180px;
      }
    }
    .Children-Wrapper {
      padding-left: 216px;
      width: 100%;
      &.error {
        padding-left: 0px;
      }
      .Children {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - var(--headerHeight) - 32px);
        height: auto;
        width: 100%;
        background-color: var(--neutralBackgroundGrey);
        padding-top: calc(var(--headerHeight) - 4px);
      }
      .footer {
        width: 100%;
      }
    }
  }
}
