.Landing-Wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-background{
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        display: flex;
        justify-content: center;
        .header-wrapper{
            display: flex;
            padding-left: 48px;
            background-color: var(--secondaryDarkestBlue);
            width: 100%;
            justify-content: center;
        }
    }
    .card-wrapper.maturity-plan {
        display: flex;
        width: 100%;
        
        .container{
            justify-content: center;
    
            .desktop{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                background-color: var(--white);
                border-radius: 8px;
                margin-top: 32px;
                width: 100%;
                max-width: 1120px;
                margin-bottom: 64px;
                // min-height: 645px;
                .content-wrapper {
                    border-radius: 8px !important;
                    width: 100%;
                    padding: 40px 32px;
                    &.empty{
                        background: var(--grey5);
                    }
                    .desktop-maturity-loading-state{
                        height: 46vh;
                        width: 100%;
                        background-color: var(--grey5);
                        border-radius: 8px;
                    }
                    .card-header-wrap{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 32px;
                        border-bottom: 1px solid var(--grey5);
                        .title-text{
                            color: var(--neutralGrey85);
                            margin-bottom: 8px;
                        }
                        .mature-text{
                            color: var(--neutralGrey75);
                        }   
                    }
                    .column-cards-wrapper{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 40px;
                        gap: 24px;
                        .left-box{
                            display: flex;
                            flex-direction: column;
                            padding: 32px;
                            border: 1px solid var(--grey5);
                            border-radius: 8px;
                            width: 60%;
                            .maturity-plan-title{
                                color: var(--neutralGrey85);
                                margin-bottom: 24px;
                            }
                            .maturity-date-wrap{
                                display: flex;
                                flex-direction: column;
                                padding-bottom: 24px;
                                border-bottom: 1px solid var(--grey5);
                                gap: 2px;
                                .maturity-date-header{
                                    color: var(--neutralGrey85);
                                }
                                .maturity-date-text{
                                    color: var(--neutralGrey75);
                                }
                            }
                            .action-wrap{
                                display: flex;
                                flex-direction: row;
                                padding-top: 24px;
                                padding-bottom: 24px;
                                border-bottom: 1px solid var(--grey5);
                                justify-content: space-between;
                                align-items: center;
                                &.no-border{
                                    border: none;
                                    padding-bottom: 0px;
                                }
                                .left-side{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 2px;
                                    .action-header{
                                        color: var(--neutralGrey85);
                                    }
                                    .action{
                                        color: var(--neutralGrey75);
                                    }
                                }
                                .right-side{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    gap: 10px;
                                    cursor: pointer;
                                    .change-text{
                                        color: var(--primaryBrandBlue);
                                        &.remove{
                                            color: var(--darkRed);
                                        }
                                    }
                                }
                            }
                        }
                        .right-box{
                            display: flex;
                            flex-direction: column;
                            padding: 32px;
                            background-color: var(--neutralBackgroundGrey);
                            border: 1px solid var(--grey5);
                            border-radius: 8px;
                            flex: 1;
                            align-self: flex-start;
                            &.gracie{
                                height: auto;
                            }
                            .header-text{
                                color: var(--neutralGrey85);
                            }
                            .peronal-cd-wrap{
                                display: flex;
                                flex-direction: column;
                                gap: 2px;
                                margin-top: 24px;
                                .personal-cd-header{
                                    color: var(--neutralGrey85);
                                }
                                .personal-cd{
                                    color: var(--neutralGrey75);
                                }
                            }
                            .term-apy-row-wrap{
                                display: flex;
                                flex-direction: row;
                                gap: 24px;
                                >div{width: 50%}
                                margin-top: 24px;
                                .current-term-wrap{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 2px;
                                    .curernt-term-header{
                                        color: var(--neutralGrey85);
                                    }
                                    .current-term{
                                        color: var(--neutralGrey75);
                                    }
                                }
                                .apy-wrap{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 2px;
                                    .current-apy-header{
                                        color: var(--neutralGrey85);
                                    }
                                    .current-apy{
                                        color: var(--neutralGrey75);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}