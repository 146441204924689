.review-terms-wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    .header{
        color: var(--neutralGrey85);
        margin-bottom: 8px;
    }
    .sub-header{
        color: var(--neutralGrey75);
        margin-bottom: 40px;
    }
    .consents-wrap{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .account-stack{
            display: flex;
            flex-direction: column;
            border: 1px solid var(--neutralGrey15);
            border-radius: 8px;
            padding: 24px 40px;
            &.mobile{
                padding: 16px;
            }
            .row-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                cursor: pointer;
                .title-stack{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
                .title{
                    color: var(--neutralGrey85);               
                    &.mobile{
                        margin-bottom: 2px;
                    }
                }
                .blue-text{
                    color: var(--primaryBrandBlue);
                    &.disabled{
                        color: var(--neutralGrey55);
                    }
                }
                .reviewing-text{
                    color: var(--neutralGrey25);
                }
                .green-text{
                    color: var(--successGreen);
                }
                .completed-wrap{
                    display: flex;
                    align-items: center;
                    gap: 2px;
                }
            }
            .consent-details{
                display: flex;
                background-color: var(--neutralBackgroundGrey);
                border-radius: 8px;
                padding: 32px;
                margin-top: 24px;
                gap: 24px;
                &.mobile{
                    flex-direction: column;
                    padding: 24px 16px;
                }
                .data-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    flex: 1;
                    .label-value-wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        .label{
                            color: var(--neutralGrey85);
                        }
                        .value{
                            color: var(--neutralGrey75);
                        }
                    }
                }
            }
            .consent-checkbox-wrap{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 24px;
                margin-bottom: 32px;
                .agree-text{
                    color: var(--neutralGrey75);
                }
                .link-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                    text-decoration: none;
                }
            }
            .button-wrap{
                display: flex;
                justify-content: center;
                .Button{
                    width: 147px;
                    &.mobile{
                        width: 100%;
                    }
                }
            }
        }
    }
}