.header-box-wrap{
    display: flex;
    flex-direction: column;
    background-color: var(--secondaryDarkestBlue);
    padding: 16px;
    gap: 16px;
    .top-row{
        display: flex;
        align-items: center;
        .image{
            cursor: pointer;
        }
        .return-text{
            color: var(--white);
            padding-left: 8px;
            cursor: pointer;
        }
    }
    .title-text{
        color: var(--white);
    }
}