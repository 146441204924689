.delete-external-account-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 995;
    .modal-details{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--white);
        border-radius: 8px;
        padding: 48px;
        &.Mobile{
            padding: 32px;
            margin-left: 16px;
            margin-right: 16px;
        }
        .image{
            width: 64px;
            height: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            text-align: center;
            margin-top: 16px;
            margin-bottom: 8px;
            width: 100%;
            max-width: 412px;
        }
        .sub-text{
            color: var(--neutralGrey75);
            text-align: center;
            width: 100%;
            max-width: 412px;
        }
        .button-wrap{
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 32px;
            &.mobile{
                flex-direction: column;
                width: 100%;

            }
            .Button{
                &.mobile{
                    width: 100%;
                }
            }
        }
    }
}