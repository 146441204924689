.mobile-contact-page-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;
    .loading-state-wrapper{
        display: flex;
        flex-direction: column;
        gap: 16px;
        .loading-header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 24px 16px;
            background-color: var(--secondaryDarkestBlue);
            .top-box{
                background-color: var(--white);
                opacity: .1;
                border-radius: 8px;
                width: 160px;
                height: 24px;
            }
            .bottom-box{
                background-color: var(--white);
                opacity: .1;
                border-radius: 8px;
                height: 28px;
                width: 200px;
            }
        }
        .loading-box{
            height: 200px;
            border-radius: 8px;
            background-color: var(--grey5);
            margin-left: 16px;
            margin-right: 16px;
        }
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        padding: 16px 16px 24px;
        .card-title{
            color: var(--neutralGrey75);
        }
        .card-sub-title{
            margin-top: 8px;
            color: var(--neutralGrey55);
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .data-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.margin{
                margin-bottom: 16px;
            }
            &.gap{
                margin-bottom: 16px;
            }
            .left-side{
                display: flex;
                flex-direction: column;
                gap: 2px;
                &.email{
                    width: 100%;
                    max-width: 85%;
                }
                .value-text{
                    &.email{
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .mailing-pill{
                    padding: 4px 12px;
                    background-color: var(--grey5);
                    border-radius: 20px;
                    max-width: 67px;
                    color: var(--neutralGrey85);
                }
            }
            .edit-image{
                cursor: pointer;
            }
        }
        .add-value-wrap{
            display: flex;
            align-items: center;
            .add-image{
                cursor: pointer;
            }
            .link-text{
                color: var(--primaryBrandBlue);
                padding-left: 8px;
                cursor: pointer;
            }

        }
    }
}