.desktop-account-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding-left: 48px;
  padding-right: 56px;
  .accounts-displayed {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    padding-top: 16px;
    max-width: 1120px;
    width: 100%;
    .account-type-wrapper {
      max-width: 1120px;
      width: 100%;
      margin-top: 32px;
      .title-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        padding-right: 40px;
        .account-title {
          // font-size: 20px;
        }
      }
      .group-display-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 8px;
        .account-wrap-headers{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 40px;
          border-bottom: 1px solid var(--grey5);
          .right-side{
            display: flex;
            align-items: center;
            gap: 12%;
            width: 38%;
          }
          .header-text{
            color: var(--neutralGrey85);
            &.apy{
              text-align: right;
              width: 100%;
              max-width: 20%
            }
            &.balance{
              text-align: right;
              width: 100%;
              max-width: 54%;
              margin-right: 8%;
            }
          }
        }
        .account-box-wrapper {
          padding: 24px 0px;
          border-bottom: 1px solid var(--grey5);
          &:last-child{
            border: none;
          }
        }
      }
      .banner-wrap{
        display: flex;
        margin-top: 16px;
        position: relative;
        .banner-image{
          resize: horizontal;
          width: 100%;
          max-width: 1120px;
        }
        .Button{
          position: absolute;
          cursor: pointer;
          left: 3.6%;
          bottom: 11.4%;
          height: 20.6%;
        }
        .tooltip-wrap-one{
          position: absolute;
          left: 42%;
          top: 29.4%;
        }
        .tooltip-wrap-two{
          position: absolute;
          left: 52.8%;
          top: 41%;
        }
        .tooltip-wrap-three{
          position: absolute;
          left: 62%;
          top: 29%;
        }
        .tooltip{
          background-color: var(--white);
          border-radius: 8px;
        }
        .tooltip-message-wrap{
          margin-top: -24px;
          margin-left: -8px;
        }
      }
    }
    
    .new-account-button {
      width: 100%;
      display: block;
      margin: 56px 0 24px;
    }
    .current-rates{
      color: var(--neutralGrey75);
      margin-bottom: 64px;
      .current-rates-link{
        color: var(--primaryBrandBlue);
        cursor: pointer;
      }
    }
  }
  .total-balance-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    padding-top: 32px;
    max-width: 1120px;
    width: 100%;
  }

  .overview-loading-state{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin-bottom: 60px;
  }

  .customer-notifications-wrapper{
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
  .customer-notifications-row{
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
}