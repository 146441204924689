.desktop-early-tooltip-wrap{
    display: flex;
    flex-direction: column;
    z-index: 990;
    position: absolute;
    width: 100%;
    max-width: 576px;    
    &.top-right{
        margin-right: -10px;
    }
    .shadow{
        -webkit-filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
        position: absolute;
        top: 22px;
        width: 22px;
        height: 18px;
        margin-left: 10px;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
        &.top-right{
            right: 10px;
        }
    }
    .message-wrap{
        position: absolute;
        margin-top: 38px;
        padding: 32px 16px;
        background-color: var(--white);
        border-radius: 8px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        z-index: 990;
        width: 100%;
        max-width: 576px; 
        display: flex;
        flex-direction: column; 
        gap: 4px;
        .header-message{
            color: var(--neutralGrey75);
        }
        .message-text{
            color: var(--neutralGrey75);
        }
    }
}

.mobile-early-tooltip-wrap{
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    z-index: 995;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    .message-wrapper{
        position: fixed;
        bottom: 0px;
        left:0px;
        right: 0px;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        border-radius: 8px 8px 0px 0px;
        .header-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 16px;
            border-bottom: 1px solid var(--grey5);
            .blue-text{
                position: absolute;
                color: var(--primaryBrandBlue);
                right: 16px;
                cursor: pointer;
            }
        }
        .body-wrap{
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 4px;
            .body-text{
                color: var(--neutralGrey85);
                padding-left: 4px;
                &.first{
                    padding-left: 0px;
                    margin-bottom: 16px;
                }
            }
        }
        .link-text{
            color: var(--neutralGrey85);
            padding: 16px;
            .blue-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
    }   
}