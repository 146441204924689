.desktop-loading-state-account-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1120px;
    // @media only screen and (min-width: 1440px) {
    //     width: 1120px;
    //   }
    //   @media only screen and (min-width: 1136px) and (max-width: 1439px) {
    //     width: 800px;
    //   }  
    //   @media only screen and (min-width: 1080px) and (max-width: 1135px) {
    //     width: 700px;
    // }      
    .top-box{
        height: 32px;
        width: 278px;
        background-color: var(--grey5);
        border-radius: 12px;
        margin-top: 32px;
        margin-bottom: 24px;
    }
    .bottom-box{
        height: 100px;
        width: 100%;
        max-width: 1120px;
        background-color: var(--grey5);
        border-radius: 12px;
    }
}
.mobile-loading-state-account-box{
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    .top-box{
        height: 28px;
        width: 278px;
        background-color: var(--grey5);
        border-radius: 12px;
        margin-top: 42px;
        margin-bottom: 12px;
    }
    .bottom-box{
        height: 80px;
        width: 100%;
        background-color: var(--grey5);
        border-radius: 12px;
    }
}