.Beneficiary-Row-Wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    .Details-Wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        .Dot{
            position: relative;
            bottom: 5px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        .Name-Allocation-Buttons-Wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-grow: 1;
            margin-left: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            .Name-Allocation-Wrap{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                align-items: center;
                
                .name-wrap{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
                .Member-Name{
                    color: var(--headerBlack);
                }
                .Input-Box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 84px;
                    height: 48px;
                    border: 1px solid var(--neutralGrey25);
                    padding-left: 16px;
                    padding-right: 12px;
                    border-radius: 4px;
                    .Box{
                        border: none;
                        width: 35px;
                        height: 30px;
                        &:focus{
                            outline: none;
                        }
                    }
                    .Percentage{
                        text-align: right;
                        margin-left: 4px;
                    }
                }
                .Error-Box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 84px;
                    height: 48px;
                    border: 1px solid var(--red);
                    padding-left: 16px;
                    padding-right: 12px;
                    border-radius: 4px;
                    .Box{
                        color: var(--red);
                        border: none;
                        width: 35px;
                        height: 30px;
                        &:focus{
                            outline: none;
                        }
                    }
                    .Percentage{
                        text-align: right;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
    .Allocation-Error-Message{
        display: flex;
        justify-content: flex-end;
        color: var(--red);
        margin-top: -10px;
        padding-bottom: 10px;
    }
    .box-text{
        text-align: right;
        padding-right: 5px;
    }
}
