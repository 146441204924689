.desktop-documents-account-agreements{
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 8px;   
    .display-rows{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .display-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid var(--grey5);        
            &:last-child{
                border-bottom: none;
            }
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 23px;
                .document{
                    padding-left: 16px;
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .document-image{
                    cursor: pointer;
                }
            }
        }
    }
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 300px;
    }
    .message-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        .message-image{
            width: 64px;
            height: 64px;
        }
        .header-text{
            margin-top: 16px;
            margin-bottom: 8px;
            color: var(--neutralGrey85);
        }
        .sub-text{
            color: var(--neutralGrey75);
        }
    }
}