.transfer-details-panel-wrap{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 993;
    &.mobile{
        background-color: var(--neutralBackgroundGrey);
        bottom: calc(0px + var(--mobileNavHeight));
        // z-index:992;
        // position:initial;
    }
    .header-wrap{
        display: flex;
        align-items: center;
        padding: 16px;
        margin-top: calc(var(--mobileHeaderHeight) + var(--mobileFDICHeight));
        .close-image{
            cursor: pointer;
        }
        .header-text{
            padding-left: 8px;
            cursor: pointer;
        }
    }
    .side-panel-wrap{
        position: fixed;
        right: 0px;
        top: calc(0px + var(--headerHeight) + var(fdicHeight));
        bottom: 0px;
        display: flex;
        flex-direction: column;
        width: 464px;
        background-color: var(--white);
        height: calc(100vh - var(--headerHeight) - var(--fdicHeight) + 3px);
        overflow-y: auto;
        &.mobile{
            width: auto;
            height: auto;
            border: 1px solid var(--grey5);
            border-radius: 8px;
            left: calc(0px + 16px);
            right: calc(0px + 16px);
            top: calc(0px + var(--mobileHeaderHeight) + 72px + var(--mobileFDICHeight));
            bottom: calc(0px + var(--mobileNavHeight));
            margin-bottom: 32px;
        }
        .side-header-wrap{
            display: flex;
            align-items: center;
            padding: 24px;
            &.desktop{
                border-bottom: 1px solid var(--grey5);
            }
            &.mobile{
                padding-top: 32px;
                padding-left: 16px;
                padding-right: 16px;
                padding-bottom: 16px;
            }
            .close-image{
                cursor: pointer;
            }
            .header-text{
                width: 100%;
                color: var(--neutralGrey85);
                &.desktop{
                    text-align: center;
                }
            }
        }
        .amount-wrap{
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 16px;
            .label-text{
                color: var(--neutralGrey75);
            }
            .amount-pill-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                .amount-value{
                    color: var(--neutralGrey85);
                }
                .status-pill{
                    background-color: var(--bluePill);
                    border-radius: 20px;
                    padding: 2px 12px 4px;
                    color: var(--primaryBrandBlue);
                }
            }
        }
        .data-wrap{
            display: flex;
            flex-direction: column;
            .data-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 24px;
                &.mobile{
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 16px;
                    gap: 4px;
                }
                .label{
                    color: var(--neutralGrey75);
                }
                .value{
                    color: var(--neutralGrey85);
                }
            }
            .bottom-border{
                margin-left: 24px;
                margin-right: 24px;
                border-bottom: 1px solid var(--grey5);
            }
            .button-wrap{
                padding-left: 24px;
                padding-right: 24px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
                .Button{
                    cursor: pointer;
                }
            }
            .cancel-wrap{
                display: flex;
                align-items: center;
                padding: 24px;
                .cancel-image{
                    cursor: pointer;
                }
                .cancel-transfer-text{
                    cursor: pointer;
                    color: var(--darkRed);
                    padding-left: 8px;
                }
            }
        }
    }
}