.mobile-amount-filter-wrap{
    display: flex;
    flex-direction: column;
    .input-box-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 12px;
        gap: 24px;
    }
}