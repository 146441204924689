.mobile-verify-ext-account-wrapper{
    display: flex;
    flex-direction: column;
    .cancel-text{
        color: var(--primaryBrandBlue);
        padding: 16px;
        text-align: right;
        cursor: pointer;
    }
    .validate-success-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border-radius: 8px;
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 64px 16px;
        .success-image{
            height: 64px;
            width: 64px;
        }
        .success-title{
            color: var(--neutralGrey85);
            margin-top: 24px;
            margin-bottom: 24px;
            text-align: center;
        }
        .success-text{
            color: var(--neutralGrey75);
            text-align: center;
        }
        .success-button-wrapper{
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 32px;
            .Button{
                width: 100%;
            }
        }
    }
    .data-card{
        display: flex;
        flex-direction: column;
        padding: 24px 16px;
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--white);
        border-radius: 8px;
        .title-text{
            color: var(--neutralGrey85);
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
        }
        .info-text{
            color: var(--neutralGrey75);
            .blue-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .error-wrapper{
            display: flex;
            flex-direction: row;
            // align-items: center;
            align-self: flex-start;
            gap: 16px;
            border: 1px solid var(--errorBorder);
            background-color: var(--errorBackground);
            padding: 24px; 
            border-radius: 8px;
            margin-top: 24px;
            margin-bottom: 8px;
            width: 100%;
            .image{
                height: 32px;
                width: 32px;
            }
            .message-stack{
                display: flex;
                flex-direction: column;
                gap: 4px;
                .error-title{
                    color: var(--darkRed);
                }
                .error-text{
                    color: var(--neutralGrey85);
                }
            }
        }                
        .bankname-wrap{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 24px;
            margin-bottom: 24px;
        }
        .inputs-column{
            display: flex;
            flex-direction: column;
            gap: 24px;
            .input-wrap{
                display: flex;
                flex-direction: column;
                gap: 2px;
                width: 100%;
                .label-row{
                    display: flex;
                    align-items: center;
                    .label-text{
                        color: var(--neutralGrey85);
                        &.error{
                            color: var(--darkRed);
                        }
                        .red-star{
                            color: var(--darkRed);
                        }
                    }
                }
                .input-symbol-wrap{
                    display: flex;
                    align-items: center;
                    border: 1px solid var(--neutralGrey15);
                    color: var(--neutralGrey85);
                    padding: 12px 16px;
                    border-radius: 4px;
                    width: 100%;
                    &.error{
                        border: 2px solid var(--darkRed);
                    }                    
                    .input-box{
                        width: 100%;
                        color: var(--neutralGrey85);
                        border: none;
                    }
                }
            }
        }
        .Button{
            &.margin{
                margin-top: 32px;
            }
        }
    }
    .modal-overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 995;
        .modal-card{
            display: flex;
            flex-direction: column;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: var(--white);
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            .header-wrap{
                display: flex;
                align-items: center;
                position: relative;
                padding: 16px;
                justify-content: center;
                border-bottom: 1px solid var(--grey5);
                .header-text{
                    color: var(--neutralGrey85);
                }
                .blue-text{
                    position: absolute;
                    right: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .modal-data{
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 16px;
                .modal-text{
                    color: var(--neutralGrey85);
                }
                .image-wrap{
                    display: flex;
                    flex: 1;
                    position: relative;
                    margin-bottom: 16px;
                    padding-left: 16px;
                    padding-right: 16px;
                    .bank-statement{
                        width: 100%;
                        height: auto;
                    }
                    .icon-header-wrap{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        position: absolute;
                        left: 12%;
                        top: 6.3%;
                        .nickname{
                            // background-color: var(--white);
                        }        
                    }
                }
            }
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }    
}