.Pill {
  text-align: center;
  color: var(--warmOrange);
  //width: auto;
  //height: 24px;
  display: inline-block;
  background: var( --backgroundOrange);
  border-radius: 20px;
  padding:0 12px;

  .title{
    line-height: 24px;
  }
}
