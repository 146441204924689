.desktop-withdrawal-landing{
    display: flex;
    flex-direction: column;
    .withdrawal-landing-page-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .card-wrapper{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            padding: 40px 32px;
            margin-top: 32px;
            margin-bottom: 64px;
            width: 100%;
            max-width: 1120px;
            .header-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-bottom: 32px;
                .header-text{
                    color: var(--neutralGrey85);
                }
                .sub-header-text{
                    color: var(--neutralGrey75);
                    max-width: 605px;
                }
            }
            .top-box{
                display: flex;
                flex-direction: column;
                padding: 24px;
                border: 1px solid var(--grey5);
                border-radius: 8px;
                .header-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 9px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid var(--grey5);
                    .title-text{
                        color: var(--neutralGrey75);
                    }
                    .tool-image{
                        height: 22px;
                        width: 22px;
                    }
                }
                .middle-wrap{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid var(--grey5);
                }
                .bottom-wrap{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    margin-top: 24px;
                }
                .top{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 390px;
                    .left-side{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .tool-image{
                            position: relative;
                        }
                        .tooltip-wrap{
                            margin-left: -40px;
                            margin-bottom: 16px;
                        }
                    }
                    .balance-red{
                        color: var(--darkRed);
                    }
                }
                .bottom{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 390px;
                    justify-content: space-between;
                }
                .row-text{
                    color: var(--neutralGrey85);
                }
                .row-value{
                    color: var(--neutralGrey85);
                }
            }
            .bottom-box{
                display: flex;
                flex-direction: column;
                background-color: var(--neutralBackgroundGrey);
                margin-top: 16px;
                padding: 24px;
                border-radius: 8px;
                gap: 24px;
                .top{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 390px;
                    .disclaim-text{
                        color: var(--neutralGrey85);
                    }
                    .disclaim-text-value{
                        color: var(--black);
                    }
                }
                .maturity-route-link-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .normal-text{
                        color: var(--neutralGrey55);
                    }
                    .link-text{
                        color: var(--primaryBrandBlue);
                        cursor: pointer;
                    }
                }
            }
            .contact-information-wrap{
                display: flex;
                align-items: center;
                margin-top: 48px;
                .image{
                    margin-right: 8px;
                }
                .blue-text{
                    color: var(--primaryBrandBlue);
                    text-decoration: none;
                }
                .help-text{
                    color: var(--neutralGrey75);
                }
            }
            // .buttom-wrap{
            //     display: flex;
            //     flex-direction: row;
            //     align-items: center;
            //     gap: 16px;
            //     margin-top: 24px;
            //     justify-content: flex-end;
            //     .early-button{
            //         width: 323px;
            //         height: 48px;
            //         border: 1px solid var(--grey5);
            //         border-radius: 54px;
            //         background-color: var(--white);
            //         color: var(--primaryBrandBlue);
            //         cursor: pointer;
            //     }
            //     .cancel-button{
            //         width: 124px;
            //         height: 48px;
            //         background-color: var(--primaryBrandBlue);
            //         color: var(--white);
            //         border: 1px solid var(--primaryBrandBlue);
            //         border-radius: 54px;
            //         cursor: pointer;
            //     }
            // }
        }
    }
}