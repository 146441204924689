.desktop-edit-account-wrapper{
    display: flex;
    flex-direction: column;
    .label{
        display: flex;
        justify-content: flex-start;
        color: var(--white);
        padding-bottom: 4px;
    }
    .input-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 4px;
        .input-box-wrapper{
            background-color: var(--white);
            padding-left: 16px;
            padding-right: 16px;
            .input-box{
                width: 371px;
                height: 48px;
                padding-right: 16px;
                border: none;
            }
            .reset-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .icon-wrapper{
            margin-left: 28px;
            .check-icon{
                margin-right: 16px;
                cursor: pointer;
            }
            .cancel-icon{
                cursor: pointer;
            }
        }
    }
}