.desktop-manage-devices-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    .change-header{
        display: flex;
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .text-wrap{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            .header-image{
                cursor: pointer;
            }
            .header-text{
                color: var(--white);
                padding-left: 8px;
                cursor: pointer;
            }
        }
    }
    .devices-card-wrapper{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        width: 100%;
        margin-top: 40px;
        .data-wrap{
            display: flex;
            flex-direction: column;
            padding: 32px;
            border-radius: 8px;
            background-color: var(--white);
            width: 100%;
            max-width: 1120px;
            .header-text{
                color: var(--neutralGrey85);
            }
            .bottom-border{
                margin-top: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
            }
            .table-headers{
                display: flex;
                align-items: center;
                width: 100%;
                gap: 24px;
                margin-bottom: 8px;
                padding-left: 8px;
            }
            .column-size-one{
                width: 22.2%;
                color: var(--neutralGrey75);
            }
            .column-size-two{
                width: 16.7%;
                color: var(--neutralGrey75);
            }
            .column-size-three{
                flex: 1;
                color: var(--neutralGrey75);
                &.right-side{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .right{
                        display: flex;
                        align-items: center;
                        gap: 24px;
                        .current-device-pill{
                            padding: 2px 12px 4px;
                            color: var(--primaryBrandBlue);
                            background-color: var(--fleetBlue);
                            border-radius: 20px;
                        }
                        .image-wrap{
                            position: relative;
                            .elipses{
                                cursor: pointer;
                            }
                            .menu-wrap{
                                display: flex;
                                flex-direction: column;
                                background-color: var(--white);
                                border: 1px solid var(--neutralGrey15);
                                box-shadow: 0px 8px 24px rgba(38, 38, 38, 0.1);
                                border-radius: 8px;
                                position: absolute;
                                right: 1px;
                                top: -3px;
                                z-index: 990;
                                width: 227px;
                                .menu-row{
                                    display: flex;
                                    align-items: center;
                                    gap: 16px;
                                    padding: 16px;
                                    cursor: pointer;
                                    .blue-text{
                                        color: var(--primaryBrandBlue);
                                    }
                                    .red-text{
                                        color: var(--darkRed);
                                    }
                                }
                                .border-bottom{
                                    border-bottom: 1px solid var(--grey5);
                                }
                            }
                        }
                    }
                }
            }
            .device-row-wrap{
                display: flex;
                align-items: center;
                padding: 16px 8px;
                gap: 24px;
                border-bottom: 1px solid var(--grey5);
                &:last-child{
                    border: none;
                }
            }
            .delete-modal-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                position: fixed;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-color: rgba(0,0,0,0.4);
                z-index: 994;
                .modal-wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: var(--white);
                    padding: 48px;
                    border-radius: 8px;
                    max-width: 528px;
                    .title-text{
                        color: var(--neutralGrey85);
                        margin-top: 16px;
                        margin-bottom: 8px;
                        text-align: center;
                    }
                    .modal-text{
                        color: var(--neutralGrey75);
                    }
                    .button-wrap{
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        margin-top: 32px;
                        width: 100%;
                        .Button{
                            &.cancel{
                                max-width: 124px;
                            }
                            &.remove{
                                flex: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}