.compliance-questions-wrapper{
    display: flex;
    flex-direction: column;
    .header-text{
        color: var(--neutralGrey85);
        margin-top: 8px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
    }
    .required-text{
        color: var(--neutralGrey75);
        margin-bottom: 32px;
    }
    .red-text{
        color: var(--darkRed);
    }
    .question-wrap{
        display: flex;
        flex-direction: column;
        &.margin{
            margin-top: 56px;
        }
        .question-text{
            color: var(--neutralGrey85);
            margin-bottom: 16px;
            .required-star{
                color: var(--darkRed);
                padding-left: 4px;
                position: relative;
                bottom: 4px;
            }
        }
        .row-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;
            border: 1px solid var(--grey5);
            border-radius: 4px;
            margin-bottom: 8px;
            width: 100%;
            max-width: 311px;
            cursor: pointer;
            &.selected{
                background-color: rgba(10, 94, 192, 0.1);
                border: 1px solid var(--primaryBrandBlue);
            }
            &.mobile{
                max-width: 100%;
            }
        }
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey5);
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .button-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-side{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
        }
        .right-side{
            display: flex;
            align-items: center;
            gap: 16px;
        } 
        .blue-text{
            color: var(--primaryBrandBlue);
        }  
    }
    .Button{
        &.mobile{
            margin-top: 24px;
            margin-bottom: 8px;
        }
    }
}