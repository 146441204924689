.mobile-account-overview-top{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // padding-bottom: 16px;
    border-bottom: 1px solid rgba(256,256,256,.2);
    overflow-y: auto;
    .return{
        display: flex;
        flex-direction: row;
        align-items: center;
        .text{
            color: var(--white);
            padding-left: 10px;
        }        
    }
    .product-account-name{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        .back-to-accounts{
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 16px;
            .arrow-icon{
                width: 22px;
                height: 24px;
                cursor: pointer;
            }    
            .accounts-text{
                cursor: pointer;
                color: var(--white);
            }
        }
        .product-description{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            .description {
                color: var(--white);
                margin-right: 11px;
            }
            .edit-icon{
                height: 20px;
                width: 20px;
                cursor: pointer;
            }
        }
        .acocount-type-number-wrap{
            display: flex;
            gap: 8px;
            align-items: center;
            margin-top: 8px;
            .account-type-number{
                color: var(--secondayLightBlue);
            }
            .loyalty-icon{
                cursor: pointer;
            }
        }

        .pill-wrap{
            width: auto;
            max-width: 50%;
            margin-top: 16px;
        }
    }
    .mobile-top-loading{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 16px;
        padding-bottom: 16px;
        .loading-box-1{
            height: 24px;
            width: 30%;
            background-color: var(--white);
            opacity: 0.1;
            border-radius: 8px;
            &.top{
                margin-bottom: 8px;
            }
        }
        .loading-box-2{
            height: 32px;
            width: 80%;
            background-color: var(--white);
            opacity: 0.1;
            border-radius: 8px;
        }
    }
}