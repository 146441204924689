.grace-transfer-funds-page-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .transfer-funds-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 8px;
        padding: 32px;
        margin-top: 32px;
        margin-bottom: 64px;
        width: 100%;
        max-width: 1120px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
        .fund-transfer-text{
            color: var(--neutralGrey55);
            padding-top: 8px;
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid var(--grey5);
        }
        .radio-button-wrap{
            display: flex;
            flex-direction: row;
            margin-top: 9px;
            gap: 8px;
            .radio-button-selection{
                display: flex;
                flex-direction: row;
                padding-top: 18px;
                padding-bottom: 18px;
                width: 300px;
                border-radius: 4px;
                background-color: var(--white);
                border: 1px solid var(--neutralBackgroundGrey);
                &.selected{
                    border: 1px solid var(--primaryBrandBlue);
                    background-color: var(--neutralBackgroundGrey);
                }
            }
        }
        .label-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            max-width: 565px;
            .input-box-wrap{
                display: flex;
                align-items: center;
                padding: 16px;
                flex: 1;
                border: 1px solid var(--neutralGrey25);
                border-radius: 4px;
                &.error{
                    border: 1px solid var(--darkRed);
                }
                .input-box{
                    display: flex;
                    flex: 1;
                    border: none;
                    width: 100%;
                }
            }
            .available-balance{
                margin-left: 4px;
                color: var(--neutralGrey55);
            }
            .error-text{
                color: var(--darkRed);
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }            
        }
        .dropdown-wrapper{
            margin-top: 24px;
            max-width: 565px;
        }
        .options-wrap{
            display: flex;
            flex-direction: row;
            gap: 51px;
            align-items: center;
            margin-top: 44px;
            padding-bottom: 44px;
            border-bottom: 1px solid var(--grey5);
            .option{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                .option-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
        .button-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2px;
                cursor: pointer;
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
            .right-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                .cancel-button{
                    width: 124px;
                    height: 48px;
                    color: var(--primaryBrandBlue);
                    background-color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    cursor: pointer;
                }
                .continue-button{
                    width: 146px;
                    height: 48px;
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    cursor: pointer;
                    &.disable{
                        background-color: var(--neutralGrey25);
                        border: 1px solid var(--neutralGrey25);
                        color: var(--white);
                    }
                }
            }
        }
    }
}