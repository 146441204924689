.text-input-wrap{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}
.pass{
    height: 48px;
    border: 1px solid var(--neutralGrey25);
    padding: 8px 16px;
    border-radius: 3px;
    color: var(--neutralGrey75);
    &.error{
        border: 2px solid var(--darkRed);
    }
}
input {
    color: var(--neutralGrey75);
    &:focus {
      outline: none;
    }
}      
