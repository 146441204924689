.reroute-page {
  display: flex;
	flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #E9E9E9;
  .fdic-convergence-wrapper{
    display: flex;
    justify-content: center;
    height: 32px;
    width: 100vw;
    background-color: var(--fdicBlue);
    &.Mobile{
      top: 95px;
      height: 48px;
    }
  }
  .header-wrapper{
    top: 32px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    background-color: var(--primaryBrandBlue);
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    &.Mobile{
      top: 0px;
    }
    .bank-icon{
      &.Mobile{
        width: 180px;
      }
    }
  }
  .container {
    background-color: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    padding: 40px 60px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    margin-top: -118px;
    &.Mobile{
      margin-top: 32px;
    }

    &.cc-picker-container {
      border: 1px solid var(--neutrals-grey-25, #BEBEBE); 
      box-shadow: none;
      padding: 48px 40px;
      width: 450px;
      text-align:center;
      img {
        align-self: center;
        width: 128px;
      }
    }

    .secondary-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.107px;
    }

    @media screen and (max-width: 700px) {
      margin: 0px 16px;
      margin-bottom : 64px;

      &.cc-picker-container {
        width: calc(100vw - 32px)
      }
      img {
        width: 90px !important;
      }
      .bottom {
        flex-direction: column;
        gap: 32px;
      
  

      }
      
    }

    .top {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }
    
    img {
      width:181px;
    }
    .bottom {
      display:flex;
      justify-content: space-between;
      flex:1;
      button:first-child {
        margin-right: 32px;
      }
      button {
        flex: 0;
        padding: 24px 40px;
        border: 1px solid var(--neutral-grey-15, #D8D8D8);
        cursor: pointer;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        // outline: solid 20px red;
        &:active {
          box-shadow: 0 0 0 3px var(--primary-brand-blue, #0A5EC0);
        }

        &:active div {
          color: var(--primary-brand-blue, #0A5EC0);
          transition: none;
        }
        
        div {
          transition: none;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 160% */
        }
      }
    }
  }
}