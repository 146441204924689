.mobile-history-card-wrap{
    display: flex;
    flex-direction: column;
    .title-text{
        color: var(--neutralGrey85);
    }
    .table-wrap{
        display: flex;
        flex-direction: column;
        .transfer-row-wrap{
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid var(--grey5);
            padding: 16px 0px;
            gap: 16px;
            cursor: pointer;
            &.green{
                animation: fade-background 6s ease-in-out;
            }
            &:last-child{
                border: none;
            }
            @keyframes fade-background {
                0%{
                    background-color: var(--fadedGreen);
                }
                80%{
                    background-color: var(--fadedGreen);
                }
                100%{
                    background-color: var(--white);
                }
            }    
            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: flex-start;
                width: 100%;
                .date-text{
                    color: var(--neutralGrey55);
                }
                .title-text{
                    color: var(--neutralGrey85);
                    max-width: 204px;
                    width: 100%;
                }
                .right{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .currency-text{
                        color: var(--neutralGrey85);
                    }
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 18px;
                .date-text{
                    color: var(--neutralGrey55);
                }
            }
        }
        .pagination-wrapper{
            margin-top: 24px;
        }
    }
    .empty-state-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .bottom-border-line{
            margin-top: 24px;
            width: 100%;
            border-bottom: 1px solid var(--grey5);
        }
        .icon{
            height: 72px;
            width: 72px;
        }
        .text-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            .text{
                text-align: center;
            }
        }
    }
}