.modify-address-modal-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    background-color: rgba(0,0,0,0.4);
    .modal-wrapper{
        display: flex;
        flex-direction: column;
        max-width: 552px;
        max-height: 90vh;
        z-index: 995;
        padding: 48px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        background-color: var(--white);
        border-radius: 8px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            padding: 0px;
        }
        .inner-wrapper{
            display: flex;
            flex-direction: column;
            max-width: 456px;
            &.mobile{
                padding: 32px;
            }
            .image-wrapper{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: center;
                .loading-circle{
                    width: 40px;
                    height: 40px;
                }
            }
            .modal-message{
                margin-top: 20px;
                color: var(--neutralGrey85);
                text-align: center;
            }
            .update-box-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 40vh;
                // margin-bottom: 36px;
                margin-top: 24px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 8px;
                }            
                &.top-border{
                    border-top: 1px solid var(--grey4); 
                }
                .from-text{
                    color: var(--neutralGrey85);
                }
                .address-text{
                    color: var(--neutralGrey85);
                }
                .city-text{
                    color: var(--neutralGrey85);
                }
                .currently-selected-address{
                    width: 100%;
                    padding: 16px;
                    background-color: var(--neutralBackgroundGrey);
                    border: 1px solid var(--grey4);
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                }
                .options-box-wrapper{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border: 1px solid var(--grey4);
                    border-radius: 8px;
                    .address-wrap{
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        gap: 26px;
                        margin-left: -16px;
                        margin-top: 16px;
                        margin-bottom: 16px;
                        margin-right: 16px;
                        cursor: pointer;
                        &.no-radio{
                            margin-left: 16px;
                        }
                        .address-info{
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    .border-line{
                        border-bottom: 1px solid var(--grey4);
                    }
                }
            }
            .bottom-border{
                border-bottom: 1px solid var(--grey4);
                &.none{
                    border: none;
                }
            }
        }
        .button-wrapper{
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            justify-content: center;
            gap: 32px;
            &.mobile{
                flex-direction: column;
                margin-top: 0px;
                padding-left: 32px;
                padding-right: 32px;
                padding-bottom: 32px;
                gap: 16px;
            }
            .Button{
                &.primary{
                    width: 250px;
                }
            }
        }
    }
}