.moible-link-account-wrapper{
    display: flex;
    flex-direction: column;
    .header-wrapper{
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        .cancel-text{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
    .nav-wrap{
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
        padding: 16px;
        .left-side{
            display: flex;
            align-items: center;
            gap: 4px;
        }
        .nav-image{
            cursor: pointer;
        }
        .nav-text{
            cursor: pointer;
            color: var(--primaryBrandBlue);
        }
        .cancel-text{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
    .mobile-link-account-card{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 64px;
        border-radius: 8px;
        position: relative;
    }
}