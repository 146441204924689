.DesktopTransactionPreview {
  width: 100%;
  background-color: white;
  letter-spacing: var(--letterSpacing);
  .Table {
    width: 100%;
    .Title-Row {
      width: 100%;
      .Title {
        padding-top: 8px; 
        padding-bottom: 8px; 
        padding-left: 8px;
        padding-right: 8px;
        // padding-right: 88px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: var(--neutralGrey75);
        vertical-align: center;
        white-space: nowrap;
        .sort-icon{
          position: relative;
          top: 7px;
          left: 3px;
          cursor: pointer;
        }
        &.Right{
          text-align: right;
          // padding-right: 88px;
        }
        &.Balance{
          text-align: right;
          padding-right: 16px;
        }
      }
    }
    .Data {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      border-top: 1px solid var(--grey6);
      td {
        padding-right: 8px;
        padding-left: 8px;
        padding-top: 16px;
        padding-bottom: 16px;
        vertical-align: middle;
      }
      .Date {
        width: 20%;
      }
      .Description {
        width: 47%;
      }
      .Amount-Wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-grow: 1;
        text-align: right;
        .Amount {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          color: var(--neutralGrey85);
        }
        &.debit{
          margin-left: -7px;
        }
        .Status {
          margin-left: 10px;
          margin-right: 5px;
          font-weight: 600;
          font-size: 12px;
        }
      }
      .Current-Balance {
        width: 15%;
        padding-right: 16px;
        text-align: right;
        position: relative;
        bottom: 2px;
      }
      .Arrow{
        text-align: right;
        padding-right: 12px;
        .arrow-image{
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}