.pagination-component {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: left;
  align-items: center;
  &.space{
    justify-content: space-between;
  }
  .left-side{
    display: flex;
    align-items: center;
  }
  .select-to-show {
    margin-right: 24px;
    width: 136px;
    .select-limiter{
      background-color: transparent;
    }
  }
  &.mobile{
    display: initial;
    flex-wrap: none;
    flex-direction: column;
    align-items: flex-end;
  }
  .page-bar {
    display: flex;
    cursor: pointer;
    .back-arrow-inactive {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: transparent;
      margin: 0px 8px;
      margin-right: 1px;
      border: 1px solid transparent;
    }
    .back-arrow-active {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0px 8px;
      margin-right: 1px;
      border: 1px solid transparent;
    }
    .page-number {
      margin: 0px 8px;
      background: transparent;
      margin-right: 1px;
      border: 1px solid transparent;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .page-number-active {
      margin: 0px 8px;
      border: 1px solid #0a5ec0;
      color: var(--white);
      background: #0a5ec0;
      margin-right: 1px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
    }
    .foward-arrow-inactive {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: transparent;
      margin: 0px 8px;
      margin-right: 1px;
      border: 1px solid transparent;
    }
    .foward-arrow-active {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0px 8px;
      margin-right: 1px;
      border: 1px solid transparent;
    }
  }
  .number-of-items {
    color: var(--neutralGrey75);
    margin-right: 24px;
    display: flex;
    align-items: baseline;
    white-space: pre-wrap;
    .text{
      color: var(--neutralGrey55);
    }
  }
  .bottom-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    &.space{
      justify-content: space-between;
    }
  }
}
