.mobile-documents-account-agreement{
    display: flex;
    flex-direction: column;
    .mobile-account-agreement-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        padding: 16px;
        .filter-wrap{
            margin-left: -16px;
            margin-top: -16px;
        }
        .agreements-wrapper{
            margin-top: 24px;
            .account-row{
                display: flex;
                align-items: center;
                background-color: var(--white);
                border-bottom: 1px solid var(--grey5);
                padding: 20px 16px;
                width: 100%;
                &:last-child{
                    border: none;
                }
                .account-details{
                    display: inline-block;
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                    padding-left: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: calc(90%);
                    cursor: pointer;
                }
                .download-image{
                    padding-left: 16px;
                }
            }
        }
    }
    .message-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 16px;
        .message-icon{
            height: 64px;
            width: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            text-align: center;
            &.margin{
                margin-bottom: 16px;
            }
        }
        .sub-text{
            color: var(--neutralGrey75);
            margin-bottom: 16px;
            text-align: center;
        }
    }
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 400px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 32px;
    }
}