.mobile-transaction-history-wrapper{
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    .overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 992;
    }
    .account-header-wrap{
        background-color: var(--secondaryDarkestBlue);
        width: 100vw;
        max-width: 100vw;
    }
    .transaction-history-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 55px;
        padding: 16px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        &.savings{
            border: none;
            margin: 0px;
            padding: 0px;
        }
        .header-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            justify-content: space-between;
            .transactions-text{
                color: var(--neutralGrey85);
            }
            .filter-box{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                border: 1px solid var(--neutralGrey15);
                padding: 10px;
                border-radius: 8px;
                cursor: pointer;
                .filter-text{
                    color: var(--neutralGrey55);
                }
            }
        }
        .transaction-table-wrap{
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            .empty-state-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                padding: 8px 16px;
                .text{
                  text-align: center;
                  color: var(--black);
                }
                .empty-image{
                  height: 64px;
                  width: 64px;
                  margin-bottom: 16px;
                  margin-top: 16px;
                }
            }        
        }
        .pagination-wrapper{
            margin-top: 29px;
        }
        .export-center{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 8px;
            margin-top: 30px;
            .export-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 11px;
                width: 176px;
                height: 40px;
                background-color: var(--white);
                border-radius: 27px;
                cursor: pointer;
                .export-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
}
.mobile-transactions-loading-box-wrapper{
    width: 100%;
    // background-color: var(--grey5);
    // border-radius: 8px;
    // height: 300px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
    .mobile-transaction-loading-box{
        width: 100%;
        background-color: var(--grey5);
        border-radius: 8px;    
        height: 300px;
    }
}
  
.Transactions-History-API-Error-Mobile{
    display:  flex;
    flex-direction: column;
    background-color: var(--white);
    height: auto;
    justify-content: center;
    border-radius: 8px;
    .API-Error-Message-Container-Mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        .image{
        height: 72px;
        width: 72px;
        }
        .top-text{
        margin-top: 24px;
        color: var(--black);
        }
        .bottom-text{
        margin-top: 14px;
        color: var(--black);
        text-align: center;
        }
    }
}