.mobile-edit-person-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 88px;
    background-color: var(--white);
    border-radius: 8px;
    .text-input-wrap, .ssn-input-wrap {
        // margin: 0 !important;
        // padding-bottom: 24px;
        
        &.quarter {
          width: 25% !important;
        }
        
        &.third {
          width: 33.333% !important;
          flex: 1;
        }
        
        &.half {
          width: 50% !important;
          flex: 1;
        }
        
        &.twothird {
          width: 66.666% !important;
        }

        &.full {
          width: 100% !important;
        }

        input {
          width: 100% !important;
        }
    }
    .title-wrap{
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 8px;
        .title-text{
            color: var(--neutralGrey85);
        }
        .sub-title-text{
            color: var(--neutralGrey75);
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
    }
    .Personal-Information-Wrap{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(0,0,0, 0.2);
        gap: 16px;
        .Personal-Information-Header{
            margin-top: 16px;
        }
    }
    .Additional-Information-Wrap{
        display: flex;
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 48px;
        gap: 16px;
        .Additional-Information-Header{
            padding-top: 32px;
        }
    }
    .Button-Wrapper{
        position: fixed;
        bottom: 0px;
        z-index: 993;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 72px;
        width: 100%;
        margin-left: -16px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: var(--white);
        .Left-Button{
            width: 166px;
            height: 40px;
            border: 2px solid var(--primaryBrandBlue);
            background-color: var(--white);
            color: var(--primaryBrandBlue);
            border-radius: 54px;
        }
        .Right-Button{
            width: 166px;
            // height: 40px;
            // border: 2px solid var(--primaryBrandBlue);
            // background-color: var(--primaryBrandBlue);
            // color: var(--white);
            // border-radius: 54px;
        }
    }
}