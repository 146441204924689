.cancel-modal-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 994;
    .modal-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-radius: 8px;
        z-index: 994;
        width: 100%;
        max-width: 528px;
        &.mobile{
            width: 92%;
            margin-left: 16px;
            margin-right: 16px;
        }
        .image{
            width: 64px;
            height: 64px;
        }
        .header-message{
            text-align: center;
            color: var(--neutralGrey85);
            margin-top: 16px;
            margin-bottom: 8px;
        }
        .warning-text{
            color: var(--neutralGrey75);
        }
        .button-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            margin-top: 32px;
            &.mobile{
                flex-direction: column;
                width: 100%;
                gap: 16px;
            }
            .Button{
                &.mobile{
                    width: 100%;
                }
            }
        }
    }
}