.mobile-tax-withholding-wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .w9-loading-wrap{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        gap: 16px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 64px;
        .w9-loading-box{
            background-color: var(--grey5);
            border-radius: 8px;
            &.top{
                height: 200px;
            }
            &.bottom{
                height: 100px;
            }
        }
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        padding: 16px 16px 24px;
        &.pointer{
            cursor: pointer;
        }
        .card-title{
            color: var(--neutralGrey75);
        }
        .card-sub-title{
            margin-top: 8px;
            color: var(--neutralGrey55);
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .data-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.tooltip{
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
            }
            &.full-border{
                border: 1px solid var(--grey5);
                padding: 16px;
                margin-top: 16px;
                border-radius: 8px;
            }
            &.tax-stack{
                gap: 12px;
            }
            .center-stack{
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
            &.pointer{
                cursor: pointer;
            }
            &.margin{
                margin-bottom: 16px;
            }
            .left-side{
                display: flex;
                flex-direction: column;
                gap: 2px;
                &.limit{
                    max-width: 151px;
                }
            }
            .edit-image{
                cursor: pointer;
            }
            .tooltip{
                width: 22px;
                height: 22px;
                cursor: pointer;
            }
        }
        .api-error-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .header{
                color: var(--neutralGrey85);
                margin-top: 16px;
                margin-bottom: 8px;
            }
            .sub-text{
                color: var(--neutralGrey75);
            }
        }
    }
    .tooltip-overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 994;
        .tooltip-wrap{
            display: flex;
            flex-direction: column;
            position: fixed;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-radius: 8px 8px 0px 0px;
            background-color: var(--white);
            .header-wrap{
                display: flex;
                align-items: center;
                padding: 16px;
                position: relative;
                border-bottom: 1px solid var(--grey5);
                .header-text{
                    width: 100%;
                    text-align: center;
                }
                .close-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                    position: absolute;
                    right: calc(0px + 16px);
                }
            }
            .tooltip-text{
                color: var(--neutalGrey75);
                padding: 16px;
            }
        }
    }
}