.update-maturity-page-wrap{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding-right: 16px;
    &.desktop{
        flex-direction: row;
        width: 100%;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
    }
    .update-maturity-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 32px;
        margin-bottom: 32px;
        border-radius: 8px;
        padding: 32px;
        width: 100%;
        max-width: 1120px;
        &.update-mobile{
            margin-top: 0px;
            margin-left: 16px;
            margin-right: 16px;
            padding: 24px 16px;
        }
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 32px;
            @media only screen and (max-width: 768px) {
                padding-bottom: 0px;
                margin-bottom: 24px;
            }          
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
        .data-wrap{
            display: flex;
            flex-direction: column;
            padding: 32px;
            background-color: var(--neutralBackgroundGrey);
            border-radius: 8px;
            @media only screen and (max-width: 768px) {
                padding: 16px;
            }          
            .selected-option{
                color: var(--neutralGrey75);
                margin-bottom: 24px;
            }
            .section-info-wrap{
                display: flex;
                flex-direction: column;
                gap: 16px;
                .label-value-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    &.margin{
                        margin-top: 8px;
                    }
                    .header-text{
                        color: var(--neutralGrey85);
                    }
                    .data-text{
                        color: var(--neutralGrey75);
                    }
                }
                .box-row{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    &.stack{
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .box-wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        background-color: var(--white);
                        border: 1px solid var(--grey5);
                        border-radius: 8px;
                        width: 100%;
                        max-width: 290px;
                        min-height: 103px;
                        padding: 12px 16px;
                        &.full{
                            max-width: 100%;
                        }
                        .header-text{
                            color: var(--neutralGrey85);
                        }
                        .bottom-row{
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            .account-data-stack{
                                display: flex;
                                flex-direction: column;
                                gap: 4px;
                                .account-text{
                                    color: var(--neutralGrey85);
                                }
                                .account-type{
                                    color: var(--neutralGrey55);
                                }
                            }
                        }
                    }
                }
            }
        }
        .bottom-border{
            border-bottom: 1px solid var(--grey5);
            margin-top: 40px;
        }
        .button-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                .back-arrow{
                    height: 20px;
                    width: 20px;
                }
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
            .right-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
            }
        }
        .mobile-button-wrap{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-top: 1px solid var(--grey5);
            box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
            background-color: var(--white);
            padding: 16px;
            position: fixed;
            bottom: 0px;
            z-index: 993;
            margin-left: -32px;
            .Button{
                width: 100%;
            }
        }
    }
}
.mobile-update-header-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    .left-side{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 9px;
        cursor: pointer;
    }
    .back-arrow{
        height: 20px;
        width: 20px;
    }
    .header-text{
        color: var(--primaryBrandBlue);
        cursor: pointer;
    }
}
