.maturity-card-wrapper{ 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    .card-details{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .card-title{
                color: var(--neutralGrey85);
            }
            .manage-button{
                display: flex;
                flex-direction: row;
                padding: 8px 16px;
                background-color: var(--white);
                color: var(--primaryBrandBlue);
                border: 1px solid var(--grey5);
                border-radius: 4px;
                cursor: pointer;
            }
        }
        .wrapper{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            .text{
                color: var(--neutralGrey85);
                padding-bottom: 4px;
            }
            .value{
                color: var(--neutralGrey75);
            }
        }
    }
} 
