.yodlee-bank-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
    max-width: 544px;
    .bank-name{
        display: flex;
        color: var(--neutralGrey75);
        min-width: 120px;
        max-width: 208px;
        &.failed{
            color: var(--darkRed);
        }
    }
    .hyphens{
        display: flex;
        // flex: 1;
        height: 1px;
        // width: 100%;
        flex: 1;
        // max-width: 204px;
        border-bottom: 1px dashed var(--neutralGrey55);
        margin-left: 8px;
        margin-right: 8px;
        &.Mobile{
            // max-width: 96px;
        }
    }
}

.yodlee-indicator-pill{
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 4px;
    padding: 4px 12px 4px 12px;
    border-radius: 20px;
    background-color: var(--neutralGrey15);
    width: 100%;
    max-width: 88px;
    &.failed{
        max-width: 112px;
        background-color: var(--softRed);
        padding-left: 8px;
        padding-right: 8px;
        &.Mobile{
            max-width: 112px;
        }
    }
    &.Mobile{
        max-width: 140px;
    }
    &.pending{
        width: 100%;
        max-width: 140px;
    }
    &.success{
        padding: 4px 8px 4px 8px;
        background-color: var(--fadedGreen);
        max-width: 112px;
    }
    &.processing{
        max-width: 84px;
    }
    &.frozen{
        justify-content: center;
        width: 100%;
        max-width: 104px;
        background-color: var(--softRed);
    }
    .image{
        height: 14px;
        width: 14px;
    }
    .pill-text{
        &.success{
            color: var(--successGreen);
        }
        &.failed{
            color: var(--darkRed);
        }
        &.not-linked{
            color: var(--darkRed);
        }
    }
}

.yodlee-link-in-progress-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    &.Mobile{
        display: flex;
        flex-direction: column;
        min-height: 565px;
        justify-content: center;
    }
    .Button{
        &.mobile{
            max-width: 448px;
        }
    }
    .bank-link-image{
        height: 64px;
        width: 64px;
    }
    .title-text{
        color: var(--neutralGrey75);
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
    .sub-title-text{
        color: var(--neutralGrey75);
        margin-bottom: 24px;
        text-align: center;
    }
    .link-in-progress-body{
        text-align: center;
        max-width: 610px;
        width: 100%;
        color: var(--neutralGrey75);
        &.Mobile{
            max-width: 448px;
        }
    }
    .button-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-top: 48px;
        // max-width: 311px;
        width: 100%;
        .Button{
            max-width: 307px;
            width: 100%;
            &.mobile{
                max-width: 100%;
            }
        }
        .link-text{
            color: var(--primaryBrandBlue);
            margin-bottom: 16px;
            cursor: pointer;
            &.mobile{
                margin-bottom: 0px;
            }
        }
    }
}