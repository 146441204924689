.savings-page-wrapper{
    display: flex;
    flex-direction: column;
    &.desktop{
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
    }
    .open-savings-page-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 32px;
        margin-bottom: 32px;
        border-radius: 8px;
        width: 100%;
        max-width: 1120px;
        &.mobile{
            max-width: 92%;
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 24px;
        }
        .savings-header-wrapper{
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            margin-top: 16px;
            gap: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey75);
            }
            .sub-header-text{
                color: var(--neutralGrey85)
            }
        }
        .open-savings-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 768px) {
                max-width: 100%;
                flex-wrap: wrap;
            }          
            .message-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 64px;
                margin-bottom: 64px;
                width: 534px;
                // max-width: 544px;
                @media only screen and (max-width: 768px) {
                    max-width: 100%;
                    flex-wrap: wrap;
                    margin-top: 24px;
                    margin-bottom: 32px;
                }              
                .image{
                    width: 200px;
                    height: 142px;
                    @media only screen and (max-width: 768px) {
                        width: 139px;
                        height: 99px;
                    }                  
                }
                .header-text{
                    margin-bottom: 24px;
                    text-align: center;
                    margin-top: 27px;
                    color: var(--headerBlack);
                }
                .point-wrapper{
                    display: flex;
                    flex-direction: row;
                    width: 432px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 8px;
                    @media only screen and (max-width: 768px) {
                        max-width: 100%;
                        padding-left: 16px;
                        padding-right: 16px;
                    }                  
                    .point-text{
                        color: var(--neutralGrey75);
                    }
                }
            }
            .button-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 22px;
                margin-top: 24px;
                @media only screen and (max-width: 768px) {
                    max-width: 100%;
                }                  
                .open-savings-button{
                    width: 357px;
                    height: 48px;
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    cursor: pointer;
                    @media only screen and (max-width: 768px) {
                        max-width: 91%;
                    }                  
                }
                .no-thanks-button{
                    width: 96px;
                    height: 24px;
                    background-color: var(--white);
                    border: none;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
        }
        .cancel-button-wrap{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 32px;
            margin-bottom: 32px;
            margin-right: 32px;
            margin-left: 32px;
            border-top: 1px solid var(--grey5);
            .cancel-button{
                width: 124px;
                height: 48px;
                background-color: var(--white);
                color: var(--primaryBrandBlue);
                border: 1px solid var(--grey5);
                border-radius: 54px;
                cursor: pointer;
            }
        }
    }
}
.mobile-open-savings-header-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    background-color: var(--secondaryDarkestBlue);
    height: 60px;
    .header-text{
        color: var(--white);
        cursor: pointer;
    }
}