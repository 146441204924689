.Landing-Wrapper{

    .card-wrapper.account-header {
        
    }

    .transaction-page-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding-left: 48px;
        padding-right: 56px;
        &.savings{
            padding-left: 0px;
        }
        .transactions-card-wrap{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border: 1px solid var(--grey5);
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 80px;
            padding: 24px;
            width: 100%;
            max-width: 1120px;
            &.empty{
                background-color: transparent;
                border: none;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
            }
            // min-height: 488px;
            .header-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .right-side{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    cursor: pointer;
                    .export-text{
                        color: var(--primaryBrandBlue);
                    }
                }
            }
            .table-header{
                color: var(--neutralGrey85);
            }
            .filters-row{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                align-items: center;
                justify-content: space-between;
                gap: 32px;
                .left-side{
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    max-width: 46%;
                    gap: 8px;
                    .date-filter{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        margin-top: 32px;
                        .header-text{
                            color: var(--neutralGrey85);
                            white-space: nowrap;
                        }
                        .filter-box-wrap{
                            width: 100%;
                        }
                    }
                    .transaction-type-filter{
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        margin-top: 32px;
                        .header-text{
                            color: var(--neutralGrey85);
                            white-space: nowrap;
                        }
                    }
                }
                .advanced-filter-wrap{
                    height: 48px;
                    display: flex;
                    flex-direction: row;
                    margin-top: 62px;
                    align-items: center;
                    margin-right: 12px;
                    gap: 8px;
                    padding: 12px; 
                    // border: 1px solid var(--neutralGrey25);
                    border: 1px solid var(--neutralGrey15);
                    border-radius: 8px;
                    &.selected{
                        border: 1px solid var(--primaryBrandBlue);
                    }
                    cursor: pointer;
                    .advanced-filters-text{
                        color: var(--neutralGrey55);
                        padding-right: 11px;
                        &.selected{
                            color: var(--primaryBrandBlue);
                        }
                    }
                }
            }
            .advanced-filters-row{
                display: flex;
                flex-direction: row;
                align-items: center;
                .selected-filters-row{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                    margin-top: 27px;
                    margin-bottom: 27px;
                    .filtered-by-text{
                        color: var(--neutralGrey75);
                    }
                    .filter-wrap{
                        display: flex;
                        flex-direction: row;
                        padding: 6px 16px;
                        background-color: var(--grey5);
                        border-radius: 67px;
                        gap: 10px;
                        .filter-text{
                            color: var(--neutralGrey85);
                        }
                        .cancel-image{
                            cursor: pointer;
                        }
                    }
                    .clear-all-text{
                        color: var(--primaryBrandBlue);
                        cursor: pointer;
                    }
                }    
            }
            .transaction-table-wrap{
                margin-top: 32px;
                &.empty{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                }
                .empty-state-wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 8px;
                    gap: 8px;
                    .empty-image{
                        height: 72px;
                        width: 72px;
                        margin-bottom: 16px;
                    }
                    .text{
                        color: var(--black);
                    }
                }          
            }
            .pagination-wrapper{
                margin-left: -12px;
                margin-top: 40px;
            }
            .bottom-row-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                &.no-pagination{
                    justify-content: flex-end;
                }
                .right-side{
                    display: flex;
                    flex-direction: row;
                    margin-top: 40px;
                    margin-right: 12px;
                    align-items: center;
                    cursor: pointer;
                    .export-text{
                        color: var(--primaryBrandBlue);
                        padding-right: 11px;
                    }
                    .export-symbol{
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
    }
}

.transaction-loading{
    height: 585px;
    background-color: var(--grey5);
    border-radius: 8px;
    &.mobile{
      margin-bottom: 16px;
    }
  }

.toast-wrapper {
    display: flex;
    justify-content: center;
    z-index: 999;
}

.Transactions-History-API-Error{
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: var(--white);
    border-radius: 8px;
    justify-content: center;
    .API-Error-Message-Container{
      display: flex;
      flex-direction: column;
      align-items: center;
      .Image{
        height: 72px;
        width: 72px;
      }
      .Top-Text{
        margin-top: 24px;
        color: var(--black);
      }
      .Bottom-Text{
        margin-top: 14px;
        color: var(--black);
      }
    }
  }
  