.input-label-wrap{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    .input-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        .label-text{
            color: var(--fleetBlack);
        }
        .required-star{
            color: var(--darkRed);
        }
    }
}