.desktop-interest-settings-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 48px;
  padding-bottom: 40px;
  .content-wrapper {
    background-color: var(--white);
    padding: 32px 32px 24px 32px;
    margin-top: 32px;
    width: 100%;
    max-width: 1120px;
    border-radius: 8px;
    .title-subtitle-messages {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      gap: 8px;
      .title-text {
        color: var(--neutralGrey85);
      }
      .subtitle-text {
        color: var(--neutralGrey55);
      }
    }
    .account-section {
      border: 1px solid var(--grey2);
      padding: 0px 24px 0px 24px;
      width: 65.5%;
      border-radius: 8px;
      margin-bottom: 25px;
      .account-title {
        margin-top: 24px;
      }
      .radio-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .row-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-top: 16px;
          width: 100%;
          flex-grow: 1;
          .radio-button {
            padding-left: 0px;
          }
          .current-selection {
            padding: 4px 10px;
            background-color: var(--bluePill);
            border-radius: 20px;
            color: var(--primaryBrandBlue);
            white-space: nowrap;
          }
          .label-wrapper{
            padding-right: 20px;
            &.not-verified{
              color: var(--neutralGrey25);
            }
          }
          .verification-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            .verification-text {
              color: var(--burntOrange);
              text-decoration: underline;
              text-decoration-style: dashed;
            }
            .verification-img {
              margin-right: 8px;
            }
          }
        }
      }
      .message{
        margin-top: 8px;
        color: var(--neutralGrey55);
      }
    }
    .external-account-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &.hidden {
        margin-bottom: 16px;
      }
      cursor: pointer;
      .down-arrow {
        margin-top: 20px;
        transform: rotate(180deg);
      }
      .up-arrow {
        margin-top: 20px;
        transform: rotate(0deg);
      }
    }
    .interest-grey-line {
      margin-top: 24px;
      margin-bottom: 24px;
      border: 1px solid var(--grey5);
    }
    .options-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;
      justify-content: flex-start;
      .add-account-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        .add-account-text {
          color: var(--primaryBrandBlue);
          padding-left: 12px;
        }
      }
      .manage-accounts-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        .manage-accounts-text {
          color: var(--primaryBrandBlue);
          padding-left: 12px;
        }
      }
    }
    .button-wrapper {
      display: flex;
      margin-top: 24px;
      padding-top: 24px;
      align-items: center;
      justify-content: right;
      gap: 16px;
      border-top: 1px solid var(--grey5);
      .cancel-button {
        background: #ffffff;
        color: var(--primaryBrandBlue);
        border: var(--primaryBrandBlue) 2px solid;
      }
      .save-button-inactive {
        color: white;
        background-color: var(--neutralGrey25);
        border: transparent 2px solid;
      }
      .save-button-active {
        color: white;
        background-color: var(--primaryBrandBlue);
        border: transparent 2px solid;
      }
    }
  }
  @keyframes closeIcon {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes openIcon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
}
