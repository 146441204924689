.desktop-link-account-wrapper{
    display: flex;
    flex-direction: column;
    .header-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 48px;
        padding-right: 56px;
        .nav-wrapper{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            .image{
                cursor: pointer;
            }
            .nav-text{
                color: var(--white);
                padding-left: 8px;
                cursor: pointer;
            }
        }
    }
    .page-card-wrapper{
        display: flex;
        margin-top: 40px;
        margin-bottom: 64px;
        padding-left: 48px;
        padding-right: 56px;
        width: 100%;
        justify-content: center;
        .link-account-card{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
            padding: 32px;
            background-color: var(--white);
            border-radius: 8px;
        }
    }
}