.select-dropdown {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .select {
    margin-top: 5px;
    padding: 12px 12px 12px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    border: 1px solid var(--neutralGrey25);
  }
  .open {
    background: url('../../assets/arrowSelectUp.svg') no-repeat 97% 50% #fff;
  }
  .closed {
    background: url('../../assets/arrowSelectDown.svg') no-repeat 97% 50% #fff;
  }
  .select * {
    padding: 12px 12px 12px 16px;
    background-color: white;
  }
}
.select-dropdown-label-inside {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: white;
  cursor: pointer;
  &:focus {
    border: 1px solid #0a5ec0;
  }
  .label{
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    color: #767676;
  }
  .select {
    font-weight: 600;
    padding-top: 12px;
    font-size: 16px;
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    margin-left: 8px; 
  }
  .open {
    background: url('../../assets/arrowSelectUp.svg') no-repeat 97% 50% #fff;
  }
  .closed {
    background: url('../../assets/arrowSelectDown.svg') no-repeat 97% 50% #fff;
  }
  .select * {
    padding: 12px 12px 12px 16px;
    background-color: white;
  }
}
