.mobile-beneficiary-display-wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: calc(0px + var(--mobileHeaderHeight) + var(--mobileFDICHeight));
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    background-color: var(--neutralBackgroundGrey);
    overflow-y: auto;
    &::-webkit-scrollbar{
        width: 0px;
    }
    &.add-flow{
        position: unset;
    }
    .cancel-button{
        padding: 16px;
        color: var(--primaryBrandBlue);
        text-align: right;
        cursor: pointer;
    }
    .card-wrap{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 40px;
        background-color: var(--white);
        border-radius: 8px;
        padding: 16px;
        &.add-flow{
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        .title-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .title{
            color: var(--neutralGrey55);
        }
        .step-text{
            color: var(--neutralGrey55);
        }
        .title-message{
            color: var(--neutralGrey85);
            padding-bottom: 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            &.mobile{
                margin-top: 8px;
            }
        }
        .message{
            color: var(--neutralGrey85);
            margin-bottom: 24px;
        }
        .header-row{
            display: flex;
            flex-direction: row;
            margin-top: 8px;
            justify-content: space-between;
            .header-text{
                color: var(--neutralGrey55);
            }
        }
        .mobile-allocation-row-wrap{
            border-bottom: 1px solid var(--grey5);
            &.no-border{
                border: none;
            }
        }
        .total-wrapper{
            display: flex;
            flex-direction: column;
            color: var(--neutralGrey55);
            margin-top: 24px;
            margin-bottom: 24px;
            padding: 16px;
            background-color: var(--neutralBackgroundGrey);
            border-radius: 8px;
            .allocations-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                .allocation-total-text{
                    color: var(--neutralGrey85);
                    &.error{
                        color: var(--darkRed);
                    }
                }
                .bottom-total{
                    display: flex;
                }
                .allocation-percentage{
                    color: black;
                    padding-left: 4px;
                }
            }
            .errors-wrap{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .sum-error-text{
                    color: var(--darkRed);
                }
                .zero-error-text{
                    color: var(--darkRed);
                }
            }
        }
        .button-wrap{
            display: flex;
            align-items: center;
            flex: 1;
            .save-button{ 
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                height: 40px;
                border-radius: 54px;
                background-color: var(--primaryBrandBlue);
                border: 1px solid var(--primaryBrandBlue);
                color: var(--white);
                cursor: pointer;
                &.inactive{
                    background-color: var(--neutralGrey15);
                    border: 1px solid var(--neutralGrey15);
                }
            }
        }
    }
}