.enter-bank-details-wrap{
    display: flex;
    flex-direction: column;
    .validate-success-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        .success-image{
            height: 72px;
            width: 72px;
        }
        .success-title{
            margin-top: 24px;
            margin-bottom: 24px;
            color: var(--neutralGrey85);
        }
        .success-text{
            margin-bottom: 48px;
            color: var(--neutralGrey75);
        }
    }
    .duplicate-error-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;
        gap: 16px;
        border: 1px solid var(--errorBorder);
        background-color: var(--errorBackground);
        padding: 24px; 
        border-radius: 8px;
        margin-top: 24px;
        margin-bottom: 8px;
        width: 100%;
        .image{
            height: 32px;
            width: 32px;
        }
        .error-text{
            color: var(--neutralGrey85);
        }
    }
    .title-text{
        color: var(--neutralGrey55);
        margin-bottom: 8px;
    }
    .header-text{
        color: var(--neutralGrey85);
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
        &.mobile{
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
    .standard-text{
        color: var(--neutralGrey75);
    }
    .red-text{
        color: var(--darkRed);
    }
    .tool-link-wrap{
        position: relative;
        max-width: 280px;
        &.Mobile{
            max-width: 100%;
        }
        .tooltip-link{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
    .account-label-row{
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        .label-text{
            color: var(--neutralGrey85);
            &.error{
                color: var(--darkRed);
            }
        }
    }
    .account-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 24px;
        .button-row{
            display: flex;
            gap: 16px;
            margin-top: 4px;
            &.Mobile{
                flex-direction: column;
            }
            .button-wrap{
                display: flex;
                align-items: center;
                padding-top: 16px;
                padding-bottom: 16px;
                border: 1px solid var(--grey5);
                border-radius: 4px;
                width: 100%;
                max-width: 252px;
                cursor: pointer;
                &.Mobile{
                    max-width: 100%;
                }
                &.selected{
                    background-color: rgba(10, 94, 192, 0.1);
                    border: 1px solid var(--primaryBrandBlue);
                }
                .radio{
                    margin-left: -16px;
                }
            }
        }
    }
    .account-info-row{
        display: flex;
        gap: 16px;
        margin-bottom: 48px;
        position: relative;
        &.mobile{
            flex-direction: column;
            margin-bottom: 24px;
        }
    }
    .error-text{
        color: var(--darkRed);
    }
    .tooltip-wrap{
        position: absolute;
        left: 96px;
        top: 4px;
        z-index: 991;
        &.two{
            left: 114px;
            white-space: normal;
        }
    }
    .input-wrap{
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        white-space: nowrap;
        max-width: 24%;
        &.Mobile{
            max-width: 100%;
            white-space: normal;
        }
        .input-box{
            padding: 12px 16px;
            border: 1px solid var(--neutralGrey15);
            border-radius: 4px;
            color: var(--neutralGrey85);
            &.error{
                border: 2px solid var(--darkRed);
            }
        }
    }
    .sample-check{
        width: 338px;
        height: 136px;
        &.Mobile{
            width: 100%;
            height: auto;
        }
    }
    .additional-text{
        color: var(--neutralGrey85);
        margin-top: 48px;
        margin-bottom: 24px;
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey5);
        margin-top: 40px;
        margin-bottom: 24px;
    }
    .nav-buttons-wrap{
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
    .Button{
        &.mobile{
            margin-top: 24px;
            margin-bottom: 8px;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}