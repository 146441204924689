.mobile-statement-filter-wrap{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: var(--mobileNavHeight);
    left: 0px;
    right: 0px;
    background-color: var(--white);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: 992;
    max-height: 50vh;
    .header-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 14px;
        border-bottom: 1px solid var(--grey4);
        .blue-text{
            color: var(--primaryBrandBlue);
        }
        .date-text{
            color: var(--headerBlack);
        }
    }
    .year-display-wrap{
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0px;
          }
    }
    .row-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        .year-text{
            color: var(--black);
            &.blue{
                color: var(--primaryBrandBlue);
            }
        }
        .checkmark-symbol{
            height: 20px;
            width: 20px;
        }
    }
    .border-line{
        height: 1px; 
        background-color: var(--grey5);
    }
}