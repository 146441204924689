.consent-modal-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    // top: calc(0px + var(--mobileHeaderHeight) + var(--mobileFDICHeight));
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 995;
    &.mobile{
        margin-top: calc(var(--mobileFDICHeight) + var(--mobileHeaderHeight));
        align-items: flex-start;
        background-color: var(--neutralBackgroundGrey);
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .consent-modal-data{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        background-color: var(--white);
        border-radius: 8px;
        width: 100%;
        max-width: 738px;
        max-height: 712px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }    
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px;
            max-height: 100%;
        }
        &.padding{
            padding: 24px 16px;
        }
        .image{
            margin-bottom: 24px;
        }
        .title{
            color: var(--neutralGrey85);
            &.main{
                margin-bottom: 8px;
            }
        }
        .text{
            color: var(--neutralGrey75);
            text-align: center;
            margin-bottom: 24px;
        }
        .completed-image{
            height: 64px;
            width: 64px;
            margin-bottom: 16px;
        }
    }
}