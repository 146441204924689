.confirmation-display-wrap{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    &.mobile{
        flex-direction: column;
    }
    .left-side{
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        flex: 1;
        padding: 32px;
        &.mobile{
            width: 100%;
        }
        .green-circle{
            height: 64px;
            width: 64px;
        }
        .message-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            text-align: center;
        }
        .text{
            color: var(--neutralGrey75);
            &.dark{
                color: var(--neutralGrey85);
            }
        }
        .Button{
            cursor: pointer;
        }
    }
    .right-side{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px;
        flex: 1;
        background-color: var(--neutralBackgroundGrey);
        border: 1px solid var(--neutralGrey15);
        border-radius: 8px;
        &.mobile{
            width: 100%;
            padding: 16px;
        }
        .details-title{
            color: var(--neutralGrey85);
        }
        .label-value-wrap{
            display: flex;
            flex-direction: column;
            gap: 4px;
            .label{
                color: var(--neutralGrey85);
            }
            .value{
                color: var(--neutralGrey75);
            }
        }
    }
}