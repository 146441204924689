.mobile-cd-selection{
    display: flex;
    flex-direction: column;
    .header-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 16px;
        .left-side{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 9px;
            cursor: pointer;
        }
        .header-text{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
    .loading-box{
        // flex: 1;
        background-color: var(--grey5);
        border-radius: 8px;
        padding: 24px 16px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 80px;
        margin-top: 40px;

        // width: 100%;
        height: 536px;
        border-radius: 8px;
    }
    .mobile-card-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 80px;
        padding: 24px 16px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        .product-api-fail-wrap{
            display:  flex;
            flex-direction: column;
            height: 192px;
            justify-content: center;
            align-items: center;
            .image{
                height: 72px;
                width: 72px;
            }
            .header-text{
                color: var(--neutralGrey85);
                margin-top: 24px;
            }
            .sub-text{
                color: var(--neutralGrey75);
                margin-bottom: 16px;
            }
        }
        .card-header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
            .view-rates{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .mobile-display-options{
            display: flex;
            flex-direction: column;
            .cd-group{
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;
                .options-header-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    gap: 9px;
                    .header-text{
                        color: var(--neutralGrey75);
                    }
                    .information-image{
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
                .row-wrapper{
                    display: flex;
                    flex-direction: row;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    border: 1px solid var(--grey5);
                    border-radius: 4px;
                    margin-bottom: 8px;
                    &.selected{
                        background-color: rgba(10, 94, 192, .1);
                        border: 1px solid var(--primaryBrandBlue);
                    }
                }
            }
        }    
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-top: 1px solid var(--grey5);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        background-color: var(--white);
        padding: 16px;
        position: fixed;
        bottom: 0px;
        z-index: 993;
        .continue-button{
            color: var(--white);
            width: 92%;
            height: 40px;
            background-color: var(--primaryBrandBlue);
            border: 1px solid var(--primaryBrandBlue);
            border-radius: 54px;
            cursor: pointer;
            &.disabled{
                background-color: var(--neutralGrey15);
                border: 1px solid var(--neutralGrey15);    
            }
        }
    }
    .tooltip-modal-overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 994;
        .tooltip-modal-wrap{
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px 8px 0px 0px;
            border-bottom: 1px solid var(--neutralGrey15);
            .header-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                border-bottom: 1px solid var(--grey5);
                .title-text{
                    color: var(--neutralGrey85);
                    padding: 16px;
                }
                .close-text{
                    color: var(--primaryBrandBlue);
                    position: absolute;
                    right: 16px;
                    cursor: pointer;
                }
            }
            .text{
                padding: 16px;
            }
        }
    }
}