.desktop-verify-ext-account-wrapper{
    display: flex;
    flex-direction: column;
    .header-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        padding-top: 32px;
        padding-bottom: 32px;
        background-color: var(--secondaryDarkestBlue);
        .nav-wrap{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            .chevron{
                cursor: pointer;
            }
            .header-text{
                color: var(--white);
                padding-left: 8px;
                cursor: pointer;
            }
        }
    }
    .card-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 64px;
        padding-left: 48px;
        padding-right: 56px;
        .validate-success-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px;
            .success-image{
                height: 72px;
                width: 72px;
            }
            .success-title{
                margin-top: 24px;
                margin-bottom: 24px;
                color: var(--neutralGrey85);
            }
            .success-text{
                margin-bottom: 48px;
                color: var(--neutralGrey75);
            }
        }
        .data-card{
            display: flex;
            flex-direction: column;
            padding: 24px 48px;
            background-color: var(--white);
            border-radius: 8px;
            width: 100%;
            max-width: 1120px;
            .title-text{
                color: var(--neutralGrey85);
                padding-bottom: 32px;
                margin-bottom: 48px;
                border-bottom: 1px solid var(--grey5);
            }
            .content-wrap{
                display: flex;
                align-items: center;
                gap: 48px;
                margin-bottom: 48px;
                .left-side{
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    .image{
                        width: 72px;
                        height: 72px;
                    }
                    .info-text{
                        color: var(--neutralGrey75);
                    }
                    .error-wrapper{
                        display: flex;
                        flex-direction: row;
                        // align-items: center;
                        align-self: flex-start;
                        gap: 16px;
                        border: 1px solid var(--errorBorder);
                        background-color: var(--errorBackground);
                        padding: 24px; 
                        border-radius: 8px;
                        margin-top: 24px;
                        margin-bottom: 8px;
                        width: 100%;
                        .image{
                            height: 32px;
                            width: 32px;
                        }
                        .message-stack{
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            .error-title{
                                color: var(--darkRed);
                            }
                            .error-text{
                                color: var(--neutralGrey85);
                            }
                        }
                    }                
                    .input-row{
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        .input-wrap{
                            display: flex;
                            flex-direction: column;
                            gap: 2px;
                            width: 100%;
                            // max-width: 232px;
                            flex: 1;
                            .label-row{
                                display: flex;
                                align-items: center;
                                .label-text{
                                    color: var(--neutralGrey85);
                                    &.error{
                                        color: var(--darkRed);
                                    }
                                    .red-star{
                                        color: var(--darkRed);
                                    }
                                }
                            }
                            .input-symbol-wrap{
                                display: flex;
                                align-items: center;
                                border: 1px solid var(--neutralGrey15);
                                color: var(--neutralGrey85);
                                padding: 12px 16px;
                                border-radius: 4px;
                                width: 100%;
                                &.error{
                                    border: 2px solid var(--darkRed);
                                }                    
                            }
                            .input-box{
                                width: 100%;
                                color: var(--neutralGrey85);
                                border: none;
                            }
                        }
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
                .right-side{
                    display: flex;
                    flex: 1;
                    position: relative;
                    .bank-statement{
                        &.Mobile{
                            width: 100%;
                            height: auto;
                        }
                    }
                    .icon-header-wrap{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        position: absolute;
                        left: 32px;
                        top: 17px;
                        .nickname{
                            background-color: var(--white);
                            // min-width: 40%;
                            &.Mobile{
                                left: 14%;
                                top: 3.5%;
                            }
                        }
                    }
                }        
            }
            .button-wrapper{
                display: flex;
                align-items: center;
                gap: 16px;
                padding-top: 24px;
                justify-content: flex-end;
                border-top: 1px solid var(--grey5);
            }
        }
    }
}