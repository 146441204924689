.mobile-early-landing{
    display: flex;
    flex-direction: column;
    .loading-box{
        background-color: var(--grey5);
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        border-radius: 8px;
        height: 46vh;
    }
    .card-wrapper{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        background-color: var(--white);
        padding: 24px 16px;
        border-radius: 8px;
        margin-bottom: 64px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            gap: 8px;
            .header-text{
                color: var(--neutralGrey85);
            }
            .sub-header-text{
                color: var(--neutralGrey75);
            }
        }
        .title-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 9px;
            .title-text{
                color: var(--neutralGrey85);
            }
        }
        .section-wrap{
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
            .top{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .image{
                        cursor: pointer;
                    }
                }
            }
            .bottom{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            .row-text{
                color: var(--neutralGrey85);
            }
            .row-value{
                color: var(--neutralGrey85);
                &.red{
                    color: var(--darkRed);
                }
            }
        }
        .border{
            border-bottom: 1px solid var(--grey5);
        }
        .bottom-box{
            display: flex;
            flex-direction: column;
            padding: 16px;
            background-color: var(--neutralBackgroundGrey);
            border: 1px solid var(--grey5);
            border-radius: 8px;
            .disclaimer-text{
                color: var(--neutralGrey85);
                margin-bottom: 8px;
            }
            .disclaimer-value{
                color: var(--neutralGrey85);
            }
            .route-maturity-link-wrap{
                margin-top: 24px;
                align-items: center;
                .normal-text{
                    color: var(--neutralGrey55);
                }
                .link-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
        }
        .contact-help-wrap{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 32px;
            .help-text{
                color: var(--neutralGrey75);
            }
            .blue-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
    // .button-wrap{
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: space-between;
    //     margin-top: 60px;
    //     background-color: var(--white);
    //     box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
    //     border-top: 1px solid var(--grey5);
    //     padding: 16px;
    //     .early-draw-button{
    //         background-color: var(--white);
    //         border: 1px solid var(--grey5);
    //         color: var(--primaryBrandBlue);
    //         padding: 8px 20px;
    //         border-radius: 54px;
    //         cursor: pointer;
    //     }
    //     .cancel-button{
    //         color: var(--white);
    //         background-color: var(--primaryBrandBlue);
    //         border: 1px solid var(--primaryBrandBlue);
    //         border-radius: 54px;
    //         padding: 8px 16px;
    //         cursor: pointer;
    //     }
    // }
}