.select-input-wrap{
    display: flex;
    flex-direction: column;
    .selection-wrap{
        display: flex;
        flex-direction: column;
        position: relative;
        &.disabled {
            background-color: #f7f7f7;
        }
        .input-selection-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            cursor: pointer;
            .selection-text{
                color: var(--neutralGrey75);
            }
        }
        .dropdown{
            box-shadow: 0 4px 10px rgba(0,0,0,.15);
            display: flex;
            flex-direction: column;
            border: 1px solid var(--neutralGrey25);
            z-index: 991;
            background-color: var(--white);
            border-radius: 3px;
            overflow-y: auto;
            max-height: 250px;
            position: absolute;
            transform: translateY(48px);
            width: 100%;
            &::-webkit-scrollbar {
                width: 8px;
            }
            .selection-row{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid var(--grey5);        
                padding: 8px 16px;
                width: 100%;
                cursor: pointer;
                &:hover {
                    background-color: var(--hoverBlue)
                }
                &:last-child{
                    border-bottom: none;
                }    
                .option-text{
                    color: var(--neutralGrey75);
                    &.selected{
                        color: var(--primaryBrandBlue);
                    }
                }
            }
        }
    }
}