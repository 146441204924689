.mobile-wrapper-stack{
    display: flex;
    flex-direction: column;
    .Mobile-Wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: var(--primaryBrandBlue);
        min-height: var(--mobileHeaderHeight);
        z-index: 994;
        .C1b-Logo{
            width: calc(50% + 39.5px);
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .Right-Side{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: flex-end;
            align-items: center;
            padding-right: 14px;
        }
    }
    
    .Hide-Background{
        animation: hideBackground .3s ease-out forwards;
        position: fixed;
        width: 100vw;
        height: calc(100vh - var(--mobileHeaderHeight));
        z-index: 995;
        background-color: var(--headerBlack);
        opacity: .6;
    }
    .Show-Mobile-DropDown {
        animation: openMobileMenu .3s ease-in forwards;
        z-index: 995;
        position: fixed;
        right: -1px;
        top: 0px;
        min-height: 100vh;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .Close-Mobile-DropDown{
        animation: closeMobileMenu .3s ease-out forwards;
        z-index: 995;
        position: fixed;
        right: -1px;
        top: 0px;
        min-height: 100vh;
    }        
    
    
    @keyframes openMobileMenu {
        0% {
            width: 0px;
            height: calc(100vh - var(--mobileSideBarHeight));
        }
        100% {
            width: 300px;
            height: calc(100vh - var(--mobileSideBarHeight));
        }
    }
    @keyframes closeMobileMenu{
        0% {
            width: 300px;
            height: calc(100vh - var(--mobileSideBarHeight));
        }
        100% {
            width: 0px;
            height: calc(100vh - var(--mobileSideBarHeight));
        }
    }
    @keyframes hideBackground{
        0% {
            opacity: 0;
        }
        100% {
            opacity: .6;
        }
    }
}
