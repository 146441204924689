.yodlee-page-wrap{
    display: flex;
    flex-direction: column;
    .header-wrap{
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
        .header-text{
            color: var(--neutralGrey55);
        }
        .sub-header-text{
            color: var(--neutralGrey85);
        }
        &.Mobile{
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
    .yodlee-fast-link-wrapper{
        width: 100%;
        min-height: 200px;
        // position: relative;
    }
    // .placeholder{
    //     width: 100%;
    //     text-align: center;
    //     margin-top: 220px;
    //     margin-bottom: 220px;
    // }
    .static-footer{
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        margin-top: 40px;
        padding-top: 32px;
        border-top: 1px solid var(--grey5);
        &.Mobile{
            border: none;
        }
        .footer-text{
            color: var(--neutralGrey85);
        }
        .footer-link{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
}