.yodlee-limit-modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,.4);
    z-index: 995;
    &.Mobile{
        padding-left: 16px;
        padding-right: 16px;
    }
    .yodlee-selection-modal-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 776px;
        padding: 48px;
        background-color: var(--white);
        border-radius: 8px;
        max-height: 70vh;
        &.Mobile{
            height: 100%;
            max-height: 65vh;
            padding: 32px;
        }
        .title-text{
            color: var(--neutralGrey85);
            margin-bottom: 16px;
            text-align: center;
        }
        .sub-title-text{
            width: 100%;
            max-width: 576px;
            color: var(--neutralGrey85);
            text-align: center;
            margin-bottom: 24px;
        }
        .yodlee-selection-error-wrap{
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 16px;
            background-color: var(--softRed);
            width: 100%;
            max-width: 566px;
            border-radius: 8px;
            margin-bottom: 24px;
            &.Mobile{
                align-items: flex-start;
            }
            .image{
                height: 24px;
                width: 24px;
            }
            .error-text{
                color: var(--neutralGrey85);
            }
        }
    }
    .yodlee-selection-wrapper{
        display: flex;
        flex-direction: column;
        border: 1px solid var(--neutralGrey15);
        border-radius: 8px;
        margin-bottom: 24px;
        width: 100%;
        max-width: 576px;
        max-height: 376px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 8px;
        }
        &.Mobile{
            max-height: 416px;
        }
        .yodlee-selection-row-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 40px;
            border-bottom: 1px solid var(--neutralGrey15);
            &.Mobile{
                // flex-direction: column;
            }
            .left-side{
                display: flex;
                align-items: center;
                // gap: 16px;
                &.gap{
                    gap: 16px;
                }
                .yodlee-selection-checkbox{
                    width: 24px;
                    height: 24px;
                }
                .bank-data-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    width: 100%;
                    max-width: 280px;
                    .bank-name{
                        color: var(--neutralGrey85);
                    }
                    .bank-account-info{
                        text-transform: lowercase;
                        &::first-letter{
                            text-transform: uppercase;
                        }
                        .bank-account-number{
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .status-pill{
                border-radius: 20px;
                padding: 4px 12px;
                margin-right: 16px;
                &.linked{
                    width: 64px;
                    background-color: var(--fleetBlue);
                    color: var(--primaryBrandBlue);
                }
                &.pending{
                    background-color: var(--neutralGrey15);
                    color: var(--neutralGrey75);
                }
                &.processing{
                    background-color: var(--neutralGrey15);
                    color: var(--neutralGrey75);
                    width: 84px;
                }
            }
            .account-balance{
                &.green{
                    color: var(--successGreen);
                }
                &.red{
                    color: var(--darkRed);
                }
            }
        }
    }
    .Button{
        width: 100%;
        max-width: 292px;
    }
}