.mobile-edit-nickname-wrap{
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    .nickname-menu-wrap{
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: var(--white);
        border-radius: 8px 8px 0px 0px;
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid var(--grey5);
            .reset-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
            .account-title-text{
                color: var(--neutralGrey85);
            }
            .done-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .input-wrap{
            display: flex;
            flex-direction: column;
            padding: 16px;
            .label{
                padding-bottom: 4px;
            }
            .input-box{
                padding: 8px 16px;
                border-radius: 4px;
                border: 1px solid var(--neutralGrey25);
                width: 100%;
            }
            .info-text{
                color: var(--neutralGrey75);
                margin-top: 8px;
            }
        }
    }
}