.mobile-transfers-landing{
    display: flex;
    flex-direction: column;
    .header-wrap{
        background-color: var(--secondaryDarkestBlue);
        padding: 16px;
        .header-text{
            color: var(--white);
        }
    }
    .outter-jump-wrap{
        position: -webkit-sticky;
        position: sticky !important;
        top: 44px;
        z-index: 990;
        background-color: var(--neutralBackgroundGrey);
        padding-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        overflow-y: auto;
        overflow-x: auto;
        .jump-menu-wrap{
            display: flex;
            align-items: center;
            flex: 1;
            gap: 16px;
            padding-bottom: 12px;
            border-bottom: 1px solid var(--grey5);
            .selection-text{
                display: flex;
                flex: 1;
                justify-content: center;
                cursor: pointer;
                &.selected{
                    color: var(--primaryBrandBlue);
                }
                &.disabled{
                    color: var(--neutralGrey15);
                }
            }
            .left-side{
                width: 50%;
                position: relative;
                justify-content: center;
            }
            .right-side{
                width: 50%;
                position: relative;
            }
            .selected-border{
                position: absolute;
                border: 4px solid var(--primaryBrandBlue);
                width: 100%;
                margin-top: 4px;
            }
        }
    }
    .card-wraps{
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 64px;
        .transfers-card{
            display: flex;
            flex-direction: column;
            padding: 24px 16px 32px;
            background-color: var(--white);
            border-radius: 8px;
            width: 100%;
            &.middle{
                margin-bottom: -24px;
            }
        }
        .transfer-error-wrapper{
            display: flex;
            flex-direction: column;
            .title-text{
                color: var(--neutralGrey85);
            }
            .error-stack{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 32px;
                .error-image{
                    height: 64px;
                    width: 64px;
                }
                .header-text{
                    margin-top: 16px;
                    color: var(--neutralGrey85);
                }
                .sub-text{
                    color: var(--neutralGrey75);
                }
            }
        }
        .loading-box{
            margin-top: 24px;
            background-color: var(--grey5);
            border-radius: 8px;
            height: 250px;
        }
    }
}