.Mobile-Account-Details-Wrapper {
  display: flex;
  flex-direction: column;
  .Account-Overview {
    display: flex;
    flex-direction: column;
  }
  .loading-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    background-color: var(--grey5);
    padding-left: 16px;
    padding-right: 16px;
    .loading-box{
      width: 84px;
      height: 24px;
      border-radius: 8px;
      background-color: var(--neutralBackgroundGrey);
      &.small{
        width: 32px;
      }
    }
    .pipe{
      height: 40px;
      width: 1px;
      background-color: var(--neutralGrey85);
    }
  }
  .details-nav-bar-wrap{
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: var(--grey5);
    justify-content: center;
    background-color: var(--grey5);
    padding-left: 16px;
    padding-right: 16px;
    .details-nav-bar{
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      max-width: 1120px;
      padding-top: 18px;
      padding-bottom: 18px;
      .nav-option{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        .nav-option-stack{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          .option-text{
            color: var(--neutralGrey85);
          }
        }
      }
      .divider-pipe{
        border-right: 1px solid var(--neutralGrey85);
        height: 48px;
      }
    }
  }
  .nav-bar-menu-wrap{
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 994;
    .menu-wrap{
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: var(--white);
      border-radius: 8px 8px 0px 0px;
      .header-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 16px;
        border-bottom: 1px solid var(--grey5);
        .title-text{
          text-align: center;
          color: var(--neutralGrey85);
        }
        .cancel-text{
          position: absolute;
          right: 16px;
          color: var(--primaryBrandBlue);
          cursor: pointer;
        }
      }
      .nav-option{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 8px;
        cursor: pointer;
      }
      .bottom-border{
        border-bottom: 1px solid var(--grey5);
      }
    }
  }
  .grace-banner-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    .grace-banner{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--white);
      margin-left: 16px;
      margin-right: 16px;
      padding: 16px;
      border-radius: 8px;
      width: 100%;
      max-width: 1120px;
      gap: 16px;
      cursor: pointer;
      .left-side{
        display: flex;
        align-items: center;
        gap: 24px;
        .right{
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }  
  .position-wrap{
    display: flex;
    justify-content: center;
    margin-top: 24px;
    .bump-banner-wrap{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--lightestBlue);
      border: 1px solid var(--borderBlue);
      padding: 24px;
      border-radius: 8px;
      margin-left: 16px;
      margin-right: 16px;
      cursor: pointer;
      width: 100%;
      .bump-image{
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .link-text{
        color: var(--primaryBrandBlue);
      }
      .close-wrap{
        display: flex;
        align-items: flex-start;
        .close-image{
          cursor: pointer;
        }
      }
    }
  }  
  .encapsulation-card{
    background-color: var(--white);
    margin-left: 16px;
    margin-right: 16px;
    padding: 24px 16px 24px 16px;
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid var(--grey5);
    .Transaction-History-Wrapper {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .Transaction-History {
        color: var(--headerBlack);
      }
      .Vew-All-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid var(--grey5);
        border-radius: 4px;
        cursor: pointer;
        .View-All-Text {
          color: var(--primaryBrandBlue);
        }
      }
    }
    .transaction-table{
      .empty-state-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        .text{
          text-align: center;
          color: var(--black);
        }
        .empty-image{
          height: 64px;
          width: 64px;
          margin-bottom: 16px;
          margin-top: 16px;
        }
      }
    }
  }
  .loading-box-wrapper{
    border-radius: 8px;
    margin-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    .loading-box{
      background-color: var(--grey5);
      width: 100%;
      height: 300px;
    }
  }
  .Summary-Cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 17px;
    .Summary-Beneficiary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 17px;
      margin-bottom: 17px;
      width: 100%;
    }
  }
  .open-new-account-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
    margin-bottom: 64px;
    background-color: var(--white);
    border-radius: 8px;
    padding: 32px;
    .sub-title-text{
      color: var(--headerBlack);
      text-align: center;
      margin-bottom: 16px;
    }
    .open-button{
      color: var(--white);
      padding: 8px 24px;
      background-color: var(--primaryBrandBlue);
      border: 1px solid var(--primaryBrandBlue);
      border-radius: 54px;
      cursor: pointer;
    }
  }
  .savings-page-wrap{
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 24px 16px;
    .content-wrap{
      padding: 24px 16px;
      background-color: var(--white);
      border: 1px solid var(--grey5);
      border-radius: 8px;
    }
    .open-cd-wrap{
      display: flex;
      width: 100%;
      position: relative;
      .image{
        width: 100%;
        min-width: 100%;
        max-width: 352px;
        resize: horizontal;
      }
      .Button{
        height: 17.7%;
        width: 100%;
        max-width: 86.3%;
        top: 74.6%;
        left: 6.8%;
        position: absolute;
      }
    }
  }
}

.mobile-accounts-notification-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  margin-inline: 16px;
  align-items: center;
  .mobile-accounts-notification {
    margin-top: 16px;
    width: 100%;
    display: flex;
  }
}