.transfer-history-card-wrap{
    display: flex;
    flex-direction: column;
    gap: 48px;
    .title-text{
        color: var(--neutralGrey85);
    }
    .table-wrap{
        display: flex;
        flex-direction: column;
        flex: 1;
        .headers-wrap{
            display: flex;
            align-items: center;
            flex: 1;
            gap: 8px;
            padding-bottom: 24px;
            padding-left: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .header-text{
            color: var(--neutralGrey85);
        }
        .date-header{
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            max-width: 110px;
            color: var(--neutralGrey85);
        }
        .from-header{
            width: 100%;
            max-width: 328px;
            color: var(--neutralGrey85);
        }
        .to-header{
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
            max-width: 358px;
            .to-text{
                color: var(--neutralGrey85);
            }
        }
        .amount-header{
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            max-width: 288px;
            padding-right: 32px;
            color: var(--neutralGrey85);
            .current-column{
                min-width: 109px;
            }
            &.value{
                justify-content: space-between;
            }
        }
        .sort-icon{
            cursor: pointer;
        }    
        .transfer-row-wrap{
            display: flex;
            align-items: center;
            flex: 1;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            border-bottom: 1px solid var(--grey5);
            cursor: pointer;
            gap: 8px;
            &.green{
                animation: fade-background 6s ease-in-out;
            }
            &:last-child{
                border: none;
            }
        }
        @keyframes fade-background {
            0%{
                background-color: var(--fadedGreen);
            }
            80%{
                background-color: var(--fadedGreen);
            }
            100%{
                background-color: var(--white);
            }
        }
    }
    .pagination-wrapper{
        margin-top: 40px;
    }
    .empty-state-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .bottom-border-line{
            width: 100%;
            border-bottom: 1px solid var(--grey5);
        }
        .text-wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;
            text-align: center;
            .text{
                color: var(--neutralGrey85);
            }
        }
    }
}