.Landing-Wrapper {

    .card-wrapper.account-header{
        
    }

    .card-wrapper.statements {
        display: flex;
        width: 100%;
        
        .container{
            justify-content: center;
    
            .desktop{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                background-color: var(--white);
                border-radius: 8px;
                margin-top: 32px;
                width: 100%;
                max-width: 1120px;
                margin-bottom: 64px;
                // min-height: 296px;
                .content-wrapper {
                    border-radius: 8px !important;
                    width: 100%;

                    .title-lockup {
                        margin-bottom: 32px;
                    }

                    .card-wrap{
                        display: flex;
                        flex-direction: column;
                        background-color: var(--white);
                        margin-top: 32px;
                        margin-bottom: 64px;
                        width: 100%;
                        max-width: 1120px;
                        border-radius: 8px;
                        border: 1px solid var(--grey5);

                        .title-text{
                            color: var(--neutralGrey85);
                            margin-left: 52px;
                            margin-top: 32px;
                        }
                    }
                    .failed-state-wrapper{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 16px;
                        margin-bottom: 16px;
                        .failed-image{
                            margin-bottom: 8px;
                        }
                        .text{
                            color: var(--black);
                        }
                    }
                }
            }
            .loading-state-wrapper{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                background-color: var(--grey5);
                border-radius: 8px;
                margin-top: 32px;
                width: 100%;
                max-width: 1120px;
                margin-bottom: 64px;
                min-height: 46vh;
            }
        }
    }
}