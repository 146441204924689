.Landing-Wrapper{
    display: flex;
    flex-direction: column;

    // .header-wrap{
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     width: 100%;
    //     background-color: var(--secondaryDarkestBlue);
    //     padding-left: 48px;
    // }

    .card-wrapper.verification-letter {
        
        .container{
    
            .desktop {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                background-color: var(--white);
                border-radius: 8px;
                margin-top: 40px;
                width: 100%;
                max-width: 1120px;
                margin-bottom: 64px; 
                .loading-box{
                    background-color: var(--grey5);
                    width: 100%;
                    height: 300px;
                    border-radius: 8px;
                }
                .content-wrapper {
                    border-radius: 8px !important;
                    width: 100%;
                    padding: 40px 32px 48px;
                    .title-lockup {
                        width: 100%;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-bottom: 32px;
                        h1{
                            color: var(--neutralGrey85);
                        }
                        p{
                            color: var(--neutralGrey75);
                        }
                    }

                    .content {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding-bottom: 16px;

                        .card-page-wrap{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            width: 100%;
                            padding-left: 48px;
                            padding-right: 56px;
                            .card-wrap{
                                display: flex;
                                flex-direction: column;
                                margin-top: 32px;
                                margin-bottom: 64px;
                                background-color: var(--white);
                                padding: 32px;
                                border: 1px solid var(--grey5);
                                border-radius: 8px;
                                width: 100%;
                                max-width: 1120px;
                                .header-text-wrap{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 8px;
                                    padding-bottom: 32px;
                                    margin-bottom: 32px;
                                    border-bottom: 1px solid var(--grey5);
                                    .header-text{
                                        color: var(--neutralGrey85);
                                    }
                                    .sub-header-text{
                                        color: var(--neutralGrey75);
                                    }
                                }
                            }
                        }
                    }
                }
                .letter-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 17px;
                    .link-text{
                        color: var(--primaryBrandBlue);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}