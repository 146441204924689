.display-account-box {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  border-radius: 8px;
  cursor: pointer;
  &.mobile{
    padding: 12px 18px;
  }
  .information {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    &.mobile{
      gap: 8px;
    }
    .bump-banner-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--lightestBlue);
      border-radius: 8px;
      padding: 16px;
      border: 1px solid var(--borderBlue);
      cursor: pointer;
      &.mobile{
        align-items: flex-start;
        padding: 12px 16px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .left-side{
        display: flex;
        flex-direction: row;
        align-items: center;
        &.mobile{
          flex-direction: column;
          align-items: flex-start;
        }
        .bold-congrats{
          margin-left: 11px;
        }
        .text-message{
          padding-left: 8px;
          .link-text{
            color: var(--primaryBrandBlue);
          }
        }
      }
      .right-side{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 13px;
        &.mobile{
          margin-top: 8px;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
        }
        .cancel{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
  .account-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 16px;
    .left-side{
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
      max-width: 60%;
      &.mobile{
        max-width: none;
        width: auto;
      }
      .top-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      &.mobile{
        gap: 2px;
      }
      .account-name-row{
        display: flex;
        align-items: center;
        .account-name{
          color: var(--neutralGrey85);
        }
      }
      .account-type{
        color: var(--neutralGrey55);
        &.mobile{
          color: var(--neutralGrey85);
        }
      }
      .open-arrow{
        height: 18px;
        width: 18px;
      }
    }
    .right-side{
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 38%;
      gap: 12%;
      &.mobile{
        max-width: none;
        gap: 18px;
        flex: 0;
        width: fit-content;
      }
      .left{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        max-width: 20%;;
        &.mobile{
          gap: 2px;
          max-width: none;
          align-items: flex-start;
          width: auto;
        }
      }
      .right{
        display: flex;
        align-items: center;
        flex: 1;
        gap: 8%;
        .balance-amount{
          width: 100%;
          text-align: right;
          color: var(--neutralGrey85);
        }
        .balance-type{
          color: var(--neutralGrey75);
        }
        .elipse-wrap{
          position: relative;
        }
        .elipses{
          cursor: pointer;
        }
        .nav-menu-wrap{
          display: flex;
          flex-direction: column;
          background-color: var(--white);
          box-shadow: 0px 8px 24px rgba(38, 38, 38, 0.1);
          border: 1px solid var(--neutralGrey15);
          border-radius: 8px;
          position: absolute;
          z-index: 990;
          right: 10%;
          margin-top: -48px;
          min-width: 188px;
          .nav-row-wrap{
            display: flex;
            align-items: center;
            padding: 16px;
            gap: 8px;
            cursor: pointer;
          }
          .bottom-border{
            border-bottom: 1px solid var(--grey5);
          }
        }
      }
    }
  }
  .closed-pill-wrap{
    width: 162px;
  }
  .bottom-border-mobile{
    border-bottom: 1px solid var(--grey5);
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .bottom-row-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label-value{
      display: flex;
      align-items: center;
      gap: 4px;
      .label-text{
        color: var(--neutralGrey55);
      }
      .value-text{
        color: var(--neutralGrey85);
      }
    }
  }
  // .nav-wrapper-mobile{
  .nav-menu-wrap-mobile{
    position: fixed;
    background-color: rgba(0,0,0,.4);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    .nav-menu-mobile{
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .header-wrap-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 16px;
        border-bottom: 1px solid var(--grey5);
        .close-text{
          color: var(--primaryBrandBlue);
          position: absolute;
          right: 16px;
        }
      }
      .nav-row-mobile{
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 16px;
        border-bottom: 1px solid var(--grey5);
        cursor: pointer;
        .row-text{
          color: var(--neutralGrey85);
        }
      }
    }
  }
}
.account-notification-mobile{
  margin-bottom: 16px;
}
