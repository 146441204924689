.desktop-manage-external-accounts {
    display: flex;
    flex-direction: column;
    .loading-wrapper{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        margin-top: 32px;
        margin-bottom: 64px;
        .loading-box{
            width: 100%;
            max-width: 1120px;
            background-color: var(--grey5);
            border-radius: 8px;
            height: 250px;
        }
    }
    .header-wrap{
        display: flex;
        justify-content: center;
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
        padding-right: 56px;

        .nav-header {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            padding-top: 32px;
            padding-bottom: 32px;

            .image {
                cursor: pointer;
            }

            .nav-text {
                padding-left: 8px;
                color: var(--white);
                cursor: pointer;
            }
        }

    }

    .card-page-wrap {
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;

        .top-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 64px;
            padding: 16px 32px;
            width: 100%;
            max-width: 1120px;

            .header-row {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                margin-bottom: 32px;
            }

            .bank-column {
                display: flex;
                flex-direction: column;
                width: 54.6%;

                .nickname-text {
                    color: var(--neutralGrey75);
                }

                .column-text {
                    color: var(--neutralGrey55);
                }
            }

            .routing-column {
                display: flex;
                align-items: center;
                width: 20.2%;

                .routing-column-data {
                    color: var(--neutralGrey75);
                }
            }

            .status-column {
                display: flex;
                align-items: center;
                width: 14%;
                .status-wrap{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    position: relative;
                    .tooltip-wrapper{
                        position: absolute;
                        margin-left: -56px;
                        margin-bottom: 8px;
                    }
                }
                .pill-wrap {
                    color: var(--primaryBrandBlue);
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 12px;
                    padding-right: 12px;
                    background-color: var(--fleetBlue);
                    border-radius: 20px;
                    &.verify{
                        color: var(--white);
                        background-color: var(--primaryBrandBlue);
                        padding: 6px 16px;
                        cursor: pointer;
                    }
                    &.processing{
                        color: var(--neutralGrey75);
                        background-color: var(--neutralGrey15);
                    }
                }
            }

            .row-wrapper {
                display: flex;
                flex-direction: row;
                flex-grow: 1;

                .elipse-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex: 1;
                    padding-right: 32px;
                    position: relative;

                    .elipses {
                        height: 32px;
                        width: 32px;
                        cursor: pointer;
                    }

                    .menu-wrap {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        background-color: var(--white);
                        border: 1px solid var(--neutralGrey15);
                        border-radius: 8px;
                        width: 204px;
                        top: 12px;
                        right: 8px;
                        z-index: 990;

                        .menu-row-wrap {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 16px;
                            cursor: pointer;

                            .blue-text {
                                color: var(--primaryBrandBlue);
                            }

                            .red-text {
                                color: var(--darkRed);
                            }
                        }

                        .border {
                            border-bottom: 1px solid var(--grey5);
                        }
                    }
                }
            }

            .border-line {
                margin-top: 16px;
                margin-bottom: 16px;
                border-bottom: 1px solid var(--neutralBackgroundGrey);
            }

            .link-new-account {
                display: flex;
                flex-direction: row;
                align-items: center;

                .add-symbol {
                    padding-right: 12px;
                    cursor: pointer;
                }

                .add-text {
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }

            .header-text {
                color: var(--neutralGrey85);
            }

            .label-wrap {
                display: flex;
                flex-direction: column;
                gap: 4px;
                color: var(--neutralGrey85);
            }

            .input-box {
                width: 100%;
                max-width: 340px;
                border: 1px solid var(--neutralGrey15);
                border-radius: 4px;
                padding: 8px 16px;
                color: var(--neutralGrey75);
            }

            .edit-border {
                margin-top: 32px;
                margin-bottom: 32px;
                border: 1px solid var(--grey5);
            }

            .button-wrap {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 16px;
                margin-bottom: 8px;
            }
            .message-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 40px;
                .message-image{
                    height: 72px;
                    width: 72px;
                }
                .message-title{
                    margin-top: 16px;
                    color: var(--neutralGrey85);
                    margin-bottom: 8px;
                }
                .message-text{
                    color: var(--neutralGrey75);
                }
            }
        }
    }
}

