.verify-identity-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        color: var(--neutralGrey85);
    }
    .sub-title{
        color: var(--neutralGrey75);
        text-align: center;
        margin-top: 8px;
        margin-bottom: 32px;
    }
    .options-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .verify-option-box{
            display: flex;
            width: 100%;
            padding: 16px 24px;
            border: 1px solid var(--grey5);
            border-radius: 8px;
            justify-content: space-between;
            align-items: center;
            background-color: var(--white);
            cursor: pointer;
            &.mobile{
                padding: 16px;
            }
            .left-side{
                display: flex;
                align-items: center;
                gap: 24px;
                &.mobile{
                    gap: 8px;
                }
                .image{
                    height: 64px;
                    width: 64px;
                    &.mobile{
                        width: 40px;
                        height: 40px;
                    }
                }
                .data-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }
            .right-chevron{
                width: 20px;
            }
            .spinner{
                will-change: width;
                transition: width .3s;              
                .click-loader {
                  font-size: .2rem;
                  width: .2rem;
                  height: .2rem;
                  border-radius: 50%;
                  position: relative;
                  text-indent: -9999em;
                  animation: circleSpin .9s ease infinite;
                  transform: translateZ(0);
                  margin-right: 1rem;
                  min-width: .2rem!important;
                }
    
                @keyframes circleSpin {
                  0%,to {
                      box-shadow: 0 -2.6em 0 0 transparent,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  12.5% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 transparent,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  25% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 transparent,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  37.5% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 transparent,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  50% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 transparent,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  62.5% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 transparent,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  75% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 transparent,-1.8em -1.8em 0 0 #0a5ec0
                  }
              
                  87.5% {
                      box-shadow: 0 -2.6em 0 0 #0a5ec0,1.8em -1.8em 0 0 #0a5ec0,2.5em 0 0 0 #0a5ec0,1.75em 1.75em 0 0 #0a5ec0,0 2.5em 0 0 #0a5ec0,-1.8em 1.8em 0 0 #0a5ec0,-2.6em 0 0 0 #0a5ec0,-1.8em -1.8em 0 0 transparent
                  }
                }

                @keyframes spin {
                  0% {
                      transform: rotate(0deg);
                      -moz-transform: rotate(0deg);
                      -o-transform: rotate(0deg);
                      -webkit-transform: rotate(0deg)
                  }
                
                  to {
                      transform: rotate(359deg);
                      -moz-transform: rotate(359deg);
                      -o-transform: rotate(359deg);
                      -webkit-transform: rotate(359deg)
                  }
                }                  
            }
        }
    }
}