.eligibiilty-modal-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 994;
    .modal-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        background-color: var(--white);
        border-radius: 8px;
        max-width: 528px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px;
            max-width: 95%;
        }
        .image{
            height: 64px;
            width: 64px;
        }
        .title-text{
            color: var(--neutralGrey85);
            margin-top: 16px;
            margin-bottom: 8px;
            text-align: center;
        }
        .body-text{
            color: var(--neutralGrey75);
            margin-bottom: 32px;
            text-align: center;
        }
        .Button{
            width: 343px;
        }
    }
}