.select-term-page-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-left: 48px;
    padding-right: 56px;
    .loading-box{
        background-color: var(--grey5);
        width: 100%;
        max-width: 1120px;
        height: 536px;
        margin-top: 40px;
        border-radius: 8px;
    }
    .select-term-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 32px;
        padding: 33px;
        background-color: var(--white);
        border-radius: 8px;
        width: 100%;
        max-width: 1120px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey55);
            }
            .bottom-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .select-cd-text{
                    color: var(--neutralGrey85);
                }
                .modal-link{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
        }
        .display-options{
            display: flex;
            flex-direction: row;
            gap: 24px;
            margin-top: 32px;
            .cd-group{
                display: flex;
                flex-direction: column;
                flex: 1;
                .header-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    .header-text{
                        color: var(--neutralGrey75);
                    }
                    .image-wrapper{
                        position: relative;
                        .information-image{
                            width: 20px;
                            height: 20px;
                        }
                        .tooltip-wrap{
                            position: absolute;
                            right: 240px;
                            margin-top: -24px;
                        }
                    }        
                }
                .row-wrapper{
                    display: flex;
                    flex-direction: row;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    border: 1px solid var(--grey5);
                    border-radius: 4px;
                    margin-bottom: 8px;
                    cursor: pointer;
                    &.selected{
                        background-color: rgba(10, 94, 192, 0.1);
                        border: 1px solid var(--primaryBrandBlue);
                    }
                }
            }
        }
        .border-bottom{
            margin-top: 32px;
            border-bottom: 1px solid var(--grey5);
        }
        .button-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                .back-arrow{
                    width: 20px;
                    height: 20px;
                }
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
            .right-side{
                display: flex;
                flex-direction: row;
                gap: 16px;
                .cancel-button{
                    width: 124px;
                    height: 48px;
                    background-color: var(--white);
                    color: var(--primaryBrandBlue);
                    border: 1px solid var(--grey5);
                    border-radius: 54px;
                    cursor: pointer;
                }
                .continue-button{
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    width: 124px;
                    height: 48px;
                    cursor: pointer;
                    &.disabled{
                        background-color: var(--neutralGrey25);
                        border: 1px solid var(--neutralGrey25);
                    }
                }
            }
        }
        .product-api-fail-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 366px;
            justify-content: center;
            .header-text{
                color: var(--neutralGrey85);
                margin-top: 24px;
            }
            .sub-text{
                color: var(--neutralGrey75);
                margin-bottom: 16px;
            }
        }
    }
}