.grace-period-wrap{
    display: flex;
    flex-direction: column;
    .header-background{
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .grace-header-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            background-color: var(--secondaryDarkestBlue);
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
    .grace-page-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        margin-top: 32px;
        margin-bottom: 64px;
    }
}