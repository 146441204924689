.mobile-documents-display-header{
    display: flex;
    flex-direction: column;
    background-color: var(--secondaryDarkestBlue);
    padding-left: 22px;
    padding-top: 16px;
    padding-bottom: 24px;
    .return-line{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--white);
        margin-bottom: 24px;
        .return-arrow{
            cursor: pointer;
        }
        .return-text{
            padding-left: 8px;
            cursor: pointer;
        }
    }
    .page-title{
        color: var(--white);
    }
}