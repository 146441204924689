.card-wrapper.account-header { 
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1168px;
    &.mobile{
        margin: 0px;
        padding: 0px;
        max-width: 100%;
        background-color: var(--secondaryDarkestBlue);
    }
    .basic-account{
        background-color: var(--secondaryDarkestBlue);
        &.mobile{
            max-height: 128px;
            padding: 0px;
            background-color: var(--secondaryDarkestBlue);
            margin: 0px;
            border-radius: 0px;
        }
    }
    .container {
        // background-color: var(--secondaryDarkestBlue);
        .desktop-header-loading-state{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
            background-color: var(--secondaryDarkestBlue);
            min-height: 120px;
            .loading-header-container{
                display: flex;
                justify-content: space-between;
                .header-left{
                    display: flex;
                    flex-direction: column;
                    .top-row{
                        display: flex;
                        background-color: var(--white);
                        opacity: 0.1;
                        height: 24px;
                        width: 141px;
                        margin-bottom: 16px;
                        margin-top: 24px;
                        border-radius: 8px;
                    }
                    .bottom-row{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .left{
                            display: flex;
                            background-color: var(--white);
                            opacity: 0.1;
                            width: 295px;
                            height: 32px;
                            border-radius: 8px;
                        }
                        .divider-line{
                            display: flex;
                            background-color: var(--white);
                            opacity: 0.1;
                            height: 22px;
                            width: 2px;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        .right{
                            display: flex;
                            background-color: var(--white);
                            opacity: 0.1;
                            width: 98px;
                            height: 16px;
                            border-radius: 8px;
                        }
                    }
                }
                .header-right{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .top-row{
                        display: flex;
                        background-color: var(--white);
                        opacity: 0.1;
                        height: 22px;
                        width: 119px;
                        margin-bottom: 3px;
                        margin-top: 24px;
                        border-radius: 8px;
                    }
                    .bottom-row{
                        display: flex;
                        background-color: var(--white);
                        opacity: 0.1;
                        height: 48px;
                        width: 267px;
                        margin-bottom: 16px;
                        // margin-top: 24px;
                        border-radius: 8px;
                    }
                }
            }
        }
        .mobile-header-loading-state{
            display: flex;
            flex-direction: column;
            max-height: 132px;
            min-height: 132px;
            background-color: var(--secondaryDarkestBlue);
            margin: 0px;
            border-radius: 0px;   
            padding: 24px 16px; 
            gap: 16px;
            .top{
                background-color: var(--white);
                opacity: 0.1;
                width: 160px;
                height: 24px;
            }
            .bottom-row{
                display: flex;
                align-items: center;
                gap: 8px;
                .left{
                    background-color: var(--white);
                    opacity: 0.1;
                    width: 264px;
                    height: 32px;
                }
                .right{
                    background-color: var(--white);
                    opacity: 0.1;
                    width: 64px;
                    height: 32px;
                }
            }
        }
        .basic-account{
            display: flex;
            flex-direction: column;
            padding-left: 16px;
            padding-top: 24px;
            padding-bottom: 24px;
            &.mobile{
                padding-bottom: 16px;
                padding-top: 16px;
            }

            &.desktop{
                padding-left: 0px;
            }
            &.transactions{
                flex-direction: row;
                justify-content: space-between;
            }
            .return-wrapper{
                display: flex;
                align-items: center;
                margin-bottom: 16px;
            }
            .return-arrow{
                padding-right: 9px;
                cursor: pointer;
                &.mobile{
                    height: 32px;
                    width: 32px;
                }
            }
            .return{
                cursor: pointer;
            }

            .account-header-return-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 16px;
            }
            .text{
                color: var(--white);
            }
            .info-wrap{
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: baseline;
                gap: 16px;

                .description{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    white-space: nowrap;
                }

                &.mobile-section{
                    flex-direction: column;
                    justify-content: center;
                    gap: 4px;

                    .top-line{
                        flex-direction: row;
                        align-items: center;
                    }

                    .pill-wrap{
                        margin-top: 8px;
                        width: 126px;
                    }
                }

                .pipe {
                    display: block;
                    width: 1px;
                    height: 24px;
                    background-color: #CEE6FF;
                    opacity: .5;
                    position: relative;
                    transform: translateY(4px);
                }

                .account-type-number{
                    color: var(--secondayLightBlue);
                }
            }
        }
    }
} 
