.Mobile-Account-Settings-Wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .encapsulation-card{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
        border-radius: 8px;
        padding: 24px 16px;
    }
    .mobile-settings-loading-state{
        flex: 1;
        background-color: var(--grey5);
        border-radius: 8px;
    }
    .header{
        color: var(--neutralGrey85);
        margin-bottom: 16px;
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey5);
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .card{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        margin-bottom: 16px;
        padding: 16px;
        background-color: var(--white);
        border: 1px solid var(--grey5);
        border-radius: 8px;
        gap: 4px;
        cursor: pointer;
        .left-side{
            display: flex;
            flex-direction: row;
            .text-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-left: 24px;
                .title{
                    color: var(--neutralGrey85);
                }
                .text{
                    color: var(--neutralGrey75);
                }
                .activated-pill{
                    padding: 2px 8px;
                    border-radius: 20px;
                    color: var(--successGreen);
                    background-color: var(--fadedGreen);
                }
                .title-row{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }
            }
        }
        .right-side{
            display: flex;
            flex-direction: row;
            align-items: center;
            .Arrow{
                height: 16px;
                width: 16px;
            }
        }
    }
    .early-withdrawal-wrap{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        p {
            color: var(--neutralGrey75);

            .blue-text {
                text-decoration: none;
                color: var(--primaryBrandBlue);
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .cancel-early-withdrawal-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-bottom: 32px;
        .button-wrapper{
            display: flex;
            align-items: center;
            padding: 8px 24px;
            border: 1px solid var(--grey5);
            border-radius: 54px;
            gap: 12px;
            .cancel-text{
                color: var(--darkRed);
            }
        }
    }
}