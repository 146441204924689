.transfer-maturity-page-wrap{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding-right: 16px;
    &.desktop{
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
    }
    .transfer-maturity-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 40px;
        width: 100%;
        max-width: 1120px;
        margin-bottom: 32px;
        border-radius: 8px;
        padding: 32px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 0px;
            padding: 16px;
        }
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 32px;
            border-bottom: 1px solid var(--grey5);
            margin-bottom: 32px;
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
            @media only screen and (max-width: 768px) {
                padding-bottom: 24px;
                margin-bottom: 24px;
            }          
        }
        .information-text{
            color: var(--neutralGrey75);
        }
        .dropdown-wrapper{
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            width: 100%;
            margin-bottom: 32px;
            @media only screen and (max-width: 768px) {
                width: 100%;
            }          
        }
        .options-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 50px;
            margin-top: 44px;
            padding-bottom: 44px;
            border-bottom: 1px solid var(--grey5);
            @media only screen and (max-width: 768px) {
                flex-direction: column;
                padding-bottom: 0px;
                margin-top: 24px;
                align-items: flex-start;
                gap: 0px;
                border: none;
            }          
            .option-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;
                cursor: pointer;
                .option-text{
                    color: var(--primaryBrandBlue);
                }
                @media only screen and (max-width: 768px) {
                    width: 100%;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-top: 1px solid var(--grey5);
                }          
        
            }
        }
        .button-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 16px;
            margin-top: 24px;
        }
        .mobile-button-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 72px;
            position: fixed;
            bottom: 0px;
            z-index: 993;
            background-color: var(--white);
            margin-left: -32px;
            padding-left: 16px;
            padding-right: 16px;
            .Button{
                width: 100%;
            }
        }
    }
}
.mobile-transfer-header-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px;
    align-items: center;
    color: var(--primaryBrandBlue);
    cursor: pointer;
}
