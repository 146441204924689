.timeout-modal-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 995;
    .timeout-modal-details-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        background-color: var(--white);
        border-radius: 8px;
        width: 100%;
        max-width: 528px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px;
        }
        .image{
            margin-bottom: 16px;
        }
        .header-text{
            color: var(--neutralGrey85);
            text-align: center;
        }
        .text{
            color: var(--neutralGrey75);
            text-align: center;
        }
        .timer-red-text{
            color: var(--darkRed);
            margin-top: 24px;
        }
        .button-wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 32px;
            &.mobile{
                width: 100%;
            }
            .Button{
                &.mobile{
                    width: 100%;
                }
            }
            &.locked{
                flex-direction: row;
                align-items: center;
                padding: 12px 32px 12px 24px;
                color: var(--white);
                background-color: var(--primaryBrandBlue);
                border-radius: 54px;
                cursor: pointer;
            }
            .blue-text-button{
                border: none;
                background-color: transparent;
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
    }
}