.mobile-document-center-landing{
    display: flex;
    flex-direction: column;
    .landing-page-header{
        width: 100%;
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--secondaryDarkestBlue);    
        .landing-title{
            color: var(--white);
            margin-left: 16px;
        }    
        .loading-title-box{
            height: 32px;
            background-color: var(--white);
            opacity: .1;
            margin-left: 16px;
            width: 50%;
            border-radius: 8px;
        }
    }
    .card-loading-box{
        margin-top: 24px;
        background-color: var(--grey5);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        height: 612px;
        margin-bottom: 64px;
    }
}