.mobile-documents-column-wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    background-color: var(--white);
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid var(--grey5);
    border-radius: 8px;
    .mobile-document-page{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        width: 100%;
        padding-left: 16px;
        padding-right: 25px;
        border-bottom: 1px solid var(--grey2);
        cursor: pointer;
        .display-page{
            color: var(--headerBlack);
        }
    }
}