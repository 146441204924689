.Checkbox-Component-Wrapper {
    display: flex;
    flex-direction: row;
    .Checkbox-Wrapper {
      display: flex;
      flex-direction: row;
      position: relative;
      .Checkbox {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        // background-color: white;
        // border: 2px solid var(--neutralGrey25);
        border-radius: 4px;
        position: absolute;
        cursor: pointer;
        .shrink-checkbox {
          width: 24px;
          height: 24px;
          background-color: var(--white);
          border: 2px solid var(--neutralGrey25);
          border-radius: 4px;
          &.disabled{
            border: 2px solid var(--neutralGrey15);
            background-color: var(--neutralBackgroundGrey);
            cursor: not-allowed;
          }
        //   animation: checkbox-remove 0.1s forwards;
        }
      }
    //   input[type='checkbox']:checked + .Checkbox > 
      .Inner-Checkbox {
        background-color: #0a5ec0;
        border: 2px solid #0a5ec0;
        width: 24px;
        height: 24px;
        position: relative;
        bottom: 1px;
        left: -1.5px;
        border-radius: 4px;
        // animation: checkbox-ani 0.1s forwards;
        .check-symbol {
          position: relative;
          left: 2px;
          top: 2px;
        }
      }
    //   .Message-String {
    //     position: relative;
    //     left: 20px;
    //     font-family: 'Open Sans';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 14px;
    //     line-height: 24px;
    //     letter-spacing: -0.107143px;
    //     color: #424242;
    //   }
    //   .Required {
    //     padding-left: 10px;
    //   }
      input[type='checkbox']:checked + .Checkbox {
        border-color: transparent;
      }
      input[type='checkbox']:checked {
        opacity: 0;
      }
    //   .Tool-Tip {
    //     padding-left: 10px;
    //   }
    }
  }
  
//   @keyframes checkbox-ani {
//     0% {
//       transform: scale(0);
//     }
//     100% {
//       transform: scale(1);
//     }
//   }
  
//   @keyframes checkbox-remove {
//     0% {
//       transform: scale(1);
//     }
//     100% {
//       transform: scale(0);
//     }
//   }
  