.mobile-date-range-wrap{
    position: fixed;
    left: 0;
    right: 0;
    bottom: calc(0px + var(--mobileSideBarHeight) - 1px);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-height: 60vh;
    &.picker-is-open{
        max-height: 75vh;
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey2);
    }

    .date-picker-header{
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 75vh;
    }
    .header-row{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 14px;
        padding-left: 14px;
        padding-right: 14px;
        .header-wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            .option-text{
                color: var(--primaryBrandBlue);
            }
            .header-text{
                color: var(--headerBlack);
            }
        }
    }
    .selection-wrapper{
        overflow-y: scroll;
        &::-webkit-scrollbar{
            width: 0px;
        }
        .row-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .selection-text{
                color: var(--neutralGrey85);
                &.selected{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
}