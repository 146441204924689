.Mobile-Beneficiary-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  .Beneficiary-Settings {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-bottom: 16px;
    .Title {
      margin-top: 24px;
      margin-bottom: 8px;
      color: var(--headerBlack);
    }
    .Title-Message {
      color: var(--neutralGrey85);
    }
  }
  .add-beneficiary-wrapper {
    top: calc(0px + var(--mobileHeaderHeight) + var(--mobileFDICHeight));
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    overflow: auto;
    z-index: 994;
    background-color: var(--neutralBackgroundGrey);
    display: flex;
    flex-direction: column;
  }
}
