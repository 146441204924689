.profile-header-wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 48px;
    padding-right: 56px;
    background-color: var(--secondaryDarkestBlue);
    .profile-header{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1120px;;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        .profile-header-loading-wrapper{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .top-box{
                background-color: var(--white);
                opacity: .1;
                height: 40px;
                width: 160px;
                border-radius: 8px;
            }
            .bottom-box{
                background-color: var(--white);
                opacity: .1;
                height: 24px;
                width: 200px;
                border-radius: 8px;
            }
        }
        .top-row{
            display: flex;
            align-items: center;
            gap: 16px;
            .edit-icon{
                cursor: pointer;
            }
            .profile-text{
                color: var(--white);
            }
        }
        .customer-since-text{
            margin-top: 4px;
            color: var(--secondayLightBlue);
        }
        .input-wrap{
            display: flex;
            align-items: center;
            gap: 16px;
            position: relative;
            .input-label{
                display: flex;
                flex-direction: column;
                gap: 4px;
                color: var(--white);
                .input-row-wrap{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .input-box{
                        min-width: 368px;
                        border: 1px solid var(--primaryBrandBlue);
                        padding: 12px 16px;
                        border-radius: 4px;
                        color: var(--neutralGrey85);
                    }
                    .edit-icon{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.tab-page-wrap{
    display: flex;
    justify-content: center;
    padding-left: 48px;
    padding-right: 56px;
    .profile-header-loading-row-wrap{
        display: flex;
        align-items: center;
        gap: 48px;
        width: 100%;
        max-width: 1120px;
        margin-top: 32px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--grey2);
        &.no-margin{
            margin-top: 0px;
        }
        .loading-box{
            background-color: var(--grey5);
            height: 24px;
            width: 160px;
            border-radius: 8px;
            &.large{
                width: 176px;
            }
        }
    }
    .tab-wrapper{
        display: flex;
        flex-direction: row;
        gap: 48px;
        margin-top: 32px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--grey2);
        width: 100%;
        max-width: 1120px;
        .tab{
            display: flex;
            flex-direction: column;
            .tab-text{
                padding-left: 6px;
                color: var(--neutralGrey75);
                cursor: pointer;
            }
            .selected{
                color: var(--primaryBrandBlue);
            }
            .selected-line{
                background-color: var(--primaryBrandBlue);
                height: 6px;
                width: calc(100% + 12px);
                position: relative;
                top: 22px;
            }
        }
    }
}
