.desktop-tax-documents-wrapper{
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 8px;  
    .table-headers{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--grey5);
        padding-top: 8px;
        padding-right: 16px;
        padding-bottom: 8px;
        padding-left: 8px;
        .document{
            color: var(--neutralGrey75);
            margin-left: 14px;
        }
        .date{  
            color: var(--neutralGrey75);
        }
    } 
    .display-rows{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .display-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;   
            align-items: center;       
            border-bottom: 1px solid var(--grey5);    
            padding-right: 16px;    
            &:last-child{
                border-bottom: none;
            }
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 23px;
                padding-right: 16px;
                padding-bottom: 20px;
                padding-left: 16px;
                .document{
                    padding-left: 12px;
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .document-image{
                    cursor: pointer;
                }
            }
            .display-date{
                color: var(--neutralGrey75);
            }
        }
    }
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 240px;
    }
    .message-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        .message-image{
            width: 64px;
            height: 64px;
        }
        .header-text{
            margin-top: 16px;
            margin-bottom: 8px;
            color: var(--neutralGrey85);
        }
        .sub-text{
            color: var(--neutralGrey75);
        }
    }
}