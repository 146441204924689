.statements-card{ 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    .card-information{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .card-title{
                color: var(--neutralGrey85);
            }
            .manage-button{
                display: flex;
                flex-direction: row;
                padding: 8px 16px;
                background-color: var(--white);
                color: var(--primaryBrandBlue);
                border: 1px solid var(--grey5);
                border-radius: 4px;
                cursor: pointer;
            }
        }
        .display-statements{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-items: center;
            .statement-row{
                display: flex;
                flex-direction: row;
                height: 60px;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid var(--grey5);
                cursor: pointer;
                .left-side{
                    display: flex;
                    align-items: center;
                    .statement-date{
                        padding-left: 5px;
                        color: var(--neutralGrey85);
                    }
                }
                .arrow-icon{
                    height: 16px;
                    width: 16px;
                }
            }
        }
        .empty-statements-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-grow: 1;
            .text{
                text-align: center;
            }
        }
    }
} 
.card-error-state{
    .title-text{
        margin-top: 29px;
    }
}
