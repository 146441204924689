.current-balance-top-border-wrap{
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--secondaryDarkestBlue);
    .current-balance-top-border{
        border-bottom: 1px solid rgba(255,255,255,.2);
    }
}
.current-balance-header-wrap{
    display: flex;
    flex-direction: column;
    &.Mobile{
        background-color: var(--secondaryDarkestBlue);
        padding-top: 16px;
        padding-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .current-balance{
        color: var(--white);
        .dollar-symbol{
            font-size: 50%;
            vertical-align: super;
        }
    }
    .current-balance-text-wrap{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        .current-balance-text{
            color: var(--secondayLightBlue);
        }
        &.Mobile{
            justify-content: flex-start;
        }
    }
    .loading-box-wrap{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
        &.Mobile{
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .loading-box{
            text-align: right;
            background-color: var(--white);
            opacity: 0.1;
            border-radius: 8px;
            &.top{
                height: 56px;
                width: 256px;
                &.Mobile{
                    height: 24px;
                    width: 152px;
                }
            }
            &.bottom{
                height: 24px;
                width: 160px;
                &.Mobile{
                    height: 28px;
                }
            }
        }
    }
}