.mobile-interest-settings-wrapper{
    display: flex;
    flex-direction: column;
    .mobile-interest-loading-state{
        display: flex;
        min-height: 214px;
        background-color: var(--grey5);
        border-radius: 8px;
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 16px;
        .loading-box{
            flex: 1;
            background-color: var(--grey5);
            border-radius: 8px;
        }
    }
    .api-error-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 32px;
        background-color: var(--white);
        border-radius: 8px;
        .error-image{
            height: 64px;
            width: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            margin-top: 16px;
        }
        .sub-text{
            color: var(--neutralGrey75);
        }
    }
}