.account-overview-top-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
    border-bottom: 1px solid rgba(256, 256, 256, .2);
    .basic-account-top{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        .left-side{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .description{
                display: flex;
                flex-direction: row;
                align-items: center;
                .description-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;
                    .account-description{
                        color: var(--white);
                    }
                    .edit-pencil{
                        cursor: pointer;
                    }
                }
            }
            .account-type-number-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 28px;
                .account-type-number{
                    color: rgba(#cee6ff, .8);
                }
                .crown-wrap{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .tooltip-wrap{
                        margin-left: -12px;
                        position: absolute;
                        bottom: 16px;
                    }
                }
            }
        }
        .right-side{
            display: flex;
            flex-direction: row;
            .balance-wrap{
                display: flex;
                flex-direction: column;
                gap: 4px;
                .balance-value{
                    line-height: 40px;
                    color: var(--white);
                }
                .dollar-symbol{
                    font-size: 50%;
                    vertical-align: super;
                }
                .label-wrap{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    justify-content: flex-end;
                    .description-text{
                        color: var(--secondayLightBlue);
                        opacity: 0.8;
                    }
                }
            }    
        }
    }
    .overview-top-loading{
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding-bottom: 24px;
        .side{
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1;
            &.right{
                align-items: flex-end;
            }
            .loading-box-1{
                background-color: var(--white);
                opacity: 0.1;
                height: 32px;
                width: 84%;
                border-radius: 8px;
            }
            .loading-box-2{
                background-color: var(--white);
                opacity: 0.1;
                height: 32px;
                width: 14%;
                border-radius: 8px;
            }
            .loading-box-3{
                background-color: var(--white);
                opacity: 0.1;
                height: 32px;
                width: 25%;
                border-radius: 8px;
            }
        }
    }
    }