.account-overview-drop-down-wrapper{
    display: flex;
    flex-direction: column;
    .show-drop-down-details{
        animation: openMobileDropDown .2s ease-in backwards;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .close-drop-down-details{
        animation: closeMobileDropDown .2s ease-out forwards;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .top-portion{
        display: flex;
        flex-direction: column;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(256,256,256,.2);
    }
    .bottom-portion{
        padding-top: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(256,256,256,.2);
    }
    .row{   
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .field-text{
            color: var(--secondayLightBlue);
            opacity: 0.8;
        }
        .values-text{
            color: var(--white);
        }
        .values-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .edit-ownership{
            height: 20px;
            width: 20px;
            margin-left: 5px;
            cursor: pointer;
        }
        .joint-account{
            color: var(--secondayLightBlue);
            cursor: pointer;
        }
    }
    .account-details-wrapper{
        margin-top: 10px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        .details-text{
            color: var(--white);
            padding-right: 6px;
            cursor: pointer;
        }
        .drop-down-icon-open {
            animation: openIcon .2s ease-in forwards;
            cursor: pointer;
            &.icon{
                width: 24px;
                height: 24px;
            }    
        }
        .drop-down-icon-close {
            animation: closeIcon .2s ease-in forwards;
            cursor: pointer;
            &.icon{
                width: 24px;
                height: 24px;
            }    
        }
        @keyframes closeIcon {
            0% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
        @keyframes openIcon{
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
        }    
    }
    @keyframes openMobileDropDown {
        0% {
            height: 0px;
        }
        100% {
            height: 380px;
        }
    }
    @keyframes closeMobileDropDown{
        0% {
            height: 380px;
        }
        100% {
            height: 0px;
        }
    }
}
.mobile-bottom-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 24px 0px;
    border-top: 1px solid rgba(256,256,256,.2);
    .loading-box-1{
        height: 24px;
        width: 48%;
        background-color: var(--white);
        opacity: 0.1;
        border-radius: 8px;
    }
}