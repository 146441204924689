.fdic-logo-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px 8px 0px;
    gap: 16px;
    background-color: var(--fdicBlue);
    .fdic-logo-text{
        font-family: "Source Sans 3", sans-serif;
        font-weight: 400;
        font-style: italic;
        font-size: 13px;
        line-height: 16.34px;
        color: var(--white);
        &.Mobile{
            max-width: 232px;
        }
    }
}