.mobile-account-ownership-wrapper{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    .mobile-ownership-header-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--secondaryDarkestBlue);
    }
    .loading-box{
        margin-top: 24px;
        background-color: var(--grey5);
        border-radius: 8px;
        height: 46vh;
        margin-left: 16px;
        margin-right: 16px;
        // width: 100%;
    }
    .mobile-ownership-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px;
        border-radius: 8px;
        margin-top: 24px;
        margin-bottom: 64px;
        margin-left: 16px;
        margin-right: 16px;
    }
    .header-wrap{
        display: flex;
        flex-direction: column;
        gap: 9px;
        padding-bottom: 24px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--grey5);
        .header-text{
            color: var(--neutralGrey85);
        }
        .info-text{
            color: var(--neutralGrey75);
        }
    }
    .column-headers{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        .header-text{
            flex: 1;
            color: var(--neutralGrey55);
        }
    }
    .owner-row-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 0px 16px 0px;
        border-bottom: 1px solid var(--grey5);
        margin-bottom: 8px;
        .owner-name{
            color: var(--neutralGrey85);
            max-width: 33%;
        }
        .owner-role{
            width: 50%;
            color: var(--neutralGrey85);
        }
        &:last-of-type{
            border: none;
            padding-bottom: 0;
            margin-bottom: 8px;
        }
    }
    .help-text{
        color: var(--neutralGrey55);
        margin-top: 16px;
    }
    .blue-text{
        color: var(--primaryBrandBlue);
        text-decoration: none;
    }
    .Button{
        margin-top: 24px;
        margin-bottom: 8px;
    }
}
.owner-card-wrapper{
    margin-left: 16px;
    margin-right: 16px;
    padding: 24px 16px;
    background-color: var(--white);
    border-radius: 8px;
    position: relative;
    margin-bottom: 64px;
    &.progress-bar{
        border-top: 6px solid var(--secondayLightBlue);
        border-radius: 0px 0px 8px 8px;
    }
    .progress-bar-step{
        position:absolute;   
        height: 6px;
        background-color: var(--secondaryDarkBlue);
        top: -6px;
        left: 0px;
        &.step-1{
            width: 33.3%;
        }             
        &.step-2{
            width: 66.6%;
        }          
        &.step-3{
            width: 100%;
        }          
    }
    .status-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .step-status{
            color: var(--neutralGrey55);
        }
    }          
}
.owner-mobile-nav-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    &.last{
        margin-bottom: 24px;
    }
    .left-side{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }
    .blue-text{
        color: var(--primaryBrandBlue);
        cursor: pointer;
    }
}
