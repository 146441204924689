.Card-Top-Border{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 352px;
    width: 100%;
    @media only screen and (min-width: 768px) and (max-width: 1079px){
        max-width: 467px;
    }  
    @media only screen and (max-width: 767px){
        max-width: 717px;
    }  
}
.Card-Wrapper{ 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid var(--cardBorderGrey);
    border-bottom: 1px solid var(--cardBorderGrey);
    border-right: 1px solid var(--cardBorderGrey);
    max-width: 352px;
    width: 100%;
    @media only screen and (min-width: 768px) and (max-width: 1079px){
        max-width: 467px;
    }  
    @media only screen and (max-width: 767px){
        max-width: 717px;
    }  
} 
