.desktop-overview-middle-wrapper{
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    flex: 1;
    .middle-row-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 11% !important;
        padding-bottom: 24px;
        .left-side{
            display: flex;
            align-items: center;
            width: 38%;
            justify-content: space-between;
        }
        .left{
            display: flex;
            align-items: center;
            width: 79%;
            gap: 30%;
        }
        .label-wrap{
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            margin-right: 1px;
            &.bump{
                position: relative;
            }
            .description-text{
                color: rgba(#cee6ff, .8);
                &.apy{
                    position: relative;
                    top: 3px;
                }
                &.min{
                    min-width: 125px;
                }
            }
            .value{
                color: var(--white);
                white-space: nowrap;
            }
            .bump-link{
                position: absolute;
                bottom: 4px;
                left: 80px;
                cursor: pointer;
            }
            .bumped-wrapper{
                position: absolute;
                bottom: 4px;
                left: 72px;
                .tool-tip-wrapper{
                    position: relative;
                    bottom: 20px;
                    right: 8px;
                }
            }         
        }
        .interest-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .bottom-row-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                position: relative;
                .bump-rate-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 15px;
                    border: 2px solid rgba(255, 255, 255, 0.5);
                    border-radius: 54px;
                    padding: 8px 16px;
                    position: absolute;
                    left: calc(100% + 1rem);
                    white-space: nowrap;
                    cursor: pointer;
                    .bump-text{
                        color: var(--white);
                    }
                }
                .tool-tip-wrapper{
                    position: relative;
                    right: 8px;
                    bottom: 22px;
                }
            }
        }
        sup{
            font-size: 50%;
            vertical-align: super;
        }
        .right-side-wrap{
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            color: var(--white);
            gap: 8px;
            .drop-down{
                margin-top: 24px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                cursor: pointer;
                .drop-down-icon-open {
                    animation: openIcon .25s ease-in forwards;
                }
                .drop-down-icon-close {
                    animation: closeIcon .25s ease-in forwards;
                }
                .text{
                    color: var(--white);
                    padding-right: 10px;
                }        
            }    
        }
        @keyframes closeIcon {
            0% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
        @keyframes openIcon{
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
        }
        @keyframes openDropDown {
            0% {
                height: 0px;
            }
            100% {
                height: 200px;
            }
        }
        @keyframes closeDropDown{
            0% {
                height: 200px;
            }
            100% {
                height: 0px;
            }
        }
    }
}
.middle-loading-state{
    height: 120px;
    background-color: var(--secondaryDarkestBlue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        .loading-box-1{
            height: 60px;
            width: 24%;
            background-color: var(--white);
            opacity: 0.1;
            border-radius: 8px;
        }
    }
    .loading-box-2{
        width: 30%;
        height: 60px;
        background-color: var(--white);
        opacity: 0.1;
        border-radius: 8px;
    }
}