.mobile-account-statements-list{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    background-color: var(--white);
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid var(--grey5);
    border-radius: 8px;
    padding: 16px;
    .document-row{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid var(--grey5);
        gap: 6px;
        &:last-child{
            border-bottom: none;
        }
        .display-row{
            display: flex;
            align-items: center;
            .staement-text{
                padding-left: 13px;
                color: var(--primaryBrandBlue);
                text-decoration: underline;
                cursor: pointer;
            }
            .download-image{
                cursor: pointer;
            }
        }
        .date{
            margin-left: 39px;
            color: var(--neutralGrey55);
        }
    }
    .message-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 16px;
        .message-icon{
            height: 64px;
            width: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            text-align: center;
            &.margin{
                margin-bottom: 16px;
            }
        }
        .sub-text{
            color: var(--neutralGrey75);
            margin-bottom: 16px;
            text-align: center;
        }
    }
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 400px;
    }
    .toast-wrapper{
        display: flex;
        width: 100%;
        justify-content: center;
    }
}