.desktop-account-ownership-wrap{
    display: flex;
    flex-direction: column;
    .account-header-page-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
        padding-right: 56px;
        .account-header-wrap{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
        }
    }
    .card-page-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        .loading-box{
            width: 100%;
            max-width: 1120px;
            background-color: var(--grey5);
            border-radius: 8px;
            height: 46vh;
            margin-top: 32px;
        }
        .card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 32px;
            padding: 32px;
            width: 100%;
            max-width: 1120px;
            position: relative;
            @media only screen and (max-width: 815px) {
                margin-right: 9px;
            }    
            &.progress-bar{
                border-top: 6px solid var(--secondayLightBlue);
                border-radius: 0px 0px 8px 8px;
            }
            .progress-bar-step{
                position:absolute;   
                height: 6px;
                background-color: var(--secondaryDarkBlue);
                top: -6px;
                left: 0px;
                &.step-1{
                    width: 33.3%;
                }             
                &.step-2{
                    width: 66.6%;
                }          
                &.step-3{
                    width: 100%;
                }          
            }
            .status-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .step-status{
                    color: var(--neutralGrey55);
                }
            }          
            .header-wrap{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
                .left-side{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .title{
                        color: var(--neutralGrey85);
                    }
                    .text{
                        color: var(--neutralGrey75);
                    }
                }
            }
            .headers-wrap{
                width: 208px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 8px;
                .name-text{
                    color: var(--neutralGrey55);
                }
                .role-text{
                    color: var(--neutralGrey55);
                }
            }
            .owner-row-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 298px;
                .owner-name{
                    color: var(--headerBlack);
                    max-width: 100px;
                }
                .owner-role{
                    width: 119px;
                    color: var(--black);
                }
                // &:last-of-type{
                //     margin-bottom: 48px;
                // }
            }
            .border-line{
                width: 431px;
                margin-top: 16px;
                margin-bottom: 16px;
                border-bottom: 1px solid var(--grey5);
                @media only screen and (max-width: 1055px) {
                    width: 431px;
                }              
            }
            .help-text-wrapper{
                display: flex;
                flex-direction: column;
                .help-text{
                    margin-top: 24px;
                    color: var(--neutralGrey55);
                    max-width: 541px;
                }
            }        
        }
    }
}