.desktop-notification-settings{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    .page-card-wrap{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .loading-box{
            margin-top: 32px;
            margin-bottom: 64px;
            background-color: var(--grey5);
            width: 100%;
            max-width: 1120px;
            border-radius: 8px;
            height: 46vh;
        }
        .top-card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 24px;
            padding-bottom: 32px;
            width: 100%;
            max-width: 1120px;
            .failed-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 16px;
                .header-text{
                    color: var(--neutralGrey85);
                    margin-top: 24px;
                    margin-bottom: 8px;
                }
                .sub-text{
                    color: var(--neutralGrey75);
                }
            }
            .header-text{
                color: var(--neutralGrey85);
            }
            .notification-data-wrap{
                display: flex;
                flex-direction: column;
                border: 1px solid var(--neutralGrey15);
                border-radius: 8px;
                margin-top: 48px;
                padding: 24px 32px;
                .top-section{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .left-side{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        max-width: 638px;
                        gap: 8px;
                        .sign-in-header{
                            color: var(--neutralGrey75);
                        }
                        .sign-in-text{
                            color: var(--neutralGrey75);
                        }
                    }
                }
                .info-text{
                    width: 100%;
                    max-width: 638px;
                    color: var(--neutralGrey55);
                    margin-top: 24px;
                }
            }
        }
    }
}