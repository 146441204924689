.beneficiary-card{ 
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    .card-information{
        display: flex;
        flex-direction: column;
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            margin-top: 8px;
            .card-title{
                color: var(--neutralGrey85);
            }
            .menu-wrap{
                display: flex;
                flex-direction: column;
                .horizontal-dots{
                    width: 24px;
                    cursor: pointer;
                }
                .menu-selection-box-desktop{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    width: 248px;
                    margin-left: -218px;
                    background-color: var(--white);
                    box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
                    border-radius: 8px;
                    .bottom-border-desktop{
                        margin-left: 16px;
                        margin-right: 16px;
                        border-bottom: 1px solid var(--neutralBackgroundGrey);
                    }
                }
                .overlay{
                    position: fixed;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background-color: rgba(0,0,0,0.4);
                }
                .menu-selection-box-mobile{
                    display: flex;
                    flex-direction: column;
                    position: fixed;
                    bottom: calc(0px + var(--mobileNavHeight) - 1px);
                    left: 0px;
                    right: 0px;
                    background-color: var(--white);
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    z-index: 990;
                    .mobile-header-wrap{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding-top: 14px;
                        padding-bottom: 14px;
                        border-bottom: 1px solid var(--neutralBackgroundGrey);
                        position: relative;
                        .cancel-button{
                            position: absolute;
                            cursor: pointer;
                            color: var(--primaryBrandBlue);
                            right: 16px;
                        }
                        .beneficiary-option-text{
                            color: var(--neutraGrey85);
                        }
                    }
                    .bottom-border-mobile{
                        border-bottom: 1px solid var(--neutralBackgroundGrey);
                    }
                }
                .option-row-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px;
                    cursor: pointer;
                    .image{
                        padding-right: 11px;
                    }
                    .row-text{
                        color: var(--neutralGrey85);
                    }
                }
            }
        }
        .pie-chart-wrapper{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .pie-chart{
                display: flex;
                flex-direction: row;
                justify-content: center;
                height: 124px;
                width: 124px;
                margin-top: 15px;
                margin-bottom: 30px;
            }
        }
        .member-wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;
        }
    }
    .beneficiary-card-empty-state{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        .top-portion{
            display: flex;
            flex-direction: column;
            margin-top: 2.5%;
            .title-text{
                color: var(--neutralGrey85);
                margin-bottom: 8px;
            }
            .sub-title-text{
                color: var(--neutralGrey75);
                margin-bottom: 47px;
            }
            .image-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: center;
                .card-image{
                    margin-top: 11%;
                    // height: 142px;
                    // width: 200px;
                }
            }
            .image-text{
                text-align: center;
                margin-top: 24px;
                color: var(--neutralGrey75);
            }
        }
        .add-beneficiary-button{
            color: var(--white);
            background-color: var(--primaryBrandBlue);
            height: 48px;
            border: 1px solid var(--primaryBrandBlue);
            border-radius: 54px;
            cursor: pointer;
        }    
    }
} 
