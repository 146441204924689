.custom-date-filter-wrap{
    display: flex;
    flex-direction: column;
    &.mobile{
        overflow-y: scroll;
        align-items: center;
        margin-bottom: 24px;
        &::-webkit-scrollbar{
            width: 0px;
        }    
    }
    .input-box-wrap{
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-top: 16px;
        .input-box-column{
            display: flex;
            flex-direction: column;
            .label-text{
                margin-bottom: 4px;
                padding-left: 6px;
                color: var(--neutralGrey85);
            }
            .date-input-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: var(--white);
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                padding: 8px 16px;
                height: 48px;
                width: 212px;
                border: 1px solid var(--neutralGrey25);
                cursor: pointer;
                &.selected{
                    border: 1px solid var(--primaryBrandBlue);
                }
                &.current {
                    box-shadow: 0 0 2px 1px var(--primaryBrandBlue);
                }
                .from-text{
                    padding-left: 8px;
                    color: var(--neutralGrey75);
                    &.selected{
                        color: var(--neutralGrey75);
                    }
                    &.unselected {
                        color: var(--neutralGrey55);
                    }
                }
                &.mobile{
                    width: 177px;
                }
            }
        }
    }
    .custom-calendar-box{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-radius: 8px;
        padding: 25px;
        margin-top: 92px;
        position: absolute;
        width: 301px;
        &.to{
            right: 16px;
        }
        &.mobile{
            position: relative;
            margin-top: 20px;
            width: 370px;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                width: 0px;
            }    
        }
        .top-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left-side{
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
            .month-year-display{
                color: var(--black);
            }
            .right-side{
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
            .images{
                cursor: pointer;
            }
        }
        .day-row{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: space-between;
            margin-top: 20px;
            .day-text{
                height: 35px;
                width: 34px;
                text-align: center;
                color: var(--neutralGrey55);
                &.mobile{
                    width: 44px;
                }
            }
            &.mobile{
                justify-content: none;
            }
        }
        .map-dates{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: space-between;
            .return-wrapper{
                display: flex;
                flex-direction: column;
                height: 45px;
                width: 34px;
                &.mobile{
                    width: 44px;
                }
                .day-wrapper{
                    display: flex;
                    flex-direction: column;
                    width: 34px;
                    .day-text{
                        height: 35px;
                        text-align: center;
                        color: var(--secondaryDarkestBlue);
                        padding-top: 5px;
                        cursor: pointer;
                        &.disabled{
                            color: var(--neutralGrey15);
                            cursor: not-allowed;
                            user-select: none;
                        }
                        &.selected{
                            color: var(--white);
                        }
                    }
                    &.current{
                        color: var(--primaryBrandBlue);
                        border: 1px solid var(--primaryBrandBlue);
                        border-radius: 4px;
                    }
                    &.selected{
                        color: var(--white);
                        border: 1px solid var(--primaryBrandBlue);
                        background-color: var(--primaryBrandBlue);
                        border-radius: 4px;
                    }
                    &.mobile{
                        width: 44px;
                    }
                }
                .today-text{
                    text-align: center;
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
}