.add-owner-form-wrapper{
    display: flex;
    flex-direction: column;
    .header-text{
        color: var(--neutralGrey85);
        margin-top: 8px;
    }
    .header-sub-text-wrap{
        display: flex;
        margin-top: 8px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
        width: 100%;
        &.mobile{
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
        .header-sub-text{
            color: var(--neutralGrey75);
            max-width: 60%;
            &.mobile{
                max-width: 100%;
            }
        }
    }
    .text{
        color: var(--neutralGrey75);
    }
    .red-text{
        color: var(--darkRed);
    }
    .first-group{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
    }
    .group{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        .required-text{
            color: var(--neutralGrey75);
            margin-bottom: -4px;
            &.error{
                color: var(--darkRed);
            }
            .red-star{
                color: var(--darkRed);
            }
        }
        &.mobile{
            margin-top: 32px;
        }
        &.last{
            margin-bottom: 8px;
        }
    }
    .title-text{
        color: var(--neutralGrey85);
        margin-bottom: 16px;
        &.mobile{
            margin-top: 24px;
        }
    }
    .input-row{
        display: flex;
        align-self: flex-start;
        gap: 16px;
        width: 100%;
        max-width: 878px;
        .quarter{
            width: 18.56%;
        }
        .third{
            width: 28.7%;
        }
        .forty{
            width: 38.7%;
        }
        .half{
            width: 49%;
        }
        &.top-margin{
            margin-top: 16px;
            &.margin{
                margin-top: 32px;
            }    
        }
    }
    .full{
        width: 100%;
        margin-bottom: 16px;
    }
    .border{
        border-bottom: 1px solid var(--grey5);
        margin-top: 40px;
    }
    .button-wrapper{
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        gap: 16px;
    }
}


.mobile-ownership-card-wrap {
    .row-wrap {
        & > * {
            flex: 1 1;
        }
    }
}