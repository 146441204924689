.mobile-modify-addresses-wrap{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 64px;
    .basic-edit-header-wrap{
        display: flex;
        align-items: center;
        .header-text{
            padding-left: 16px;
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        .back-image{
            height: 20px;
            width: 20px;
            cursor: pointer;
        }
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px 32px;
        margin-top: 16px;
        border-radius: 8px;
        .required-sub-text{
            color: var(neutralGrey75);
            margin-top: 16px;
            .required-star{
                color: var(--darkRed);
            }
        }
        .card-title{
            color: var(--neutralGrey85);
        }
        .sub-text{
            color: var(--neutralGrey75);
            margin-top: 4px;
            .required-star{
                color: var(--darkRed);
            }
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            &.no-margin{
                margin-bottom: 0px;
            }
        }
        .current-address-wrap{
            display: flex;
            flex-direction: column;
        }
        .data-text{
            color: var(--neutralGrey75)
        }
        .inputs-wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            .full{
                width: 100%;
            }
            .auto-search-text{
                margin-bottom: -20px;
                color: var(--neutralGrey75);
                &.error{
                    color: var(--darkRed);
                }
                .required-star{
                    color: var(--darkRed);
                }
            }
        }
        .checkbox-tooltip-wrap{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 32px;
            .tooltip-icon{
                cursor: pointer;
            }
        }
        .Button{
            margin-top: 32px;
        }
    }
    .remove-address-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        .remove-text{
            cursor: pointer;
            color: var(--darkRed);
            padding-left: 8px;
        }
        .remove-image{
            cursor: pointer;
        }
    }
    .modal-overlay{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 994;
        .menu-wrap{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px 8px 0px 0px;
            padding-top: 16px;
            padding-bottom: 16px;
            position: fixed;
            bottom: 0px; 
            left: 0px;
            right: 0px;
            .header-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding-bottom: 16px;
                border-bottom: 1px solid var(--grey5);
                .title-text{
                    color: var(--neutralGrey85);
                }
                .link-text{
                    position: absolute;
                    right: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .body-text{
                color: var(--neutralGrey85);
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 16px;
            }
        }
    }
}