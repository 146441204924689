.contact-page-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    .contact-header-wrap{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 48px;
        padding-right: 56px;
        &.mobile{
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 16px;
            padding-right: 16px;
        }
        .header-text{
            width: 100%;
            max-width: 1120px;
            color: var(--white);
        }
    }
    .content-page-wrap{
        display: flex;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        &.mobile{
            padding-left: 16px;
            padding-right: 16px;
        }
        .content-wrap{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
            margin-top: 40px;
            &.mobile{
                margin-top: 32px;
            }
            .content-header-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                .content-header-text{
                    color: var(--neutralGrey85);
                }
                .content-sub-header{
                    color: var(--neutralGrey75);
                }
            }
            .card-wrap{
                display: flex;
                align-items: center;
                gap: 24px;
                margin-top: 64px;
                &.mobile{
                    flex-direction: column;
                    margin-top: 48px;
                }
                .card{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    background-color: var(--white);
                    border: 1px solid var(--neutralGrey15);
                    border-radius: 8px;
                    padding: 24px 24px 32px;
                    min-height: 257px;
                    &.mobile{
                        width: 100%;
                    }
                    .image{
                        height: 24px;
                        width: 24px;
                    }
                    .title{
                        color: var(--neutralGrey85);
                        margin-top: 24px;
                        margin-bottom: 8px;
                        &.mobile{
                            margin-top: 16px;
                        }
                    }
                    .data{
                        color: var(--neutralGrey75);
                    }
                    .blue-text{
                        text-decoration: none;
                        color: var(--primaryBrandBlue);
                        cursor: pointer;
                    }
                    .message{
                        color: var(--neutralGrey75);
                        margin-top: 16px;
                    }
                }
            }
            .questions-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 64px;
                margin-bottom: 64px;
                gap: 8px;
                .questions-text{
                    color: var(--neutralGrey75);
                }
                .link-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .save-wrap-mobile{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                margin-top: 24px;
                margin-bottom: 64px;
            }
            .saving-wrap{
                margin-top: 24px;
                margin-bottom: 104px;
                text-align: center;
                color: var(--neutralGrey75);
                &.mobile{
                    margin: 0px;
                }
            }
            .linked-text{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
            .footer-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 16px;
                border-top: 1px solid var(--grey5);
                .row{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                }
                .copywrite-wrap{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 8px;
                    margin-top: 16px;
                    margin-bottom: 8px;
                    .copywrite-text{
                        color: var(--neutralGrey75);
                        text-align: center;
                    }
                }
            }
        }
    }
}