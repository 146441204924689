.mobile-grace-fund-transfer-wrap{
    display: flex;
    flex-direction: column;
    .step-header{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        // background-color: var(--secondaryDarkestBlue);
        padding: 16px;
        .cancel-button{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        &.two{
            justify-content: space-between;
            padding-left: 16px;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 9px;
                cursor: pointer;
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
    .step-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        padding: 16px;
        border: 1px solid ver(--grey5);
        border-radius: 8px;
        margin-bottom: 32px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            margin-bottom: 24px;
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
        .label-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            max-width: 565px;
            margin-bottom: 24px;
            .input-box-wrap{
                display: flex;
                align-items: center;
                padding: 16px;
                flex: 1;
                border: 1px solid var(--neutralGrey25);
                border-radius: 4px;
                &.error{
                    border: 1px solid var(--darkRed);
                }
                .input-box{
                    display: flex;
                    flex: 1;
                    border: none;
                    width: 100%;
                }
            }
            .available-balance{
                margin-left: 4px;
                color: var(--neutralGrey55);
            }
            .error-text{
                color: var(--darkRed);
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }            
        }
        .funds-message{
            color: var(--neutralGrey55);
            margin-top: 8px;
        }
        .radio-button-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 9px;
            .radio-button-selection{
                border: 1px solid var(--grey5);
                border-radius: 4px;
                padding-top: 16px;
                padding-bottom: 16px;
                .radio{
                    margin-left: -20px;
                }
                &.selected{
                    background-color: rgba(10, 94, 192, 0.1);
                    border: 1px solid var(--primaryBrandBlue);
                }
            }
        }
        
        .input-wrapper{
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 16px;
            .available-balance{
                color: var(--neutralGrey55);
            }
        }
        .dropdown-wrapper{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
        }
        .options-wrap{
            display: flex;
            flex-direction: column;
            .option{
                display: flex;
                flex-direction: row;
                padding-top: 20px;
                padding-bottom: 20px;
                gap: 10px;
                border-top: 1px solid var(--grey5);
                cursor: pointer;
                .option-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
    .button-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-top: 1px solid var(--grey5);
        background-color: var(--white);
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 72px;
        z-index: 993;
        width: 100%;
        .Button{
            width: 100%;
        }
    }
}