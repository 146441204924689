.desktop-grace-close-account-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-left: 48px;
    padding-right: 56px;
    .desktop-launch-withdrawal-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 32px;
        margin-bottom: 32px;
        border-radius: 8px;
        padding: 32px;
        width: 100%;
        max-width: 1120px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey75);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
        .disclaimer-text{
            color: var(--neutralGrey55);
            margin-bottom: 32px;
        }
        .dropdown-wrapper{
            width: 561px;
            margin-bottom: 44px;
        }
        .navigation-wrapper{
            display: flex;
            flex-direction: row;
            gap: 50px;
            padding-bottom: 44px;   
            margin-bottom: 44px;
            border-bottom: 1px solid var(--grey5);
            .nav-option{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                .nav-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
        }
        .button-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .left{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
            .right{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                .cancel-button{
                    background-color: var(--white);
                    color: var(--primaryBrandBlue);
                    border: 1px solid var(--grey5);
                    border-radius: 54px;
                    padding: 12px 32px;
                    cursor: pointer;
                }
                .continue-button{
                    color: var(--white);
                    background-color: var(--primaryBrandBlue);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    padding: 12px 32px;
                    cursor: pointer;
                }
            }
        }
    }
}
.mobile-grace-close-account-wrap{
    display: flex;
    flex-direction: column;
    .header-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        padding: 16px;
        .left-side{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            .back-text{
                color: var(--primaryBrandBlue);
            }
        }
        .cancel-text{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
    .card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px 32px;
        margin-left: 16px;
        margin-right: 16px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        .card-header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            .title-text{
                color: var(--neutralGrey75);
            }
            .sub-title-text{
                color: var(--neutralGrey85);
            }
            .info-text{
                color: var(--neutralGrey75);
            }
        }
        .mobile-dropdown-wrapper{
            margin-top: 24px;
        }
        .mobile-navigation-wrapper{
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            border-top: 1px solid var(--grey5);
            .mobile-nav-option{
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 16px;
                border-bottom: 1px solid var(--grey5);
                cursor: pointer;
                &:last-child{
                    border-bottom: none;
                }
                .nav-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
    .button-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 16px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 993;
        background-color: var(--white);
        .left{
            .back-text{
                color: var(--primaryBrandBlue);
            }
        }
        .Button{
            width: 100%;
        }
    }
}