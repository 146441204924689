.mobile-notification-settings-wrap{
    display: flex;
    flex-direction: column;
    gap: 24px;
    .loading-box{
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--grey5);
        border-radius: 8px;
        height: 300px;
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        padding: 16px 16px 24px;
        &.pointer{
            cursor: pointer;
        }
        .failed-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px;
            .header-text{
                color: var(--neutralGrey85);
                margin-top: 16px;
                margin-bottom: 8px;
            }
            .sub-text{
                color: var(--neutralGrey75);
            }
        }
        .card-title{
            color: var(--neutralGrey75);
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .data-row{
            display: flex;
            flex-direction: column;
            gap: 16px;
            &.full-border{
                border: 1px solid var(--grey5);
                padding: 16px;
                margin-top: 16px;
                border-radius: 8px;
            }
            &.pointer{
                cursor: pointer;
            }
            &.margin{
                margin-bottom: 16px;
            }
            .top{
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: space-between;
            }
            .label-text{
                color: var(--neutralGrey75);
            }
            .info-text{
                color: var(--neutralGrey85);
            }
        }
    }
}