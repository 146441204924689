.mobile-basic-edit-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    .basic-edit-header-wrap{
        display: flex;
        align-items: center;
        .header-text{
            padding-left: 16px;
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        .back-image{
            height: 20px;
            width: 20px;
            cursor: pointer;
        }
    }
    .data-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px 32px;
        margin-top: 16px;
        border-radius: 8px;
        .title-text{
            color: var(--neutralGrey85);
            &.margin{
                margin-bottom: 8px;
            }
            &.email{
                max-width: 97%;
                word-wrap: break-word;
            }
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .label-text{
            color: var(--neutralGrey85);
            display: flex;
            flex-direction: column;
            gap: 4px;
            &.error{
                color: var(--darkRed);
            }
            .input-box{
                padding: 8px 16px;
                border: 1px solid var(--neutralGrey25);
                border-radius: 4px;
                &.error{
                    border: 1px solid var(--darkRed);
                }
            }
        }
        .Button{
            margin-top: 24px;
        }
    }
    .remove-number-wrap{
        display: flex;
        align-items: center;
        margin-top: 24px;
        justify-content: center;
        .icon{
            cursor: pointer;
        }
        .red-text{
            color: var(--darkRed);
            cursor: pointer;
            padding-left: 8px;
        }
    }
}