.beneficiary-edit{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    .basic-account-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
        padding-right: 56px;
        padding-top: 16px;
        padding-bottom: 24px;
        .split-pipe{
            color: var(--secondayLightBlue);
            font-size: 22px;
            padding-left: 8px;
            padding-right: 8px;
        }
        .data-wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
            .return-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                margin-bottom: 24px;
                cursor: pointer;
                .return-text{
                    color: var(--white);
                }
            }
            .account-description-text{
                color: var(--white);
            }
            .account-number{
                color: var(--secondayLightBlue);
            }
        }
    }
    .instruction-text{
        color: var(--neutralGrey55);
    }
    .Chart-Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 32px;
        margin-right: 32px;
        margin-bottom: 32px;

        .Chart-Settings{
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .Header-Text{
                padding-bottom: 8px;
                color: var(--headerBlack);
            }
            .Regular-Text{
                color: var(--neutralGrey85);
            }
        }
    }
    .container{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        margin-top: 32px;
        justify-content: center;
        &.add-flow{
            padding-left: 0px;
            padding-right: 0px;
            margin-top: 0px;
        }
        .desktop{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            width: 100%;
            max-width: 1120px;
            border-radius: 8px;
            padding: 32px;
            margin-bottom: 64px;
            &.add-flow{
                margin-top: -32px !important;
            }
            .Beneficiary-Details-Wrapper{
                display: flex;
                flex-direction: column;
                margin-top: 32px;

                .data-wrap{
                    border: 1px solid var(--grey5);
                    border-radius: 8px;
                    max-width: 583px;
                    padding: 32px;

                    .beneficiary-row-wrapper{
                        display: flex;
                        flex-direction: row;
                        border-bottom: 1px solid var(--grey5);
                        align-items: center;
                        justify-content: space-between;
                        max-width: 552px;

                        &:last-child{
                            border: none;
                        }
                    }
                }
                .Header-Line{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: var(--fleetBlack);
                }
            }
            .Total-Wrapper{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-width: 516px;
                margin-top: 16px;
                margin-bottom: 38px;
                max-width: 584px;
                width: 100%;
                padding: 24px 48px 24px 32px;
                background-color: var(--neutralBackgroundGrey);
                border-radius: 8px;
                .allocations-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
                .Totals{
                    color: var(--neutralGrey85);
                    &.error{
                        color: var(--darkRed);
                    }
                }
                .bottom-total{
                    display: flex;
                }
                .allocation-percentage{
                    color: black;
                    padding-left: 4px;
                }
                .errors-wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .sum-error-text{
                        color: var(--darkRed);
                    }
                    .zero-error-text{
                        color: var(--darkRed);
                    }
                }
            }
            .button-wrap{
                display: flex;
                flex: 1;
                justify-content: flex-end;
                gap: 16px;
            }
        }
    }
    // @keyframes closeAlertMessage{
    //     0% {
    //         height: 56px;
    //     }
    //     100% {
    //         height: 0px;
    //     }
    // }
}