
.page-wrapper{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding-right: 16px;
    &.desktop{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
    }
    .plan-change-card-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 26px;
        background-color: var(--white);
        padding: 32px 37px;
        border-radius: 8px;
        width: 100%;
        max-width: 1120px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 16px;
            padding: 24px 16px;
        }
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 32px;
            &.mobile{
                border-bottom: none;
                padding-bottom: 16px;
            }
            .header-text{
                color: var(--neutralGrey55);
            }
            .header-question{
                color: var(--neutralGrey85);
            }
        }
        .options-wrapper{
            display: flex;
            flex: 1;
            gap: 8px;
            &.mobile{
                flex-direction: column;
            }
            .option-card-wrap{
                display: flex;
                flex-direction: row;
                flex: 1;
                padding: 20px 24px;
                border: 1px solid var(--grey5);
                border-radius: 8px;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                min-height: 118px;
                cursor: pointer;
                .left-side{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 24px;
                    &.mobile{
                        gap: 16px;
                    }
                    .text-stack{
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        &.mobile{
                            gap: 4px;
                            width: 80%;
                        }
                        .option-header{
                            color: var(--neutralGrey85);
                        }
                        .option-text{
                            color: var(--neutralGrey75);
                        }
                    }
                }
                .image-arrow{
                    width: 24px;
                    height: 24px;
                    @media only screen and (max-width: 768px) {
                        height: 16px;
                        width: 16px;
                    }          
                }
            }
        }
        .button-wrap{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: flex-end;
            margin-top: 24px;
            .cancel-button{
                width: 124px;
                height: 48px;
                color: var(--primaryBrandBlue);
                background-color: var(--white);
                border: 1px solid var(--grey5);
                border-radius: 54px;
                cursor: pointer;
            }
        }
    }
}
.mobile-plan-change-header-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    padding-right: 16px;
    .mobile-cancel-button{
        background-color: transparent;
        color: var(--primaryBrandBlue);
        border: none;
        cursor: pointer;
    }
}