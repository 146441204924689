.mobile-interest-settings-change {
  display: flex;
  flex-direction: column;
  margin: 24px 16px;
  .encapsulation{
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 24px 16px 32px;
    gap: 24px;
    border-radius: 8px;
    margin-bottom: 72px;
  }
  .title-wrap {
    display: flex;
    flex-direction: column;
    .title-text {
      color: var(--headerBlack);
      margin-bottom: 8px;
    }
    .sub-title-text {
      color: var(--neutralGrey85);
    }
  }
  .mobile-card-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 8px;
    .mobile-internal-account-section {
      border: 1px solid var(--grey2);
      padding: 0px 16px 0px 16px;
      border-radius: 8px;
      .mobile-external-account-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--headerBlack);
        margin-bottom: 5px;
        margin-top: 20px;
        &.hidden {
          margin-bottom: 20px;
        }
        cursor: pointer;
        .down-arrow {
          transform: rotate(180deg);
          // animation: openIcon .3s ease-in forwards;
        }
        .up-arrow {
          transform: rotate(0deg);
          // animation: closeIcon .3s ease-in forwards;
        }
      }
      .sub-title-text {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        color: var(--neutralGrey55);
        // padding-bottom: 16px;
        // border-bottom: 1px solid var(--grey5);
      }
      .mobile-account-title {
        margin-top: 16px;
        padding-left: 4px;
        // padding-bottom: 16px;
        // border-bottom: 1px solid var(--grey5);
      }
      .mobile-radio-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .mobile-row-wrapper {
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: space-between;
          padding-top: 16px;
          .radio-label {
            display: flex;
            flex-direction: column;
            // flex-grow: 1;
            width: 100%;
            .mobile-radio-account{
              color: var(--neutralGrey55);
            }
            .external-text{
              color: var(--neutralGrey55);
            }
          }
          .current-selection {
            text-align: center;
            max-width: 120px;
            padding: 4px 10px;
            background-color: var(--bluePill);
            border-radius: 20px;
            color: var(--primaryBrandBlue);
            margin-left: 40px;
            margin-top: 4px;
          }
        }
        .mobile-verification-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 50px;
          padding-bottom: 26px;
          .mobile-verification-text {
            color: var(--burntOrange);
            margin-left: 9px;
            text-decoration: underline;
            text-decoration-style: dashed;
          }
          .mobile-verification-img {
            margin-right: 8px;
          }
        }
      }
      .border-line {
        border-bottom: 1px solid var(--grey5);
      }
      .mobile-external-account-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        margin-top: 16px;
        margin-bottom: 16px;
        cursor: pointer;
        .blue-symbol {
          height: 18px;
          width: 18px;
        }
        .link-text {
          padding-left: 12px;
          color: var(--primaryBrandBlue);
        }
      }
    }
  }
  .mobile-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 16px;
    margin-top: 100px;
    box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
    position: fixed;
    bottom: var(--mobileNavHeight);
    left: 0;
    width: 100%;
    .cancel-button {
      width: 166px;
      height: 40px;
      border-radius: 54px;
      background-color: var(--white);
      color: var(--primaryBrandBlue);
      border: 1px solid var(--primaryBrandBlue);
    }
    .save-button {
      width: 166px;
      height: 40px;
      border-radius: 54px;
      background-color: var(--neutralGrey25);
      border: 1px solid var(--neutralGrey25);
      color: var(--white);
      &.active {
        background-color: var(--primaryBrandBlue);
        border: 1px solid var(--primaryBrandBlue);
      }
    }
  }
  // @keyframes closeIcon {
  //   0% {
  //       transform: rotate(180deg);
  //   }
  //   100% {
  //       transform: rotate(0deg);
  //   }
  // }
  // @keyframes openIcon {
  //   0% {
  //       transform: rotate(0deg);
  //   }
  //   100% {
  //       transform: rotate(180deg);
  //   }
  // }
}
