.display-mobile-row-wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .details-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        .name-allocation-dots-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            padding-top: 16px;
            padding-bottom: 16px;
            .left-side{
                display: flex;
                align-items: center;
                gap: 8px;
                .dot{
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                }
                .name-type-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    .member-name{
                        color: var(--neutralGrey85);
                    }
                    .member-type{
                        color: var(--neutralGrey75);
                    }
                }
            }
            .Input-Box{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 64px;
                height: 40px;
                border: 1px solid var(--neutralGrey25);
                padding-left: 8px;
                padding-right: 12px;
                margin-bottom: 6px;
                border-radius: 4px;
                .Box{
                    border: none;
                    width: 35px;
                    height: 30px;
                    &:focus{
                        outline: none;
                    }
                }
                .Percentage{
                    text-align: right;
                    color: var(--neutralGrey55);
                }
            }
            .Error-Box{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 64px;
                height: 40px;
                border: 1px solid var(--red);
                padding-left: 8px;
                padding-right: 12px;
                margin-bottom: 6px;
                border-radius: 4px;
                .Box{
                    color: var(--red);
                    border: none;
                    width: 35px;
                    height: 30px;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
    .Allocation-Error-Message{
        display: flex;
        justify-content: flex-end;
        color: var(--red);
        margin-top: -20px;
        padding-bottom: 10px;
    }
    .box-text{
        text-align: right;
        padding-right: 4px;
    }
}