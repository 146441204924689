.picker-page-wrap{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &.Mobile{
        gap: 24px;
    }
    .header-wrap{
        display: flex;
        flex-direction: column;
        .header-text{
            color: var(--neutralGrey55);
        }
        .sub-header-text{
            color: var(--neutralGrey85);
        }
    }
    .picker-wrap{
        display: flex;
        gap: 16px;
        &.Mobile{
            flex-direction: column;
        }
        .card-wrap{
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 24px;
            background-color: var(--white);
            border: 1px solid var(--grey5);
            border-radius: 8px;
            cursor: pointer;
            &:hover{
                border: 1px solid var(--neutralGrey15);
                transition: border 150ms ease-in-out;
            }
            .right-side{
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
            &.selected{
                border: 1px solid var(--primaryBrandBlue);
                background-color: rgba(10, 94, 192, .1);
            }
        }
    }
    .button-wrap{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        border-top: 1px solid var(--grey5);
        padding-top: 24px;
        &.Mobile{
            border: none;
            padding-top: 0px;
        }
        .Button{
            &.Mobile{
                width: 100%;
            }
        }
    }
}