.desktop-wrap{
    display: flex;
    flex-direction: column;
    flex: 1;
    .title-text{
        color: var(--neutralGrey85);
    }
    .sub-title-text{
        color: var(--neutralGrey75);
    }
    .inner-card-wrap{
        display: flex;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 40px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        margin-top: 24px;
        &.details{
            padding: 0px;
            border: none;
            margin-top: 0px;
            gap: 32px;
        }
        .first-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            justify-content: space-between;
            gap: 24px;
            &.details{
                flex-wrap: wrap;
                gap: 32px;
            }
        }
        .second-row{
            display: flex;
            align-items: center;
            width: 100%;
            gap: 24px;
            &.details{
                justify-content: flex-start;
                flex-direction: column;
            }
            .second-row-inputs-wrap{
                display: flex;
                align-items: center;
                flex: 1;
                gap: 16px;
                &.details{
                    flex-wrap: wrap;
                }
                .label-wrap{
                    display: flex;
                    flex-direction: column;
                    align-self:flex-start;
                    gap: 4px;
                    flex: 1;
                    &.details{
                        width: 100%;
                    }
                    .label-text-wrap{
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        flex: 1;
                        .amount-text{
                            color: var(--neutralGrey85);
                        }
                        .require-star{
                            color: var(--darkRed);
                        }
                    }
                    .input-box-wrap{
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        flex: 1;
                        border: 1px solid var(--neutralGrey25);
                        border-radius: 4px;
                        &.error{
                            border: 1px solid var(--darkRed);
                        }
                        .input-box{
                            display: flex;
                            flex: 1;
                            border: none;
                            max-width: 100%;
                        }
                    }
                    .error-text{
                        color: var(--darkRed);
                    }
                }
            }
            .transfers-text-wrap{
                display: flex;
                flex: 1;
                margin-top: 28px;
                // align-self:flex-start;
                .transfers-text{
                    color: var(--neutralGrey55);
                }
            }
        }
        .border{
            border: 1px solid var(--grey5);
            margin-top: 32px;
        }
        .nav-wrap{
            display: flex;
            align-items: center;
            gap: 48px;
            .nav-option{
                display: flex;
                align-items: center;
                color: var(--primaryBrandBlue);
                gap: 8px;
                cursor: pointer;
            }
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .button-wrap{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .Button{
            margin-top: 24px;
            cursor: pointer;
            &.details{
                margin-top: 16px;
                text-align: center;
                width: 100%;
            }
        }
    }
}

.mobile-wrap{
    display: flex;
    flex-direction: column;
    .inner-card-wrap{
        display: flex;
        flex-direction: column;
        gap: 24px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        padding: 24px 16px;
        margin-top: 24px;
        &.details{
            border: none;
            padding: 0px;
        }
    }
    .label-wrap{
        display: flex;
        flex-direction: column;
        align-self:flex-start;
        gap: 4px;
        width: 100%;
        flex: 1;
        .label-text-wrap{
            display: flex;
            align-items: center;
            gap: 4px;
            .amount-text{
                color: var(--neutralGrey85);
            }
            .require-star{
                color: var(--darkRed);
            }
        }
        .input-box-wrap{
            display: flex;
            align-items: center;
            padding: 16px;
            flex: 1;
            border: 1px solid var(--neutralGrey25);
            border-radius: 4px;
            &.error{
                border: 1px solid var(--darkRed);
            }
            .input-box{
                display: flex;
                flex: 1;
                border: none;
            }
        }
        .error-text{
            color: var(--darkRed);
        }
    }
    .border{
        border-bottom: 1px solid var(--grey5);
    }
    .nav-wrap{
        display: flex;
        flex-direction: column;
        gap: 24px;
        .nav{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            .nav-text{
                color: var(--primaryBrandBlue);
            }
        }
    }
    .margin{
        margin-top: 24px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }    
}

.error-wrapper{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    gap: 16px;
    border: 1px solid var(--errorBorder);
    background-color: var(--errorBackground);
    padding: 24px; 
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 8px;
    width: 100%;
    .image{
        height: 32px;
        width: 32px;
    }
    .message-stack{
        display: flex;
        flex-direction: column;
        gap: 4px;
        .header-text{
            color: var(--darkRed);
        }
        .sub-header-text{
            color: var(--neutralGrey85);
        }
    }
}