.yodlee-fastlink-loading-box{
    position: absolute;
    // width: 100%;
    // max-width: 1120px;
    min-height: 384px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey5);
    border-radius: 8px;
    &.Mobile{
        top: -36px;
        right: -2px;
    }
}
.yodlee-launch-loader-wrap{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1120px;
    margin-left: -32px;
    &.Mobile{
        margin-left: -16px;
    }
    .yodlee-launch-loader{  
        border-radius: 8px;
        background-color: var(--grey5);
        &.middle-box{
            margin-top: 384px;
            width: 372px;
            height: 24px;
            &.Mobile{
                margin-top:376px;
                width: 100%; 
            }
        }
        &.bottom-box{
            margin-top: 8px;
            width: 274px;
            height: 16px;
            &.Mobile{
                width: 100%;
                max-width: 274px;
            }
        }
    }
}
.fast-link-container{
    // min-height: 591px;
}