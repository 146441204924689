.notification-modal-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    ::-webkit-scrollbar {
        width: 8px;
    }          
    .notification-modal-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        border-radius: 8px;
        background-color: var(--white);
        width: 100%;
        max-width: 528px;
        max-height: 90vh;
        overflow: auto;
        &.Mobile{
            margin-left: 16px;
            margin-right: 16px;
        }
        .image{
            height: 64px;
            width: 64px;
        }
        .header-text{
            margin-top: 16px;
            margin-bottom: 16px;
            color: var(--neutralGrey85);
        }
        .sub-text{
            color: var(--neutralGrey75);
            margin-bottom: 32px;
        }
        .Button{
            width: 100%;
            max-width: 216px;
            &.mobile{
                max-width: 100%;
            }
        }
    }
}