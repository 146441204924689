.beneficiary-confirmation-wrap{
    display: flex;
    flex-direction: column;
    .confirmation-info-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        .checkmark{
            height: 64px;
            width: 64px;
        }
        .confirmation-text{
            max-width: 432px;
            text-align: center;
        }
        .confirmation-details{
            text-align: center;
        }
        .confirmation-buttons-wrap{
            display: flex;
            flex-direction: row;
            gap: 32px;
            margin-bottom: 16px;
            .Button{
                min-width: 200px;
            }
            &.mobile{
                flex-direction: column;
                gap: 24px;
                width: 100%;
            }
        }
    }
}