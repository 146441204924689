.radio-button {
  padding-left: 30px;
  // background-color: var(--neutralBackgroundGrey);
  color: var(--headerBlack);
  display: flex;
  align-items: center;
  &.mobile-radio {
    padding-left: 0px;
    flex-grow: 1;
  }
  &.no-padding{
    padding-left: 0px;
  }
  cursor: pointer;
  border: 1px solid transparent;
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    .label-text {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-left: 18px;
      &.mobile {
        max-width: 90%;
        padding-left: 16px;
        color: var(--neutralGrey85);
      }
      &.Interest{
        padding-left: 8px;
      }
    }

    .radio-circle {
      -webkit-appearance: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      min-height: 25px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      outline: none;
      border: 2.5px solid gray;
      background-color: white;
      cursor: pointer;
      &.disabled {
        border: 2.5px solid var(--neutralGrey25);
        background-color: var(--neutralBackgroundGrey);
        cursor: not-allowed;
      }
      &:checked:after {
        min-width: 12.5px;
        min-height: 12.5px;
        width: 12.5px;
        height: 12.5px;
        border-radius: 50%;
        background-color: var(--primaryBrandBlue);
        content: '';
        display: inline-block;
      }
      &:checked {
        border-color: var(--primaryBrandBlue);
      }
    }
  }
  .radio-active {
    border: 1px solid var(--primaryBrandBlue);
  }
}
