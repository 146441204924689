.bene-delete-page-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 40px;
    padding-right: 56px;
    &.Mobile{
        padding-left: 16px;
        padding-right: 16px;
    }
    .bene-delete-confirmation-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1120px;
        background-color: var(--white);
        padding-top: 40px;
        padding-bottom: 48px;
        margin-top: 40px;
        border-radius: 8px;
        gap: 32px;
        &.Mobile{
            margin-top: 24px;
            padding-top: 24px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
            gap: 24px;
        }
        .checkmark{
            width: 64px;
            height: 64px;
            &.Mobile{
                width: 48px;
                height: 48px;
            }
        }
        .bene-text{
            color: var(--neutralGrey75);
            text-align: center;
            &.Mobile{
                margin-top: -16px;
            }
        }
        .Button{
            &.Mobile{
                width: 100%;
            }
        }
    }
}