.card-wrapper {
  .container {
    
    .desktop {
  
      .header-wrapper {
        margin-top: 32px;
        margin-bottom: 16px;
        display: none;
        flex-direction: column;
        .header-text {
          margin-bottom: 8px;
        }
      }
  
      .content-wrapper {
        padding: 40px 32px 24px;
				border-radius: 0 0 8px 8px;
        background-color: var(--white);
        
        .form-wrapper {
          padding-top: 0;
  
          .form {
            min-height: 378px;
            
            .row {
              display: flex;
              flex-flow: row nowrap;
              gap: 16px;

              .text-input-wrap, .ssn-input-wrap {
                margin: 0 !important;
                padding-bottom: 24px;
                
                &.quarter {
                  width: 25% !important;
                }
                
                &.third {
                  width: 33.333% !important;
                  // flex: 1;
                }
                
                &.half {
                  width: 50% !important;
                  // flex: 1;
                }
                
                &.twothird {
                  width: 66.666% !important;
                }

                &.fullwidth {
                  width: 100% !important;
                }

                input {
                  width: 100% !important;
                }
              }

              .ssn-input-wrap,
              .phone-input-wrap,
              .date-input-wrap,
              .tin-input-wrap,
              .number-input-wrap,
              .select-input-wrap {
                flex: none;
                margin: 0 !important;
                padding-bottom: 24px;

                .input-label-wrap {
                  //width: 100%;

                  .ssn-wrap,
                  .inputs-wrap {
                    padding: 12px 16px;
                    //width: 100%;

                    input {
                      padding: 0;
                      text-align: center;
                      //width: auto !important;
                      //flex: 1;
                    }
                    span {
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .mobile {}
  }

  &.account-header {

  }

  &.add-beneficiary-form {
    display: flex;
    flex-direction: column;
    .button-options-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .left-side{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-left: 16px;
      cursor: pointer;
      .back-text{
        color: var(--primaryBrandBlue);
      }
    }
    .cancel-button{
      padding: 16px;
      text-align: right;
      color: var(--primaryBrandBlue);
      cursor: pointer;
    }
  
    .container {
    
      .desktop {
        margin-top: 32px;
        margin-bottom: 40px;

        .control-bar {
          margin-top: 32px;
        }
  
        .content-wrapper {
          padding: 40px 32px 24px;
          border-radius: 0 0 8px 8px;
          background-color: var(--white);
        }
      }

      .mobile {
        // margin-top: 24px;
        margin-bottom: 32px;
        border-radius: 8px;
        &.form{
          margin-left: 16px;
          margin-right: 16px;
        }
        .title-lockup {

          .pre-header {
        
            .content {
              font-size: 14px;
            }
          }
        
          h2 {
            font-size: 20px;
            line-height: 24px;
          }
        }
      
        h3 {
          font-size: 16px;
        }

        // .progress-indicator {
        //   height: 4px;
        // }

        .grey-line {
          margin-bottom: 24px;
        }

        .control-bar {
          margin-top: 32px;
          padding: 24px 0;
        }

        .content-wrapper {
          padding: 24px 16px 0 !important;
          border-radius: 0 0 8px 8px !important;
          background-color: #fff;
  
          .form-wrapper {
            padding: 0;
            
            .form {
              margin-bottom: 24px;
              .row {
                flex-flow: column;
                &.mobile{
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  margin-bottom: 16px;
                }                
                .text-input-wrap {
            
                  &.third, &.half, &.twothird {
                    width: 100% !important;
                  }
                  &.quarter {
                    width: 50% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
