.amount-filter-wrapper{
    display: flex;
    flex-direction: row;
    width: 100vw;
    gap: 10px;
    margin-top: 32px;
    &.Mobile{
        margin-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .amount-input-row-wrapper{
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
        max-width: 100%;
        gap: 8px;
        &.Mobile{
            flex-direction: column;
            gap: 24px;
        }
    }
    .amount-single-input-wrap{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .label-text{
        margin-left: 4px;
        color: var(--neutralGrey85);
    }
    .amount-input-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        background-color: var(--white);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid var(--neutralGrey25);
        width: 212px;
        height: 48px;
        &.Mobile{
            width: 100%;
            // width: 168px;
        }
        &.is-error {
            border: solid var(--darkRed) 2px !important;
        }
        &:focus-within {
            border: 1px solid var(--primaryBrandBlue);
            
        }
        &.selected {
            border: 1px solid var(--primaryBrandBlue);
        }
    }
    .amount-input-box{
        width: 182px;
        border: none;
        color: var(--neutralGrey75);
        &:focus{
            // border: 1px solid transparent;
            outline: none;    
            // color: var(--neutralGrey75);
        }
        &.Mobile{
            width: 90%;
            // width: 136px;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}