.transfers-dropdown-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .label-wrap{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .label-text-wrap{
            display: flex;
            align-items: center;
            gap: 4px;
            .label-text{
                color: var(--neutralGrey85);
            }
            .required-star{
                color: var(--darkRed);
            }
        }
        .input-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--white);
            border: 1px solid var(--neutralGrey25);
            border-radius: 4px;
            flex: 1;
            padding: 16px;
            width: 100%;
            .input-box{
                border: none;
                width: 100%;
                cursor: pointer;
                color: var(--neutralGrey25);
                &.selected{
                    color: var(--neutralGrey85);
                }
            }
            .arrow-image{
                transition: ease-in .3s; 
                &.open{
                    transform: rotate(180deg);
                }
                &.close{
                    transform: rotate(0deg);
                }
            }
        }
        .available-balance-text{
            position: absolute;
            color: var(--neutralGrey55);
        }
    }
    .drop-down-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        position: absolute;
        margin-top: 96px;
        width: 100%;
        z-index: 995;
        max-height: 450px;
        overflow: auto;
        &:last-child{
            border: none;
        }
        .option-row-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid var(--grey5);
            background-color: var(--white);
            .grouping-block{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: var(--neutralBackgroundGrey);
                padding: 16px;
                width: 100%;
                .grouping-title{
                    color: var(--neutralGrey85);
                }
                .red-circle-error-icon {
                    height: 24px;
                    width: 24px;
                    margin-right: 8px;
                }
            }
            .option-row{
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 16px;
                justify-content: space-between;
                flex: 1;    
                .left-side{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .title-row{
                        color: var(--neutralGrey85);
                        &.selected{
                            color: var(--primaryBrandBlue);
                        }
                    }
                    .balance-row{
                        color: var(--neutralGrey55);
                        &.selected{
                            color: var(--primaryBrandBlue);
                        }
                    }
                }
            }
        }
    }
}