.MobileTransactionDetails {
  top: var(--mobileHeaderHeight);
  bottom: var(--mobileNavHeight);
  left: 0;
  right: 0;
  position: fixed;
  overflow: auto;
  z-index: 990;
  background-color: var(--grey1);
  .Account-Header-Wrap {
    z-index: 990;
    display: flex;
    flex-direction: column;
    margin-top: var(--mobileFDICHeight);
  }
  .Data-Wrapper {
    background-color: white;
    padding: 16px;
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 8px;
    margin-bottom: 64px;
    .Title-Wrapper {
      padding-top: 8px;
      margin-bottom: 24px;
      .card-title{
        color: var(--neutralGrey85);
      }
    }
    .Transaction-Description {
      color: var(--neutralGrey75);
      margin-bottom: 4px;
    }
    .Transaction-Amount-Wrapper {
      padding-bottom: 10px;
      display: flex;
      .Status {
        margin-top: 13px;
        margin-left: 8px;
        font-weight: 600;
      }
    }
    .Row {
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 1px solid var(--grey5);
      .Label {
        color: var(--neutralGrey85);
        margin-bottom: 4px;
      }
      .Value {
        color: var(--neutralGrey75);
      }
    }
    .Memo-Row {
      padding-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-direction: column;
      .Memo-Link {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--primaryBrandBlue);
        cursor: pointer;
        .Memo-Link-Text {
        }
        .Pencil {
          margin-left: 5px;
          padding-right: 8px;
        }
      }
    }
    .Memo-Message {
      margin-top: 10px;
      color: var(--neutralGrey75);
      text-align: left;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .Text-Area {
      margin-top: 10px;
      padding-right: 16px;
      position: relative;
      ::placeholder {
        color: var(--neutralGrey25);
      }
      .Input-Memo {
        padding: 12px 16px;
        width: 100%;
        min-height: 123px;
        resize: none;
        border: 1px solid var(--neutralGrey25);
        color: var(--neutralGrey55);
        border-radius: 4px;
      }
      .Character-Count {
        bottom: 5px;
        position: absolute;
        right: 21px;
        color: var(--neutralGrey55);
      }
    }
    .Buttons {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: right;
      gap: 16px;
      .Save-Button {
        background-color: var(--primaryBrandBlue);
        color: white;
        margin-right: 10px;
        border: none;
      }
      .Cancel-Button {
        color: var(--primaryDarkBlue);
        background-color: white;
        border: 2px solid var(--primaryDarkBlue);
      }
    }
  }
  .Disclaimer-Wrapper {
    padding: 24px 16px 36px;
    background-color: var(--grey1);
    .Disclaimer {
      width: 100%;
      text-align: left;
    }
  }
}

.toast-wrapper {
  display: flex;
  justify-content: center;
  z-index: 999;
}