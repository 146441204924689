.Delete-Background-Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 991;
    background-color: var(--black);
    opacity: 0.4;
}
.Mobile-Delete-Beneficiary-Wrapper{
    position: fixed;
    top: 25% !important;
    right: 5% !important;
    z-index: 993;    
    width: 90%;
    .Card-Details{
        display: flex;
        flex-direction: column;
        padding-left: 48px;
        padding-right: 48px;
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: var(--white); 
        border-radius: 8px;  
        .Icon{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: center;
            .Delete-Icon{
                height: 64px;
                width: 64px;
            }
        }
        .Top-Message{
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
            margin-top: 16px;
            .Bolded-Message{
                color: var(--black);
            }
        } 
        .Bottom-Message{
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
            margin-top: 8px;
            .Text-Message{
                color: var(--black);
            }
        }
        .Button-Wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            margin-top: 16px;
            gap: 8px;
            .Cancel-Button{
                width: 279px;
                height: 45px;
                border: 2px solid var(--primaryBrandBlue);
                color: var(--primaryBrandBlue);
                background-color: var(--white);
                border-radius: 27px;
                cursor: pointer;
            }
            .Remove-Button{
                width: 279px;
                height: 45px;
                border: 2px solid var(--primaryBrandBlue);
                color: var(--white);
                background-color: var(--primaryBrandBlue);
                border-radius: 27px;
                cursor: pointer;
            }
        }
    }
}
