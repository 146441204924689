.early-withdrawal-page-wrap{
    display: flex;
    flex-direction: column;
    .account-header-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
    }
    .loading-box-wrapper{
        display: flex;
        width: 100%;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .loading-box{
            width: 100%;
            max-width: 1120px;
            background-color: var(--grey5);
            border-radius: 8px;
            height: 46vh;
            margin-top: 32px;
        }
    }
}