.mobile-interest-settings-landing{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    .encapsulate{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 24px 16px 32px;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
        border-radius: 8px;
        .title-messages{
            display: flex;
            flex-direction: column;
            .title-text{
                color: var(--headerBlack);
                margin-bottom: 8px;
            }
            .subtitle-text{
                color: var(--neutralGrey85);
            }
        }
        .card-wrapper{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            padding: 16px;
            border: 1px solid var(--grey5);
            border-radius: 8px;
            .account-row{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    display: flex;
                    flex-direction: column;
                    .account-title-text{
                        color: var(--headerBlack);
                        margin-bottom: 4px;
                    }
                    .account-type{
                        color: var(--neutralGrey55);
                    }
                }
                .right-side{
                    display: flex;
                    flex-direction: row;
                    .edit-pencil{
                        height: 19px;
                        width: 19px;
                        cursor: pointer;
                    }
                }
                .change-click{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
        }
    }
}