.tooltip-wrapper{
    display: flex;
    flex-direction: column;
    z-index: 990;
    position: absolute;
    &.top-right{
        margin-right: -10px;
    }
    .shadow{
        -webkit-filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
        position: absolute;
        top: 22px;
        width: 22px;
        height: 18px;
        margin-left: 10px;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
        &.top-right{
            right: 10px;
        }
    }
    .tooltip-message-wrapper{
        position: absolute;
        margin-top: 38px;
        padding: 16px;
        background-color: var(--white);
        border-radius: 8px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        z-index: 990;
        .header-message{
            color: var(--neutralGrey75);
        }
        .tooltip-message{
            color: var(--neutralGrey75);
            white-space: pre-wrap;
        }
    }
}