.Transactions-API-Error {
  display: flex;
  flex-direction: column;
  height: 192px;
  background-color: var(--white);
  border-radius: 8px;
  justify-content: center;
  padding-top: 1rem;
  .API-Error-Inner-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .Image {
      height: 72px;
      width: 72px;
    }
    .Top-Text {
      margin-top: 24px;
      color: var(--black);
    }
    .Bottom-Text {
      margin-top: 14px;
      color: var(--black);
    }
  }
}
