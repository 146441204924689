.desktop-date-selector-wrap{
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    cursor: pointer;
    width: 100%;
    position: relative;
    .image{
        padding-right: 11px;
    }
    .filter-selection-box{
        display: flex;
        flex-direction: row;
        padding: 12px;
        background-color: var(--white);
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        .left-side{
            display: flex;
            align-items: center;
            .date-text{
                white-space: nowrap;
                &.disabled{
                    color: var(--neutralGrey15);
                }
                &.selected {
                    color: var(--neutralGrey75);
                }
        
            }
        }
        &.disabled{
            border: 1px solid var(--neutralGrey15);
        }
    }
    .open{
        border: 1px solid var(--primaryBrandBlue);
        box-shadow: 0px 0px 0px 2px rgba(94, 121, 197, 0.15);
        color: var(--black);
    }
    .closed{
        border: 1px solid var(--neutralGrey15);
        color: var(--neutralGrey55);
    }
    .drop-down-menu{
        position: absolute;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-top: 60px;
        z-index: 990;
        background-color: var(--white);
        width: 100%;
        // max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }    
        .row-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            .left{
                display: flex;
                align-items: center;
                width: 100%;
                gap: 8px;
            }
            .date-text{
                color: var(--black);
                &.selected-blue {
                    color: var(--primaryBrandBlue)
                }
            }
        }
        .bottom-border{
            border-bottom: 1px solid var(--grey2);
        }
    }
}