.failed-to-link-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 48px;
    &.Mobile{
        background-color: var(--white);
        border-radius: 8px;
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 72px 16px;
        margin-bottom: 64px;
    }
    .failed-image{
        height: 72px;
        width: 72px;
        &.Mobile{
            height: 64px;
            width: 64px;
        }
    }
    .failed-title-text{
        color: var(--neutralGrey85);
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
    .failed-message-text{
        color: var(--neutralGrey75);
        text-align: center;
        margin-bottom: 32px;
    }
    .failed-button-wrapper{
        display: flex;
        width: 100%;
        justify-content: center;
        .Button{
            &.Mobile{
                width: 100%;
            }
        }
    }
}