.drop-down-wrapper{
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: var(--secondaryDarkestBlue);
    .close-details-divider{
        display: flex;
        flex: 1;
        animation: closeDropDown 0s ease-in forwards;
        overflow: hidden;
    }
    .show-details-divider{
        border-top: 1px solid rgba(256, 256, 256,.2);    
        display: flex;
        flex: 1;
        animation: openDropDown .25s ease-in forwards;
        overflow: hidden;
    }
    .show-details{
        display: flex;
        flex: 1;
        gap: 11%;
    }
    .left-side{
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        width: 38%;
    }
    .right-side{
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .label-value-wrapper{
        display: flex;
        flex-direction: row;
        gap: 22%;
        &.right{
            gap: 6%;
        }
        .left{
            display: flex;
            flex-direction: column;
        }
        .right{
            display: flex;
            flex-direction: column;
        }
        .details-text{
            color: var(--secondayLightBlue);
            opacity: 0.8;
            width: 125px;
            min-width: 125px;
            white-space: nowrap;
            padding-bottom: 16px;
            &.right{
                width: 248px;
            }
        }
        .values-text{
            width: 137px;
            color: var(--white);
            white-space: nowrap;
            padding-bottom: 16px;
        }
        .owner-values-wrap{
            display: flex;
            align-items: center;
            .owner-values-text{
                display: inline-block;
                color: var(--white);
                width: 100%;
                max-width: 137px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }    
            .owner-edit-ownership{
                height: 20px;
                width: 20px;
                margin-left: 14px;
                cursor: pointer;
            }
        }
        .death-wrap{
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
            .label-text{
                color: var(--white);
            }
            .death-payment-edit{
                height: 20px;
                width: 20px;
            }
        }
        .joint-account{
            cursor: pointer;
        }
    }
}

