.mobile-maturity-main-wrapper{
    display: flex;
    flex-direction: column;
    .header-wrapper{
        background-color: var(--secondaryDarkestBlue);
        padding-left: 32px;
    }
    .mobile-maturity-loading-state-wrapper{
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
        .mobile-maturity-loading-state{
            background-color: var(--grey5);
            border-radius: 8px;
            height: 46vh;
            width: 100%;
        }
    }
    .maturity-card-wrapper{
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background-color: var(--white);
        padding: 24px 16px;
        margin-top: 23px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 46px;
        .card-header-wrap{
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            .title-text{
                color: var(--neutralGrey85);
                margin-bottom: 8px;
            }
            .mature-text{
                color: var(--neutralGrey75);
            }   
        }
        .column-cards-wrapper{
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            gap: 24px;
            .left-box{
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                min-width: 60%;
                .maturity-plan-title{
                    color: var(--neutralGrey85);
                    margin-bottom: 24px;
                }
                .maturity-date-wrap{
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 24px;
                    gap: 2px;
                    .maturity-date-header{
                        color: var(--neutralGrey85);
                    }
                    .maturity-date-text{
                        color: var(--neutralGrey75);
                    }
                }
                .action-wrap{
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 24px;
                    justify-content: space-between;
                    align-items: center;
                    &.no-border{
                        border: none;
                        padding-bottom: 0px;
                    }
                    .left-side{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        .action-header{
                            color: var(--neutralGrey85);
                        }
                        .action{
                            color: var(--neutralGrey75);
                        }
                    }
                    .right-side{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;
                        .change-text{
                            color: var(--primaryBrandBlue);
                            &.remove{
                                color: var(--darkRed);
                            }
                        }
                    }
                }
            }
            .right-box{
                display: flex;
                flex-direction: column;
                padding: 16px;
                background-color: var(--neutralBackgroundGrey);
                border: 1px solid var(--grey5);
                border-radius: 8px;
                min-width: 32%;
                height: 236px;
                &.gracie{
                    height: auto;
                }
                .header-text{
                    color: var(--neutralGrey85);
                }
                .peronal-cd-wrap{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    margin-top: 24px;
                    .personal-cd-header{
                        color: var(--neutralGrey85);
                    }
                    .personal-cd{
                        color: var(--neutralGrey75);
                    }
                }
                .term-apy-row-wrap{
                    display: flex;
                    flex-direction: row;
                    gap: 24px;
                    margin-top: 24px;
                    .current-term-wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        .curernt-term-header{
                            color: var(--neutralGrey85);
                        }
                        .current-term{
                            color: var(--neutralGrey75);
                        }
                    }
                    .apy-wrap{
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        .current-apy-header{
                            color: var(--neutralGrey85);
                        }
                        .current-apy{
                            color: var(--neutralGrey75);
                        }
                    }
                }
            }
        }
    }
}