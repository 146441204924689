.desktop-edit-address-wrapper{
    display: flex;
    flex-direction: column;
    .header-page-wrap{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        background-color: var(--secondaryDarkestBlue);
        .header-wrapper{
            width: 100%;
            max-width: 1120px;
            padding-top: 32px;
            padding-bottom: 32px;
            .top-line{
                display: flex;
                flex-direction: row;
                .back-arrow{
                    cursor: pointer;
                }
                .origin-text{
                    padding-left: 12px;
                    color: var(--white);
                    cursor: pointer;
                }
            }
        }
    }
    .card-page-wrapper{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .card-wrapper{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            padding: 32px;
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 83px;
            width: 100%;
            max-width: 1120px;
            .required-sub-text{
                color: var(--neutralGrey75);
                margin-top: 8px;
                margin-bottom: 24px;
                .required-star{
                    color: var(--darkRed);
                }
            }
            .address-text{
                color: var(--neutralGrey85);
            }
            .sub-text{
                color: var(--neutralGrey75);
                margin-top: 8px;
                .required-star{
                    color: var(--darkRed);
                }
            }
            .bottom-border{
                margin-top: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
            }
            .address-text{
                color: var(--neutralGrey85);
                &.set-margin{
                    margin-bottom: 8px;
                }
            }
            .required-text{
                color: var(--neutralGrey75);
                // margin-top: 24px;
                &.error{
                    color: var(--darkRed);
                }
                .red-star{
                    color: var(--darkRed);
                }
                &.no-margin{
                    margin-top: 0px;
                }
            }
            .first-row{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px;
                margin-top: 24px;
                .half{
                    flex: 1;
                }
            }
            .second-row{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 24px;
                gap: 20px;
            }
            .checkbox-tooltip-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 32px;
                margin-left: 4px;
                .tooltip-icon{
                    cursor: pointer;
                    position: relative
                }
                .tooltip-message-wrap{
                    position: absolute;
                    margin-left: 254px;
                    margin-bottom: 4px;
                }
            }
            .bottom-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    .remove-text{
                        color: var(--darkRed);
                    }
                }
                .button-wrap{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    justify-content: flex-end;
                }
            }
        }
    }
}