.Desktop-SideNavigation-Wrapper{
    position: fixed;
    top: 65px;
    min-width: 216px;
    max-width: 216px;
    height: 100vh;
    max-height: calc(100vh - var(--headerHeight) - 33px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: var(--white);
    overflow-y: auto;
    margin-top: 32px;
    &::-webkit-scrollbar {
        width: 8px;
    }

    @media only screen and (max-wdith: 808px){
        max-width: 180px;
    }
    .Nav-Wrapper{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex-grow: 1;
        padding-top: 22px;
        padding-left: 12px;
        padding-right: 16px;
    }
    .Accounts{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .Account-Line{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-grow: 1;
            align-items: center;
            padding-top: 16px;
            padding-left: 11px;
            padding-bottom: 16px;
            background-color: var(--white);    
            cursor: pointer;
            .Accounts-Text {
                padding-left: 9px;
                text-align: center;
            }
        }
        .Focused{
            color: var(--primaryBrandBlue);
            background-color: rgba(#0A5EC0, .1);
            border-radius: 8px;
        }
        .Account-Listing{
            padding-top: 20px;
        }
        .Account-Wrap{
            border-left: 2px solid var(--fleetBlue);
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            padding: 12px 8px 12px 16px;
            cursor: pointer;
            gap: 4px;

            .Account-Title{
                color: var(--primaryGrey85);
                line-height: 20px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .Account-Type{
                color: var(--neutralGrey75);
            }
        }
        .Focus-Account{
            border-left: 2px solid var(--primaryBrandBlue);
        }
        .Focus-Title{
            color: var(--primaryBrandBlue) !important;
        }
    }
    .Transfers{
        margin-top: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        .Transfer-Line{
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 13px; 
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            cursor: pointer;   
        }
        .Transfers-Text{
            padding-left: 12px;
        }
        .Focused{
            color: var(--primaryBrandBlue);
            background-color: rgba(#0A5EC0, .1);
            border-radius: 8px;
        }
    }
    .Documents{
        .Documents-Line{
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 13px; 
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            cursor: pointer;   
        }
        .Documents-Text{
            padding-left: 12px;
        }
        .Focused{
            color: var(--primaryBrandBlue);
            background-color: rgba(#0A5EC0, .1);
            border-radius: 8px;
        }
    }
}
