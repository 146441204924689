.mobile-grace-landing-wrap{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    .top-card{
        display: flex;
        flex-direction: column;
        padding: 24px 16px;
        background-color: var(--white);
        border-radius: 8px;
        .header-text-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
        }
        .details-wrap{
            display: flex;
            flex-direction: column;
            gap: 24px;
            .details-header{
                color: var(--neutralGrey85);
            }
            .option-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    .option-text{
                        color: var(--neutralGrey75);
                    }
                    .option-value{
                        color: var(--neutralGrey85);
                    }
                    .pill-wrap{
                        display: flex;
                        padding-left: 16px;
                        padding-right: 16px;
                        border-radius: 24px;
                        background-color: var(--bluePill);
                        .maturity-pill{
                            color: var(--primaryBrandBlue);
                        }
                    }    
                }
                .edit-pencil{
                    cursor: pointer;
                }
            }
            .close-link{
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
            .close-account-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                .close-chevron{
                    cursor: pointer;
                    &.close{
                        transform: rotate(0deg);
                        transition: .3s ease-in-out;
                    }
                    &.open{
                        transform: rotate(180deg);
                        transition: .3s ease-in-out;
                    }        
                }
            }
            .phone-text{
                color: var(--neutralGrey75);
                margin-left: 8%;
                margin-top: -8px;
                .blue-text{
                    color: var(--primaryBrandBlue);
                    text-decoration: none;
                }
            }
        }
    }
    .bottom-card{
        display: flex;
        flex-direction: row;
        padding: 16px;
        background-color: var(--white);
        border-radius: 8px;
        margin-top: 16px;
        gap: 8px;
        cursor: pointer;
        .left-side{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            .column-stack{
                display: flex;
                flex-direction: column;
                gap: 2px;
                .header-text{
                    color: var(--black);
                }
                .sub-header-text{
                    color: var(--neutralGrey75);
                }
            }
        }
    }
}
.mobile-grace-landing-loading-wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    .loading-box{
        width: 100%;
        max-width: 1120px;
        border-radius: 8px;
        background-color: var(--grey5);
        &.top{
            height: 400px;
        }
        &.bottom{
            height: 100px;
        }
    }
}
