.mobile-overview-middle-wrapper{
    margin-top: 24px;
    .mobile-layout{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(256,256,256,.2);
        .balance-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .balance{
                color: var(--white);
            }
            .title-row{
                display: flex;
                align-items: center;
                gap: 8px;
                .icon{
                    cursor: pointer;
                }
            }
        }
        .account-details{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-grow: 1;
            margin-top: 24px;
            .interest-rate-wrap{
                display: flex;
                flex-direction: column;
                .bottom-row-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                    position: relative;
                }
                .bumped-arrow{
                    cursor: pointer;
                }
                .bump-circle{
                    position: absolute;
                    bottom: 6px;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }
            .term-wrap{
                display: flex;
                flex-direction: column;
            }
            .matures-on-wrap{
                display: flex;
                flex-direction: column;
            }
            .values{
                color: var(--white);
            }
        }
        .text{
            color: var(--secondayLightBlue);
            opacity: 0.8;
        }
        .mobile-middle-loading{
            display: flex;
            flex-direction: column;
            gap: 24px;
            .top-row{
                display: flex;
                flex: 1;
                justify-content: center;
                .loading-box-1{
                    height: 64px;
                    width: 50%;
                    background-color: var(--white);
                    opacity: 0.1;
                    border-radius: 8px;
                }
            }
            .bottom-row{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                .loading-box-2{
                    height: 48px;
                    width: 24%;
                    background-color: var(--white);
                    opacity: 0.1;
                    border-radius: 8px;
                }
            }
        }
    }
    sup{
        font-size: 50%;
        vertical-align: super;
        position: relative;
        bottom: 6px;
    }
    .description-text{
        color: var(--secondayLightBlue);
    }
}