.desktop-transfers-landing{
    display: flex;
    flex-direction: column;
    .header-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 48px;
        padding-right: 56px;
        background-color: var(--secondaryDarkestBlue);
        .header-text{
            color: var(--white);
            width: 100%;
            max-width: 1120px;
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
    .card-wraps{
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding-left: 48px;
        padding-right: 56px;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 64px;
        .transfers-card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            width: 100%;
            max-width: 1120px;
            padding: 32px;
        }
        .scheduled-transfers-card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            width: 100%;
            max-width: 1120px;
            padding: 32px;
        }
        .transfer-error-wrapper{
            display: flex;
            flex-direction: column;
            .title-text{
                color: var(--neutralGrey85);
            }
            .error-stack{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 32px;
                .error-image{
                    height: 64px;
                    width: 64px;
                }
                .header-text{
                    margin-top: 16px;
                    color: var(--neutralGrey85);
                }
                .sub-text{
                    color: var(--neutralGrey75);
                }
            }
        }
        .loading-box{
            width: 100%;
            max-width: 1120px;
            background-color: var(--grey5);
            border-radius: 8px;
            height: 300px;
        }
    }
}