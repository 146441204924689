.bump-rate-modal-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 994;
    .message-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 564px;
        background-color: var(--white);
        padding: 48px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-radius: 8px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            width: 92%;
            padding: 32px;
        }
        .image{
            width: 64px;
            height: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            margin-top: 16px;
            margin-bottom: 8px;
            text-align: center;
        }
        .sub-header-text{
            color: var(--neutralGrey75);
            text-align: center;
        }
        .modal-button-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-top: 32px;
            width: 100%;
            &.mobile{
                flex-direction: column;
            }
            button{
                flex: 1;
                white-space: nowrap;
            }
            .Button{
                width: 100%;
            }
        }
    }
}