.mobile-cd-header-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    .cancel-text{
        color: var(--primaryBrandBlue);
        cursor: pointer;
    }
}
.grace-cd-select-wrap{
    width: 100%;
    max-width: 1120px;
    &.mobile{
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 32px;
        width: auto;
    }
    .change-cd-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 8px;
        padding: 40px 32px;
        &.mobile{
            padding: 16px;
            border: 1px solid var(--grey5);
        }
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid var(--grey5);
            .title-text{
                color: var(--neutralGrey55);
            }
            .sub-title-text{
                color: var(--neutralGrey85);
            }
        }
        .grace-display-options{
            display: flex;
            flex-direction: column;
            .grace-cd-options{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 24px;
                &.mobile{
                    flex-direction: column;
                }
                .grace-cd-group{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .cd-header-wrap{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 24px;
                        gap: 8px;
                        .header-text{
                            color: var(--neutralGrey75);
                        }
                        .tooltip-wrap{
                            position: relative;
                            .information-image{
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                            }
                            .tooltip{
                                position: absolute;
                                right: 345px;
                                bottom: 20px;
                            }
                        }
                    }
                    .row-wrapper{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        padding-right: 20px;
                        border: 1px solid var(--grey5);
                        border-radius: 4px;
                        margin-bottom: 8px;
                        cursor: pointer;
                        &.selected{
                            background-color: rgba(10, 94, 192, 0.1);
                            border: 1px solid var(--primaryBrandBlue);    
                        }
                        .show-apy{
                            color: var(--neutralGrey55);
                        }
                    }
                }
            }
            .button-wrap{
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: flex-end;
                gap: 16px;
                margin-top: 40px;
                border-top: 1px solid var(--grey5);  
                padding-top: 24px;
                .cancel-button{
                    width: 124px;
                    height: 48px;
                    background-color: var(--white);
                    color: var(--primaryBrandBlue);
                    border: 1px solid var(--grey5);
                    border-radius: 54px;
                    cursor: pointer;
                }
                .continue-button{
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    width: 124px;
                    height: 48px;
                    cursor: pointer;
                    &.disabled{
                        background-color: var(--neutralGrey25);
                        border: 1px solid var(--neutralGrey25);
                    }
                }
            }
            .mobile-continue-button-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 16px;
                position: fixed;
                bottom: 0px;
                left: 0px;
                right: 0px;
                background-color: var(--white);
                box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
                border-top: 1px solid var(--grey5);
                z-index: 994;
                .mobile-continue-button{
                    width: 100%;
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    padding: 8px 24px;
                    border: 1px solid var(--primaryBrandBlue);
                    border-radius: 54px;
                    cursor: pointer;
                    &.disabled{
                        background-color: var(--neutralGrey15);
                        border: 1px solid var(--neutralGrey15);
                    }
                }
            }
            .rate-text{
                color: var(--neutralGrey55);
                margin-top: 24px;
                margin-bottom: 8px;
            }
        }
    }
}