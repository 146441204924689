.Delete-Background-Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 991;
    background-color: var(--black);
    opacity: 0.4;
}
.Delete-Beneficiary-Card-Wrapper{
    position: fixed;
    top: 34% !important;
    right: 36.5% !important;
    z-index: 993;    
    width: 508px;
    .Card-Details{
        display: flex;
        flex-direction: column;
        padding-left: 48px;
        padding-right: 48px;
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: var(--white); 
        border-radius: 8px;  
        .Icon{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: center;
            .Delete-Icon{
                height: 64px;
                width: 64px;
            }
        }
        .Top-Message{
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
            margin-top: 32px;
            .Bolded-Message{
                color: var(--black);
            }
        } 
        .Bottom-Message{
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            flex-wrap: wrap;
            margin-top: 8px;
            .Text-Message{
                color: var(--black);
            }
        }
        .Button-Wrap{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: space-between;
            margin-top: 32px;
            height: 45px;
            .Cancel-Button{
                width: 200px;
                border: 2px solid var(--primaryBrandBlue);
                color: var(--primaryBrandBlue);
                background-color: var(--white);
                border-radius: 27px;
                cursor: pointer;
            }
            .Remove-Button{
                width: 200px;
                border: 2px solid var(--primaryBrandBlue);
                color: var(--white);
                background-color: var(--primaryBrandBlue);
                border-radius: 27px;
                cursor: pointer;
            }
        }
    }
}
