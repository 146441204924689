.hybrid-layout-wrapper{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .middle {
    min-height: calc(100vh - var(--headerHeight));
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 31px;
    .children-wrapper {
      width: 100%;
      .children {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - var(--headerHeight));
        height: auto;
        width: 100%;
        background-color: var(--neutralBackgroundGrey);
        padding-top: calc(var(--headerHeight) - 4px);
      }
    }
  }  
  .footer{
    margin-bottom: 64px;
    &.error {
      margin-bottom: 0px;
    }
  }
  .side-navigation{
    position: fixed;
    background-color: var(--white);
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}