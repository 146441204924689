.confirmation-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .image{
        height: 64px;
        width: 64px;
    }
    .header-text{
        color: var(--neutralGrey75);
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .sub-text{
        color: var(--neutralGrey75);
        text-align: center;
        max-width: 530px;
        width: 100%;
        margin-bottom: 56px;
    }
    .Button{
        &.Mobile{
            width: 100%;
        }
    }
}