.mobile-user-profile-wrapper{
    display: flex;
    flex-direction: column;
    .overlay-wrap{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.4);
        z-index: 994;
        .edit-box{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            border-radius: 8px 8px 0px 0px;
            .header-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                border-bottom: 1px solid var(--grey5);
                .blue-text{
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
                .header-text{
                    color: var(--neutarlGrey85);
                }
            }
            .input-wrap{
                display: flex;
                padding: 16px;
                .input-label{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 4px;
                    color: var(--neutralGrey85);
                    padding-bottom: 16px;
                    .input-box{
                        padding: 8px 16px 8px 16px;
                        border: 1px solid var(--neutralGrey25);
                        border-radius: 4px;
                    }
                }
            }
        }
    }
    .mobile-profile-header-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--secondaryDarkestBlue);
        width: 100%;
        padding: 24px 56px 24px 16px;
        gap: 4px;
        .top-row{
            display: flex;
            align-items: center;
            .user-name{
                color: var(--white);
                padding-right: 16px;
                cursor: pointer;
            }
            .edit-icon{
                cursor: pointer;
            }
        }
        .loading-box{
            background-color: var(--white);
            opacity: .1;
            height: 40px;
            width: 160px;
            border-radius: 8px;
        }
        .customer-since-text{
            color: var(--secondayLightBlue);
        }
    }
    .bottom-card{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border: 1px solid var(--grey5);
        border-radius: 8px;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 24px;
        .row-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 16px;
            padding-top: 16px;
            cursor: pointer;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;
                .tab-image{
                    height: 26px;
                    width: 26px;
                }
                .tab-text{
                    color: var(--neutralGrey75);
                }
            }
            .arrow-image{
                width: 24px;
                height: 24px;
            }
        }
        .border{
            margin-left: -16px;
            margin-right: -16px;
            border-bottom: 1px solid var(--grey5);
        }    
    }
    .sign-out-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 16px;
        .sign-out-text{
            cursor: pointer;
            color: var(--darkRed);
        }
    }
}