.owner-confirmation-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .image{
        width: 64px;
        height: 64px;
    }
    .header-text{
        color: var(--neutralGrey75);
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;
    }
    .message-text{
        color: var(--neutralGrey75);
        text-align: center;
    }
    .button-wrap{
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 32px;
        &.mobile{
            flex-direction: column;
            width: 100%;
        }
        .Button{
            &.mobile{
                width: 100%;
            }
        }
    }
}