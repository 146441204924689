.total-balance {
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  color: #ffffff;
  letter-spacing: -0.107143px;
  .desktop-greeting-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .greeting-text{
      display: flex;
      align-items: center;
      color: var(--white);
    }
    .icon {
      margin-left: 3px;
      height: 40px;
      width: 40px;
    }
  }
  .total-balance-message {
    color: var(--white);
  }
  .total-balance-amount {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
  }
  .desktop {
    background: var(--secondaryDarkestBlue);
    border-radius: 8px;
    height: 164px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2.5rem;
    .greeting {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 85px;
    }
    .balance {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 85px;
      .total-balance-message {
        display: flex;
        justify-content: right;
        color: var(--white);
      }
      .total-balance-amount {
        display: flex;
        justify-content: right;
      }
    }
  }
  .mobile {
    background: #00244e;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    gap: 24px;
    .greeting-message {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      .icon-box{
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .icon{
        height: 36px;
        margin-bottom: 8px;
      }
    }
    .white-line {
      width: 100%;
      height: 1px;
      background: rgba(256,256,256,.2);
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .balance {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 55px;
      .total-balance-message {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 21px;
      }
      .total-balance-amount {
        display: flex;
        justify-content: center;
        font-size: 40px;
        line-height: 52px;
      }
    }
  }
  sup{
    font-size: 50%;
    vertical-align: super;
    position: relative;
    bottom: 6px;
  }
}

.desktop-account-box-loading-state{
  display: flex;
  flex-direction: column;
  height: 164px;
  border-radius: 8px;
  background-color: var(--secondaryDarkestBlue);
  width: 100%;
  .loading-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left-side{
      margin-top: 44px;
      margin-left: 32px;
      .left-top{
        height: 40px;
        width: 471px;
        background-color: rgba(255, 255, 255, .1);
        border-radius: 12px;
      }
      .left-bottom{
        height: 24px;
        width: 247px;
        background-color: rgba(255, 255, 255, .1);
        margin-top: 12px;
        border-radius: 12px;
      }
    }
    .right-side{
      margin-top: 44px;
      margin-right: 48px;
      .right-top{
        position: relative;
        left: 153px;
        height: 24px;
        width: 120px;
        background-color: rgba(255, 255, 255, .1);
        border-radius: 12px;
      }
      .right-botom{
        width: 274px;
        height: 56px;
        background-color: rgba(255, 255, 255, .1);
        border-radius: 12px;
        margin-top: 12px;
      }
    }
  }
}

.mobile-account-box-loading-state{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 193px;
  background-color: var(--secondaryDarkestBlue);
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  .gretting-line{
    height: 32px;
    width: 273px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, .1);
    margin-top: 24px;
  }
  .divider-line{
    width: 100%;
    margin-top: 24px;
    border-top: 3px solid rgba(255,255,255,.1);
  }
  .total-balance-text{
    height: 24px;
    width: 130px;
    margin-top: 24px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 12px;
  }
  .total-balance{
    height: 42px;
    width: 182px;
    background-color: rgba(255, 255, 255, .1);
    margin-top: 8px;
    border-radius: 12px;
  }
}

