.api-error-state-account-overview{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--neutralBackgroundGrey);
    .display-error{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100vh - var(--footerHeight) - var(--headerHeight));
      .inner-display{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;  
        .top-message{
          margin-top: 24px;
          color: var(--black);
        }
        .lower-message{
          margin-top: 14px;
          color: var(--black);
        }
      }
    }
}
  