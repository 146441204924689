.option-box {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  cursor: pointer;
  transition: border 150ms ease-in-out;

  .image {
    height: 48px;
    width: 48px;
  }

  .content {
    flex: 1;

    .title {
      margin-bottom: 0px !important;
    }
  }

  &:hover {

    &:before {
      border-color:#D8D8D8;
    }
    
  }

  &:before {
    content: '';
    position: absolute;
    top:0;right:0;bottom:0;left:0;
    border: 1px solid #E8EAF0;
    pointer-events: none;
    border-radius: 8px;
    transition: border var(--durButtonHover) ease-in-out;
  }

  &.active {
      background: rgba(#0A5EC0, 0.1);
      
      &:before {
        border: 2px solid #0a5ec0;
      }
  }
}