.mobile-alert-box-wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 995;
    .data-wrapper{
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 994;
        background-color: var(--white);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .alert-header-wrap{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;
            justify-content: center;
            align-items: center;
            padding: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            .header-message-text{
                color: var(--headerBlack);
            }
            .done-button{
                position: absolute;
                right: 16px;
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .body-message-text{
            color: var(--neutralGrey85);
            padding-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
            white-space: pre-wrap;
        }
    }
}