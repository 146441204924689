.mobile-security-settings-wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;
    .loading-box{
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--grey5);
        border-radius: 8px;
        height: 250px;
        &.small{
            height: 120px;
        }
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        padding: 16px 16px 24px;
        .failed-devices-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .failed-image{
                height: 64px;
                width: 64px;
            }
            .message-title{
                margin-top: 16px;
                margin-bottom: 8px;
                color: var(--neutralGrey85)
            }
            .message-text{
                color: var(--neutralGrey75);
            }
        }
        .card-title{
            color: var(--neutralGrey75);
        }
        .card-sub-title{
            margin-top: 8px;
            color: var(--neutralGrey55);
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .data-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.cursor{
                cursor: pointer;
            }
            &.margin{
                margin-bottom: 16px;
            }
            .left-side{
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
            .edit-image{
                cursor: pointer;
            }
        }
    }
}