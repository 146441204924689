.mobile-beneficiary-summary{
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 108px);
    .card-wrapper{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 56px;
        .beneficiary-details-wrapper{
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 48px;
            margin-top: 16px;
            background-color: var(--white);
            border-radius: 8px;      
            .title-wrap{
                border-bottom: 1px solid var(--grey5);
                margin-left: 16px;
                margin-right: 16px;
                padding-top: 24px;
                padding-bottom: 16px;
            }
            .header-columns{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: space-between;
                padding-left: 16px;
                padding-right: 16px;
                margin-top: 24px;
                .table-header-text{
                    color: var(--neutralGrey55);
                }
                .right {
                    padding-right: 64px;
                }
            }
            .display-mobile-beneficiaries{
                display: flex;
                flex-direction: column;
                flex: 1;
                .row-wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 16px;
                    flex: 1;
                    .left-side{
                        display: flex;
                        align-items: center;
                        .dot{
                            gap: 8px;
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                        }
                        .name-type-stack{
                            display: flex;
                            flex-direction: column;
                            margin-left: 12px;
                            &.one{
                                margin-left: 0;
                            }
                            .Member-Name {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                width: 180px;
                                color: var(--headerBlack);
                                &.one{
                                    margin-left: -50px;
                                }
                            }
                        }
                    }
                    .right-side{
                        display: flex;
                        align-items: center;
                        gap: 55px;
                        .elipses{
                            height: 24px;
                            width: 24px;
                            cursor: pointer;
                        }
                    }
                }
                .bottom-border{
                    margin-left: 16px;
                    margin-right: 16px;
                    border-bottom: 1px solid var(--grey5);
                }
            }
            .pie-wrapper{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                justify-content: center;
                margin-top: 24px;
                margin-bottom: 24px;
                .pie-chart{
                    height: 145px;
                    width: 145px;
                }
            }
        }
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: var(--white);
        padding: 16px;
        position: fixed;
        bottom: var(--mobileSideBarHeight);
        width: 100%;
        margin-top: 40px;
        .add-beneficiary {
            width: 100%;
        }

    }
    .mobile-beneficiary-page-api-error{
        display: flex;
        flex-direction: column;
        .error-state-card-wrapper{
            display: flex;
            flex-direction: column;
            height: 360px;
            background-color: var(--white);
            margin-top: 16px;
            margin-left: 16px;
            margin-right: 16px;
            padding: 16px;
            .sub-text{
                margin-top: 8px;
                color: var(--neutralGrey75);
                margin-bottom: 24px;
            }
            .data-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                .top-text{
                    margin-top: 24px;
                    color: var(--black);
                }
                .bottom-text{
                    margin-top: 14px;
                    margin-left: 20px;
                    margin-right: 20px;
                    text-align: center;
                    color: var(--black);
                }
            }
        }
    }
    .pop-up-menu-wrap{
        display: flex;
        flex-direction: column;
        background-color: rgba(0,0,0,0.4);
        overflow-y: hidden;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 994;
        .pop-up-menu{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px 8px 0px 0px;
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            .header-row{
                display: flex;
                align-items: center;
                padding: 16px;
                border-bottom: 1px solid var(--grey5);
                .beneficiary-name{
                    color: var(--neutralGrey85);
                    text-align: center;
                    flex: 1;
                }
                .cancel-click{
                    position: absolute;
                    right: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .menu-option{
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 16px;
                &.border{
                    border-bottom: 1px solid var(--grey5);
                }
                cursor: pointer;
                .edit-text{
                    color: var(--primaryBrandBlue);
                }
                .delete-text{
                    color: var(--darkRed);
                }
            }
        }
    }
}

.mobile-beneficiary-loading-state{
    display: flex;
    flex-direction: column;
    .loading-card{
        display: flex;
        min-height: 572px;
        background-color: var(--white);
        border-radius: 8px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        .loading-box{
            flex: 1;
            background-color: var(--grey5);
            border-radius: 8px;
        }
    }
}