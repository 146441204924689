.mobile-manage-devices-wrap{
    display: flex;
    flex-direction: column;
    .devices-mobile-header{
        display: flex;
        align-items: center;
        padding: 16px;
        .blue-text{
            color: var(--primaryBrandBlue);
            padding-left: 16px;
            cursor: pointer;
        }
        .header-image{
            cursor: pointer;
        }
    }
    .data-card-wrapper{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--white);
        padding: 24px 16px 32px;
        border-radius: 8px;
        margin-bottom: 64px;
        .header-text{
            color: var(--neutralGrey85);
            margin-bottom: 16px;
        }
        .device-wrap{
            display: flex;
            flex-direction: column;
            gap: 2px;
            padding: 10px 0px 14px;
            border-bottom: 1px solid var(--grey5);
            &:last-child{
                border: none;
            }
            .current-device{
                max-width: 110px;
                border-radius: 20px;
                padding: 2px 12px 4px;
                color: var(--primaryBrandBlue);
                background-color: var(--fleetBlue);
            }
            .device-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .elipses{
                    cursor: pointer;
                }
            }
            .device{
                color: var(--neutralGrey75);
            }
            .sub-text{
                color: var(--neutralGrey55);
            }
        }
        .delete-modal-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background-color: rgba(0,0,0,0.4);
            z-index: 994;
            .modal-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--white);
                padding: 48px;
                border-radius: 8px;
                max-width: 528px;
                margin-left: 16px;
                margin-right: 16px;
                .title-text{
                    color: var(--neutralGrey85);
                    margin-top: 16px;
                    margin-bottom: 8px;
                    text-align: center;
                }
                .modal-text{
                    color: var(--neutralGrey75);
                }
                .button-wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;
                    margin-top: 32px;
                    width: 100%;
                    .Button{
                        width: 100%;
                    }
                }
            }
        }
    }
    .mobile-device-menu-wrap{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 994;
        background-color: rgba(0,0,0,0.4);
        .menu-wrap{
            position: fixed;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background-color: var(--white);
            border-radius: 8px 8px 0px 0px;
            .header-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 16px;
                border-bottom: 1px solid var(--grey5);
                .header-text{
                    color: var(--neutralGrey75);
                }
                .cancel-text{
                    position: absolute;
                    right: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .menu-row-wrap{
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 16px;
                cursor: pointer;
                &.border{
                    border-bottom: 1px solid var(--grey5);
                }
                .blue-text{
                    color: var(--primaryBrandBlue);
                }
                .red-text{
                    color: var(--darkRed);
                }
            }
        }
    }
}