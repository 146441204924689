.transfer-review-wrap{
    display: flex;
    flex-direction: column;
    .title-wrap{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        .title-text{
            color: var(--neutralGrey85);
        }
        .sub-title-text{
            color: var(--neutralGrey75);
        }
    }
    .error-wrapper{
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        gap: 16px;
        border: 1px solid var(--errorBorder);
        background-color: var(--errorBackground);
        padding: 24px; 
        border-radius: 8px;
        margin-bottom: 32px;
        width: 100%;
        .image{
            height: 32px;
            width: 32px;
        }
        .message-stack{
            display: flex;
            flex-direction: column;
            gap: 4px;
            .header-text{
                color: var(--darkRed);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
    }
    .inner-data-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--neutralBackgroundGrey);
        border-radius: 8px;
        padding: 32px 32px 40px 32px;
        gap: 24px;
        border: 1px solid var(--grey5);
        &.mobile{
            padding: 24px 16px 24px;
        }
        .inner-title{
            color: var(--neutralGrey85);
        }
        .transfer-accounts-row{
            display: flex;
            align-items: center;
            gap: 16px;
            &.mobile{
                flex-direction: column;
                align-items: flex-start;
            }
            .transfer-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                background-color: var(--white);
                border: 1px solid var(--grey5);
                border-radius: 8px;
                width: 100%;
                max-width: 320px;
                padding: 12px 16px;
                min-height: 110px;
                &.mobile{
                    max-width: none;
                }
                .wrap-text{
                    color: var(--neutralGrey85);
                }
                .transfer-row{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .data-stack{
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        .account-title{
                            color: var(--neutralGrey85);
                        }
                        .account-info-text{
                            color: var(--neutralGrey55);
                        }
                    }
                }
            }
        }
        .label-value-wrap{
            display: flex;
            flex-direction: column;
            gap: 4px;
            .text{
                color: var(--neutralGrey85);
            }
        }
    }
    .buttons-wrap{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        .left-side{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            .back-arrow{
                width: 18px;
                height: 20px;
            }
            .edit-transfer-text{
                color: var(--primaryBrandBlue);
            }
        }
        .Button{
            cursor: pointer;
        }
    }
}