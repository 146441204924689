.Landing-Wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .account-settings {
        display: flex;
        width: 100%;
        justify-content: center;

        .container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            .desktop-settings-loading-state{
                display: flex;
                max-width: 1120px;
                width: 100%;
                min-height: 542px;
                background-color: var(--grey5);
                border-radius: 8px;
                margin-top: 32px;
                margin-bottom: 40px;
                padding: 48px;
                .loading-box{
                    flex: 1;
                    background-color: var(--grey5);
                    border-radius: 8px;
                }
            }
            .desktop{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                background-color: var(--white);
                border-radius: 8px;
                margin-top: 32px;
                width: 100%;
                max-width: 1120px;
                margin-bottom: 40px;
                .content-wrapper {
                    border-radius: 8px !important;
                    width: 100%;
                    padding: 32px;

                    .show-cards-wrap{
                        display: flex;
                        flex-flow: row wrap;
                        gap: 16px;

                        .card{
                            display: flex;
                            flex-direction: row;
                            border: 1px solid var(--grey5);
                            width: calc(50% - 8px);
                            justify-content: space-between;
                            padding: 16px 24px;
                            background-color: var(--white);
                            border-radius: 8px;
                            align-items: center;
                            cursor: pointer;
                            transition: all var(--durButtonHover) ease-in-out;

                            &:hover {
                                border: 1px solid var(--lightGrey);
                            }

                            .left-side{
                                display: flex;
                                flex-direction: row;

                                .text-wrap{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 4px;
                                    padding-left: 24px;
                                    .title{
                                        color: var(--neutralGrey85);
                                    }
                                    h3{
                                        color: var(--neutralGrey75);
                                        margin: 0;
                                    }
                                    p{
                                        color: var(--neutralGrey75);
                                        font-size: 16px;
                                        line-height: 24px;
                                        margin-bottom: 4px;
                                    }
                                }
                            }
                            .right-side{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 24px;
                                .activated-pill{
                                    padding: 4px 12px 4px 12px;
                                    background-color: var(--fadedGreen);
                                    border-radius: 20px;
                                    color: var(--successGreen);
                                }
                                .right-side{
                                    display: flex;
                                    flex-direction: row;
                                }
                            }
                            .arrow{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .early-withdrawl-breakpoint-wrap{
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                margin-bottom: 64px;

                .early-withdrawal-wrap{
                    width: 100%;
                    max-width: 1120px;

                    p {
                        color: var(--neutralGrey75);

                        .blue-text {
                            text-decoration: none;
                            color: var(--primaryBrandBlue);
                            cursor: pointer;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .cancel-early-withdrawal-wrap{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 12px;
                    padding: 8px 16px;
                    border: 1px solid var(--grey5);
                    border-radius: 54px;
                    cursor: pointer;

                    .cancel-text{
                        color: var(--darkRed);
                    }
                }
            }
        }
    }
    
}