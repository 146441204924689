.desktop-documents-landing-wrapper{
    display: flex;
    flex-direction: column;
    .centering-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        padding-top: 48px;
        padding-bottom: 48px;
        background-color: var(--secondaryDarkestBlue);
        .header-title{
            width: 100%;
            max-width: 1120px;
            color: var(--white);
        }
        .loading-box-title-wrap{
            display: flex;
            justify-content: flex-start;
            width: 100%;
            max-width: 1120px;
            .loading-box-title{
                text-align: left;
                width: 277px;
                height: 40px;
                border-radius: 8px;
                background-color: var(--white);
                opacity: .1;
            }
        }
    }
    .documet-center-box-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        .document-center-box{
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 1120px;
            .loading-box-header-wrapper{
                display: flex;
                align-items: center;
                gap: 24px;
                border-bottom: 1px solid var(--neutralGrey15);
                padding-bottom: 24px;
                width: 100%;
                margin-top: 24px;
                .loading-box{
                    background-color: var(--grey5);
                    height: 24px;
                    border-radius: 8px;
                    &.small{
                        width: 10%;
                    }
                    &.medium{
                        width: 12%;
                    }
                    &.large{
                        width: 20%;
                    }
                }
            }
        }
    }
    .card-loading-box-page-wrap{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        margin-top: 24px;
        margin-bottom: 64px;
        .card-loading-box{
            background-color: var(--grey5);
            width: 100%;
            max-width: 1120px;
            border-radius: 8px;
            height: 456px;
        }
    }
    .document-card-page-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        margin-top: 32px;
        margin-bottom: 64px;
        width: 100%;
        .document-card-wrap{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            width: 100%;
            max-width: 1120px;
            border: 1px solid var(--grey5);
            border-radius: 8px;
            padding: 24px;
            .filter-row{
                display: flex;
                flex-direction: row;
                gap: 24px;
                .filter-box{
                    display: flex;
                    flex-direction: column;
                    margin-top: -4px;
                    width: 100%;
                    max-width: 22%;
                    .date-text{
                        // color: var(--neutralGrey85);
                    }
                }
            }
        }
    }
}