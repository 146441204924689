.verification-code-box-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
    .input-char{
        border: 1px solid var(--grey5);
        border-radius: 4px;
        width: 48px;
        height: 48px;
        padding: 8px 12px;
        color: var(--neutralGrey75);
        text-align: center;
        &.fail{
            border: 2px solid var(--darkRed);
        }
        &.mobile{
            width: 40px;
            padding: 8px;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }    
}