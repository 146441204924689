.desktop-interest-settings-wrapper{
    display: flex;
    flex-direction: column;
    .header-wrapper{
        display: flex;
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
        width: 100%;
        justify-content: center;
    }
}
.interest-loading-wrapper{
    display: flex;
    width: 100%;
    padding-left: 48px;
    padding-right: 56px;
    justify-content: center;
    .desktop-interest-loading-state{
        display: flex;
        background-color: var(--grey5);
        max-width: 1120px;
        width: 100%;
        border-radius: 8px;
        padding: 40px 32px 24px;
        min-height: 299px;
        margin-top: 32px;
    }
}
.failed-api-page-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    padding-left: 48px;
    padding-right: 56px;
    .failed-api-data-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        background-color: var(--white);
        width: 100%;
        max-width: 1120px;
        .failed-image{
            width: 64px;
            height: 64px;
        }
        .header-text{
            color: var(--neutralGrey85);
            margin-top: 24px;
        }
        .sub-text{
            color: var(--neutralGrey75);
        }
    }
}