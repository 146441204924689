.mobile-fund-transfer-wrap{
    display: flex;
    flex-direction: column;
    .step-header{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 16px;
        .cancel-button{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        &.two{
            justify-content: space-between;
            padding-left: 16px;
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 9px;
                cursor: pointer;
                .back-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
    .step-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        // margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 24px 16px;
        border: 1px solid ver(--grey5);
        border-radius: 8px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            margin-bottom: 24px;
            .header-text{
                color: var(--neutralGrey55);
            }
            .sub-header-text{
                color: var(--neutralGrey85);
            }
        }
        .funds-message{
            color: var(--neutralGrey55);
            margin-top: 16px;
        }
        .transfer-input-wrapper{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            max-width: 520px;
            margin-bottom: 16px;
            .label-wrap{
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 8px;
                .label-text-wrap{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    flex: 1;
                    .amount-text{
                        color: var(--neutralGrey85);
                    }
                    .require-star{
                        color: var(--darkRed);
                    }
                }
                .input-box-wrap{
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    flex: 1;
                    border: 1px solid var(--neutralGrey25);
                    border-radius: 4px;
                    &.error{
                        border: 1px solid var(--darkRed);
                    }
                    .input-box{
                        display: flex;
                        flex: 1;
                        border: none;
                        width: 100%;
                    }
                }
                .error-text{
                    color: var(--darkRed);
                }
                .available-balance{
                    margin-left: 4px;
                    color: var(--neutralGrey55);
                }
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        .radio-button-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 9px;
            .radio-button-selection{
                border: 1px solid var(--grey5);
                border-radius: 4px;
                padding-top: 16px;
                padding-bottom: 16px;
                &.selected{
                    background-color: rgba(10, 94, 192, 0.1);
                    border: 1px solid var(--primaryBrandBlue);
                }
                .radio{
                    margin-left: -20px;
                }
            }
        }
        .dropdown-wrapper{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
        }
        .options-wrap{
            display: flex;
            flex-direction: column;
            .option{
                display: flex;
                flex-direction: row;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 8px;
                gap: 10px;
                border-top: 1px solid var(--grey5);
                cursor: pointer;
                &.no-border{
                    border: none;
                }
                .option-text{
                    color: var(--primaryBrandBlue);
                }
            }
        }
    }
    .button-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-top: 1px solid var(--grey5);
        background-color: var(--white);
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 72px;
        z-index: 993;
        .Button{
            width: 100%;
        }
        // .continue-button{
        //     width: 100%;
        //     height: 40px;
        //     background-color: var(--primaryBrandBlue);
        //     border: 1px solid var(--primaryBrandBlue);
        //     color: var(--white);
        //     border-radius: 54px;
        //     &.disabled{
        //         background-color: var(--neutralGrey15);
        //         border: 1px solid var(--neutralGrey15);
        //     }
        // }
    }
}