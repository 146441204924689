.card-wrapper.interest-settings{

    .container{

        .desktop{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            width: 100%;
            max-width: 1120px;
            margin-bottom: 64px;

            .content-wrapper {
                border-radius: 8px !important;
                width: 100%;

                .title-lockup {
                    margin-bottom: 32px;
                }

                .content {
                    display: block;
                    padding-bottom: 16px;
                    .account-row{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 24px;
                        width: 62.7%;
                        border: 1px solid var(--grey5);
                        border-radius: 8px;
                        .left{
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            .account-details{
                                color: var(--neutralGrey85);
                            }
                        }
                        .right{
                            display: flex;
                            flex-direction: row;
                            white-space: nowrap;
                            .edit-pencil{
                                height: 19px;
                                width: 19px;
                                cursor: pointer;
                            }
                            .change-click{
                                color: var(--primaryBrandBlue);
                                cursor: pointer;
                                position: relative;
                                padding-left: 10px;
                                height: 20px;
                                bottom: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}