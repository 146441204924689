.account-locked-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .lock-image{
        width: 64px;
        height: 64px;
    }
    .title{
        color: var(--neutralGrey85);
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .sub-text{
        color: var(--neutralGrey75);
        text-align: center;   
        &.bottom{
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }
    .Button{
        width: 100%;
    }
}