.mobile-bump-rate-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mobile-bump-loading-state{
        display: flex;
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        margin-bottom: 32px;
        min-height: 483px;
        .loading-box{
            flex: 1;
            background-color: var(--grey5);
            border-radius: 8px;
        }
    }
    .info-card-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 32px;
        .header-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            .header-text{
                color: var(--neutralGrey85);
            }
            .sub-header-text{
                color: var(--neutralGrey75);
            }
        }
        .info-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .image{
                width: 128px;
                height: 92px;
            }
            .header-text{
                color: var(--black);
                margin-top: 32px;
                margin-bottom: 8px;
                text-align: center;
            }
            .sub-header-text{
                color: var(--neutralGrey85);
                text-align: center;
            }
        }
        .apy-info-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 16px;
            margin-top: 24px;
            gap: 16px;
            &.unavailable{
                gap: 0px;
            }
            .left-side{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 16px 12px;
                &.selected{
                    border: 1px solid var(--grey5);
                    border-radius: 8px;
                }
                .current-apy{
                    color: var(--neutralGrey85);
                }
                .current-apy-text{
                    color: var(--neutralGrey75);
                }
            }
            .right-side{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 16px 24px;
                margin-right: -40px;
                &.unavailable{
                    margin-right: -20px;
                }
                &.selected{
                    border: 1px solid var(--grey5);
                    border-radius: 8px;
                }
                .top{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 7px;
                    .apy-text{
                        color: var(--neutralGrey85);
                    }
                }
            }
            .right-arrow{
                position: relative;
                right: 133px;
                background-color: var(--white);
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        width: 100%;
        padding: 16px;
        .Button{
            width: 100%;
        }
    }
}