.all-filters-overlay{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 994;
    background-color: rgba(0,0,0,0.4);
    .desktop-all-filters-wrap{
        display: flex;
        flex-direction: column;
        width: 464px;
        position: fixed;
        right: 0px;
        bottom: 0px;
        top: calc(0px + var(--headerHeight) - 3px + var(--fdicHeight));
        background-color: var(--white);
        padding-left: 16px;
        padding-right: 16px;
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-top: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid var(--grey5);
            gap: 130px;
            .back-arrow{
                cursor: pointer;
            }
            .header-text{
                color: var(--neutralGrey85);
            }
        }
        .filter-row{
            display: flex;
            flex-direction: column;  
            flex: 1;
            margin-bottom: 56px;
            .left-side{
                display: flex;
                flex-direction: row;
                .custom-date-filter{
                    display: flex;
                    flex-direction: column;
                    .custom-date-text{
                        color: var(--black);
                        margin-top: 32px;
                        margin-bottom: -16px;
                    }
                    .custom-date-wrap{
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                    }
                }
            }  
            .right-side{
                display: flex;
                flex-direction: row;
                .amount{
                    display: flex;
                    flex-direction: column;
                    .amount-text{
                        color: var(--black);
                        margin-top: 32px;
                        margin-bottom: -16px;
                    }
                }
            }
        }
        .button-row{
            display: flex;
            flex-direction: row;
            padding-top: 12px;
            padding-bottom: 12px;
            border-top: 2px solid var(--neutralBackgroundGrey);
            border-bottom: 2px solid var(--neutralBackgroundGrey);
            justify-content: space-between;
            .clear-all-button{
                color: var(--primaryBrandBlue);
                background-color: var(--white);
                border: 1px solid var(--primaryBrandBlue);
                border-radius: 54px;
                width: 124px;
                height: 52px;
                cursor: pointer;
            }
            .apply-button{
                width: 115px;
                height: 52px;
                background-color: var(--primaryBrandBlue);
                color: var(--white);
                border: 1px solid var(--primaryBrandBlue);
                border-radius: 27px;
                cursor: pointer;
            }
        }
    }
}