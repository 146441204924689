.Desktop-Account-Details-Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .Overview-Wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--secondaryDarkestBlue);
  }
  .details-nav-bar-wrap{
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: var(--grey5);
    justify-content: center;
    background-color: var(--grey5);
    padding-left: 48px;
    padding-right: 56px;
    .details-nav-bar{
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1120px;
      padding-top: 24px;
      padding-bottom: 24px;
      .nav-option{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        .option-text{
          color: var(--neutralGrey85);
        }
      }
      .divider-pipe{
        border-right: 1px solid var(--neutralGrey85);
        opacity: 0.2;
        height: 40px;
        margin-left: 32px;
        margin-right: 32px;
      }
    }
    .loading-wrapper{
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
      height: 72px;
      max-width: 1120px;
      .loading-box{
        width: 127px;
        height: 24px;
        background-color: var(--neutralBackgroundGrey);
        border-radius: 8px;
      }
      .pipe{
        height: 40px;
        width: 1px;
        background-color: var(--neutralGrey85);
      }
    }
  }
  .grace-banner-wrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 48px;
    padding-right: 56px;
    width: 100%;
    margin-top: 32px;
    .grace-banner{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--white);
      padding: 24px;
      border-radius: 8px;
      width: 100%;
      max-width: 1120px;
      gap: 16px;
      cursor: pointer;
      .left-side{
        display: flex;
        align-items: center;
        gap: 24px;
        .right{
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
  .position-wrap{
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .bump-banner-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--lightestBlue);
      border: 1px solid var(--borderBlue);
      padding: 16px 24px;
      border-radius: 8px;
      cursor: pointer;
      max-width: 1120px;
      width: 100%;
      margin-left: 48px;
      margin-right: 48px;
      .left-side{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        .text-stack{
          display: flex;
          flex-direction: column;
          gap: 8px;
          .link-text{
            color: var(--primaryBrandBlue);
          }
        }
      }
      .close-image{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .outter-transaction-wrap{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    .Transaction-History-Title-Wrapper {
      margin-left: 48px;
      margin-right: 48px;
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      margin-bottom: 24px;
      background-color: var(--white);
      border: 1px solid var(--grey5);
      border-radius: 8px;
      max-width: 1120px;
      width: 100%;
      min-height: 300px;
      .header-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 28px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 36px;
        .Vew-All-Wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 16px;
          border: 1px solid var(--grey5);
          border-radius: 4px;
          cursor: pointer;
          .View-All-Text {
            color: var(--primaryBrandBlue);
            padding-right: 9px;
          }
        }
      }
      .Transaction-Table {
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 24px;
        .empty-state-wrap{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;
          gap: 8px;
          .empty-image{
            margin-bottom: 16px;
          }
          .text{
            color: var(--black);
          }
        }
      }
    }
  }
  .loading-box-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 48px;
    padding-right: 56px;
    margin-top: 32px;
    margin-bottom: 24px;
    .loading-box{
      background-color: var(--grey5);
      width: 100%;
      max-width: 1120px;
      border-radius: 8px;
      height: 300px;
    }
  }
  .Summary-Card-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    margin-left: 48px;
    margin-right: 48px;
    margin-bottom: 174px;
    margin-top: 32px;
    gap: 32px;
    &.savings{
      margin-right: 425px;
    }
    @media only screen and (min-width: 1080px) and (max-width: 1145px){
      gap: 9px;
    }
  }
  .open-account-card-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    // margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 48px;
    margin-right: 48px;
    width: 100%;
    max-width: 1120px;
    padding: 32px;
    background-color: var(--white);
    border: 1px solid var(--grey5);
    border-radius: 8px;
    margin:auto;
    margin-bottom: 64px;
    > * {
      text-align: center;
    }
    > .title-text {
      margin-bottom: 8px;
    }
    > .sub-title-text {
      margin-bottom: 48px;
    }
      .open-account-button{
        padding: 12px 32px;
        color: var(--white);
        background-color: var(--primaryBrandBlue);
        border: 1px solid var(--primaryBrandBlue);
        border-radius: 54px;
        margin-top: 48px;
      }
  
  }
  .savings-cards-wrapper{
    display: flex;
    padding-left: 48px;
    padding-right: 48px;
    width: 100%;
    justify-content: center;
    margin-bottom: 64px;
    .left-right-wrap{
      display: flex;
      width: 100%;
      max-width: 1120px;
    }
    .savings-left-side{
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      max-width: 70%;
    }
    .savings-right-side{
      display: flex;
      flex-direction: column;
      gap: 32px;
      margin-top: 34px;
      width: 100%;
      max-width: 30%;
      .open-cd-wrap{
        display: flex;
        width: 100%;
        position: relative;
        .image{
          resize: horizontal;
          width: 100%;
          max-width: 352px;
        }
        .Button{
          height: 17.7%;
          width: 100%;
          max-width: 86.3%;
          top: 74.6%;
          left: 6.8%;
          position: absolute;
        }
      }
      .wrap-transfer{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--white);
        border: 1px solid var(--grey5);
        border-radius: 8px;
        padding: 24px;
        .title-text{
          color: var(--neutralGrey85);
          margin-bottom: 16px;
        }
        .wrap-inputs{
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;
        }
      }
    }
  }
}

.toast-wrapper {
  display: flex;
  justify-content: center;
  z-index: 999;
}

  .accounts-notification-wrapper {
  padding-inline: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
  .accounts-notification {
    width: 100%;
    max-width: 1120px;
    margin-bottom: 16px;
    display: flex;
  }
}