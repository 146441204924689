.mobile-document-center-filter{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    .filter-backdrop{
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 992;
        background-color: rgba(0,0,0,0.2)
    }
    .filter-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--white);
        border-radius: 4px;
        padding: 8px 16px;
        margin-left: 16px;
        border: 1px solid var(--neutralGrey25);
        gap: 16px;
        .filter-text{
            color: var(--neutralGrey55);
            cursor: pointer;
        }
        .filter-number{
            text-align: center;
            color: va(--primaryBrandBlue);
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: var(--secondayLightBlue);
        }
    }
    .mobile-filter{
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: var(--white);
        padding-top: 14px;
        z-index: 993;
        max-height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }    
        .no-selection{
            display: flex;
            flex-direction: column;
            .filter-header-sort{
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-grow: 1;
                justify-content: space-between;
                padding-bottom: 14px;
                border-bottom: 1px solid var(--grey5);      
                .sort-text{
                    position: relative;
                    left: 40%;
                    color: var(--headerBlack);
                    margin-left: 16px;
                }
                .cancel-text{
                    color: var(--primaryBrandBlue);
                    margin-right: 16px;
                    cursor: pointer;
                }
            }
            .filter-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 20px 16px;
                border-bottom: 1px solid var(--grey5);
                cursor: pointer;
                .left-side{
                    display: flex;
                    flex-direction: row;
                    max-width: 80%;
                    .filter-text{
                        color: var(--neutralGrey55);
                    }
                    .filter-selection{ 
                        color: var(--black);
                        margin-left: 8px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 80%;            
                    }
                }
                .arrow-icon{
                    height: 24px;
                    width: 24px;
                }
            }
        }
        .filter-selected{
            display: flex;
            flex-direction: column;
            .filter-header{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 14px;
                border-bottom: 1px solid var(--grey5);
                .back-text{
                    margin-left: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
                .account-text{
                    color: var(--headerBlack);
                }
                .save-text{
                    margin-right: 16px;
                    color: var(--primaryBrandBlue);
                    cursor: pointer;
                }
            }
            .row-display{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid var(--grey5);
                cursor: pointer;
                .account-data{
                    padding-left: 16px;
                    color: var(--black);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 80%;            
                    &.blue{
                        color: var(--primaryBrandBlue);
                    }
                }
                .checkmark-image{
                    padding-right: 16px;
                }
            }
        }
    }
}