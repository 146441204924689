.review-owner-wrapper{
    display: flex;
    flex-direction: column;
    .header-text{
        color: var(--neutralGrey85);
        margin-top: 8px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
    }
    .required-text{
        color: var(--neutralGrey75);
        margin-bottom: 32px;
    }
    .red-text{
        color: var(--darkRed);
    }
    .details-stack{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .details-wrap{
        background-color: var(--neutralBackgroundGrey);
        border: 1px solid var(--neutralGrey15);
        padding: 32px;
        border-radius: 8px;
        &.mobile{
            padding: 16px;
        }
        .details-header{
            color: var(--neutralGrey85);
        }
        .details-row{
            display: flex;
            gap: 24px;
            margin-top: 32px;
            &.mobile{
                flex-direction: column;
            }
            .left{
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 24px;
            }
            .right{
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 24px;
            }
            .label-value-wrap{
                display: flex;
                flex-direction: column;
                gap: 4px;
                .label{
                    color: var(--neutralGrey85);
                }
                .value{
                    color: var(--neutralGrey75);
                }
            }
        }
        .question-wrap{
            display: flex;
            flex-direction: column;
            gap: 24px;
            &.margin-top{
                margin-top: 32px;
            }
            .question-stack{
                display: flex;
                flex-direction: column;
                gap: 4px;
                .question{
                    color: var(--neutralGrey85);
                }
                .answer{
                    color: var(--neutralGrey75);
                }
            }
        }
    }
    .checkbox-wrap{
        display: flex;
        align-items: center;
        gap: 8px;
        .consent-text{
            color: var(--neutralGrey75);
            cursor: pointer;
        }
        .blue-text{
            color: var(--primaryBrandBlue);
            cursor: pointer;
            text-decoration: none;
        }
        .red-text{
            color: var(--darkRed);
        }
    }
    .bottom-border{
        margin-top: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--grey5);
    }
    .button-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            .blue-text{
                color: var(--primaryBrandBlue);
            }
        }
        .right{
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    .Button{
        &.mobile{
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }
}