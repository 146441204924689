.mobile-transaction-preview {
  background-color: white;
  table {
    width: 100%;
  }
  .row {
    border-bottom: 1px solid var(--grey6);
    &:last-child{
      border: none;
    }
    cursor: pointer;
    &.last{
      border: none
    }
    td {
      padding-top: 12px;
      padding-bottom: 12px;
      vertical-align: middle;
    }
    .left-side{
      .date{
        color: var(--neutralGrey55)
      }
      .description{
        color: var(--neutralGrey75);
      }
    }
    .right-side{
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-end;
      .amount-status-stack{
        .amount{
          color: var(--neutralGrey85);
        }
        .status {
          font-weight: 600;
          font-size: 12px;
          margin-right: -16px;
        }
      }
    }
    .arrow-image {
      text-align: right;
    }
  }
}