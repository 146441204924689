.api-overview-error-mobile{
    display: flex;
    flex-direction: column;
    .error-display{
      display: flex;
      flex-direction: column;
      background-color: var(--neutralBackgroundGrey);
      height: calc(100vh - var(--mobileSideBarHeight));
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      .image{
        width: 221px;
        height: 157px;
      }
      .top-message{
        margin-top: 24px;
        color: var(--black);
      }
      .bottom-message{
        margin-top: 14px;
        color: var(--black);
      }
    }
  }
  