.accounts-dropdown-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 388px;
    max-width: 396px;
    position: relative;
    .desktop-dropdown-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 50px;
        background-color: var(--white);
        margin-bottom: 40px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 8px;
        border: 1px solid var(--neutralGrey15);
        gap: 20px;
        width: 100%;
        max-width: 396px;
        &.selected {
            border: 1px solid var(--primaryBrandBlue);
            box-shadow: 0px 0px 0px 2px rgba(94, 121, 197, 0.15);
        }
        .dropdown-left{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            max-width: 336px;
            .account-text{
                color: var(--black);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
    
            }
        }
        .dropdown-right{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
        }
    }
    
    .desktop-document-dropdown-display{
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: -29px;
        z-index: 991;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        background-color: var(--white);
        position: absolute;
        margin-top: 84px;
        max-height: 300px;
        width: 100%;
        max-width: 396px;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }    
        .account-row{
            display: flex;
            flex-direction: row;
            gap: 16px;
            padding: 16px;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
            max-width: 396px;    
            .account-text{
                color: var(--neutralGrey75);
                max-width: 320px;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
                &.selected{
                    color: var(--primaryBrandBlue);
                }
            }
            .icon{
                height: 24px;
                width: 24px;
            }
        }
        .border-line{
            border-bottom: 1px solid var(--grey2);
        }
    }
    .dropdown-label{
        position: relative;
        bottom: 4px;
        color: var(--neutralGrey85);
        margin-right: 8px;
        padding-left: 4px;
    }
}
