
.Landing-Wrapper{
    display: flex;
    flex-direction: column;

    .card-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: none;
		flex-flow: column;
		margin: 0;

		.container {
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
            padding-left: 4%;
            padding-right: 4%;
			
			.desktop {
				max-width: 1120px;
				min-width: 330px;
				width: 100%;
			}
			
			.mobile {
                min-height: 100vh;
				max-width: 1120px;
				min-width: 330px;
				width: 100%;
			}
		}
    }

    .account-header{
        background-color: var(--secondaryDarkestBlue);
    }
    .header-box-loading-wrapper{
        display: flex;
        justify-content: center;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        width: 100%;
        .loading-wrap{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
        }
        .top-line{
            height: 24px;
            width: 170px;
            background-color: rgba(255,255,255,0.1);
            margin-bottom: 32px;
        }
        .middle-line{
            height: 40px;
            width: 295px;
            background-color: rgba(255,255,255,0.1);
            margin-bottom: 8px;
        }
        .bottom-line{
            height: 32px;
            width: 78px;
            background-color: rgba(255,255,255,0.1);
        }
    }
    .Basic-Account{
        background-color: var(--secondaryDarkestBlue);
        padding-left: 48px;
        padding-top: 16px;
        padding-bottom: 24px;
        .Return-Wrapper{
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;
            .Return-Arrow{
                padding-right: 9px;
                cursor: pointer;
            }
            .Return{
                cursor: pointer;
            }
        }
        .Text{
            color: var(--white);
        }
        .Account-Type-Number{
            color: var(--secondayLightBlue);
        }
        .Description{
            margin-bottom: 4px;
        }
    }
}