.toggle-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 75px;
    background-color: var(--neutralGrey25);
    border-radius: 34px;
    padding: 12px;
    cursor: pointer;
    position:relative;
    &.on{
        background-color: var(--successGreen);
    }
    .on-text{
        color: var(--white);
    }
    .off-text{
        color: var(--black);
    }
    .circle-wrapper{
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        width: 55px;
        &.left{
            justify-content: flex-start;
            margin-left: -6px;
        }
        &.right{
            justify-content: flex-end;
            margin-left: 2px;
        }
        .toggle-circle-selector{
            display: flex;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: var(--white);
            z-index: 990;
        }
    }
}