.DesktopTransactionDetails {
  .Overlay {
    animation: hideBackground 0.3s ease-out forwards;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 980;
    background-color: black;
    opacity: 0.6;
  }
  .Detail-Wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    width: 450px;
    min-height: calc(100vh - var(--headerHeight) - var(--fdicHeight));
    height: 100px;
    background-color: white;
    z-index: 981;
    opacity: 1;
    top: calc(0px + var(--headerHeight) - 3px + var(--fdicHeight));
    padding-bottom: 20px;
    .Title-Wrapper {
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 24px;
      .Arrow {
        align-items: left;
        cursor: pointer;
      }
      .Title {
        font-size: 16px;
        position: relative;
        right: 32%;
      }
    }
    .Title-Line {
      border-bottom: 1px solid var(--grey4);
    }
    .Data-Wrapper {
      padding-left: 24px;
      padding-right: 24px;
      .Transaction-Description {
        padding-top: 12px;
        padding-bottom: 4px;
      }
      .Transaction-Amount-Wrapper {
        margin-bottom: 24px;
        display: flex;
        .Transaction-Amount {
          font-size: 32px;
        }
        .Status {
          margin-left: 8px;
          padding-top: 7px;
          font-weight: 600;
        }
      }
      .Row {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--grey5);
        .Row-Title {
          color: var(--black);
        }
      }
      .Memo-Row {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        padding-bottom: 24px;
        .Memo-Link {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--primaryBrandBlue);
          cursor: pointer;
          .Memo-Link-Text {
          }
          .Pencil {
            margin-left: 5px;
            padding-right: 8px;
          }
        }
      }
      .Memo-Message {
        color: #00244e;
        text-align: left;
        margin-bottom: 10px;
      }
      .Text-Area {
        position: relative;
        ::placeholder {
          color: var(--neutralGrey25);
        }
        .Input-Memo {
          padding: 12px 16px;
          width: 100%;
          min-height: 123px;
          resize: none;
          border: 1px solid var(--neutralGrey25);
          color: var(--neutralGrey55);
          border-radius: 4px;
        }
        .Character-Count {
          bottom: 5px;
          position: absolute;
          right: 5px;
          color: var(--neutralGrey55);
        }
      }
      .Buttons {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: right;
        gap: 16px;
        .Save-Button {
          background-color: var(--primaryBrandBlue);
          color: white;
          margin-right: 10px;
          border: none;
        }
        .Cancel-Button {
          color: var(--primaryDarkBlue);
          background-color: white;
          border: 2px solid var(--primaryDarkBlue);
        }
      }
      .Disclaimer {
        text-align: left;
      }
    }
  }
  .Open {
    animation: OpenDetails 0.25s ease-in forwards;
    z-index: 990;
    position: fixed;
    right: 0px;
    top: calc(var(--headerHeight) - 3px + var(--fdicHeight));
    height: calc(100vh - var(--headerHeight));
  }
  .Close {
    animation: CloseDetails 0.25s ease-out forwards;
    z-index: 990;
    position: fixed;
    right: 0px;
    top: calc(var(--headerHeight) - 3px);
    min-height: calc(100vh - var(--headerHeight));
  }

  @keyframes OpenDetails {
    0% {
      width: 475px;
      height: calc(100vh - var(--headerHeight));
      overflow-y: hidden;
      right: -475px;
    }
    99% {
      overflow-y: hidden;
    }
    100% {
      right: 0px;
      height: calc(100vh - var(--headerHeight));
    }
  }
  @keyframes CloseDetails {
    0% {
      width: 475px;
      right: 0px;
      min-height: calc(100vh - var(--headerHeight));
      overflow-y: hidden;
    }
    100% {
      width: 0px;
      // right: -475px;
      min-height: calc(100vh - var(--headerHeight));
    }
  }
  @keyframes hideBackground {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }
}
