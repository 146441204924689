.verification-code-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .image{
        width: 64px;
        height: 64px;
    }
    .title{
        color: var(--neutralGrey85);
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .sub-title{
        color: var(--neutralGrey75);
        &.bottom{
            margin-bottom: 24px;
        }
    }
    .text{
        margin-top: 16px;
        color: var(--neutralGrey75);
    }
    .blue-text{
        color: var(--primaryBrandBlue);
        cursor: pointer;
    }
    .resend-timer-box{
        display: flex;
        align-items: center;
        gap: 16px;
        background-color: var(--softGreen);
        border: 1px solid var(--borderGreen);
        padding: 16px 24px;
        margin-top: 16px;
        width: 100%;
        border-radius: 8px;
        &.mobile{
            align-items: flex-start;
        }
        .message-stack{
            display: flex;
            flex-direction: column;
            gap: 4px;
            .message{
                color: var(--neutralGrey85);
            }
        }
    }
    .fail-message-box{
        display: flex;
        margin-top: 16px;
        .fail-text{
            color: var(--darkRed);
            text-align: center;
        }
    }
    .button-wrap{
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 32px;
        margin-bottom: 32px;
        justify-content: center;
        &.mobile{
            flex-direction: column;
            width: 100%;
            gap: 16px;
        }
        .Button{
            &.mobile{
                width: 100%;
            }
        }
    }
    .back-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}