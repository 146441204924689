.max-address-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 994;
    background-color: rgba(0,0,0,0.4);
    .modal-card-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--white);
        border-radius: 8px;
        max-width: 528px;
        max-height: 344px;
        padding: 48px;
        &.mobile{
            margin-left: 16px;
            margin-right: 16px;
            padding: 32px;
        }
        .modal-image{
            height: 64px;
            width: 64px;
        }
        .modal-title{
            color: var(--neutralGrey85);
            margin-top: 16px;
            margin-bottom: 8px;
        }
        .modal-text{
            color: var(--neutralGrey75);
            margin-bottom: 32px;
            text-align: center;
        }
        .Button{
            width: 216px;
            &.mobile{
                width: 100%;
            }
        }
    }
}