.change-password-wrap{
    display: flex;
    flex-direction: column;
    background-color: var(--neutralBackgroundGrey);
    margin-bottom: 64px;
    .change-header{
        display: flex;
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        .text-wrap{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1120px;
            .header-image{
                cursor: pointer;
            }
            .header-text{
                color: var(--white);
                padding-left: 8px;
                cursor: pointer;
            }
        }
    }
    .password-mobile-header{
        display: flex;
        align-items: center;
        padding: 16px;
        .blue-text{
            color: var(--primaryBrandBlue);
            padding-left: 16px;
            cursor: pointer;
        }
        .header-image{
            cursor: pointer;
        }
    }
    .password-card-wrapper{
        display: flex;
        justify-content: center;
        padding-left: 48px;
        padding-right: 56px;
        width: 100%;
        margin-top: 40px;
        &.mobile{
            margin-top: 0px;
            padding-left: 16px;
            padding-right: 16px;
        }
        .data-wrap{
            display: flex;
            flex-direction: column;
            padding: 32px;
            border-radius: 8px;
            background-color: var(--white);
            width: 100%;
            max-width: 1120px;
            &.mobile{
                padding: 24px 16px;
            }
            .header-text{
                color: var(--black);
            }
            .sub-header-text{
                color: var(--neutralGrey75);
                margin-top: 8px;
            }
            .error-wrapper{
                display: flex;
                flex-direction: row;
                align-self: flex-start;
                gap: 16px;
                border: 1px solid var(--errorBorder);
                background-color: var(--errorBackground);
                padding: 24px; 
                border-radius: 8px;
                margin-bottom: 32px;
                width: 100%;
                margin-top: -8px;
                &.Mobile{
                    padding: 16px;
                    gap: 8px;
                }
                .image{
                    height: 32px;
                    width: 32px;
                    &.Mobile{
                        height: 24px;
                        width: 24px;
                    }
                }
                .message-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .header-text{
                        color: var(--darkRed);
                    }
                    .sub-header-text{
                        color: var(--neutralGrey85);
                    }
                }
            }        
            .input-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                max-width: 390px;
                .label-wrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .label-text{
                    color: var(--neutralGrey85);
                }
                .right-side{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .blue-text{
                        color: var(--primaryBrandBlue);
                        padding-right: 8px;
                    }
                }
                .input{
                    padding: 8px 16px;
                    border: 1px solid var(--neutralGrey25);
                    border-radius: 4px;
                    &.error{
                        border: 1px solid var(--darkRed);
                    }
                }
                .error-text{
                    color: var(--darkRed);
                }
                .security-box-wrap{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    .box{
                        flex: 1;
                        height: 4px;
                        border-radius: 100px;
                        background-color: var(--neutralGrey15);
                        &.green{
                            background-color: var(--successGreen);
                        }
                    }
                }
            }
            .strength-check{
                display: flex;
                justify-content: flex-end;
                &.red{
                    color: var(--darkRed);
                }
                &.yellow{
                    color: orange;
                }
                &.green{
                    color: var(--successGreen);
                }
            }
            .security-check-stack{
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 32px;
                margin-bottom: 24px;
                .security-row{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .security-image{
                        &.Mobile{
                            height: 16px;
                            width: 16px;
                        }
                    }
                    .passed-font{
                        color: var(--neutralGrey85);
                    }
                    .failed-font{
                        color: var(--darkRed);
                    }
                }
            }
            .bottom-border{
                margin-top: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
            }
            .button-wrap{
                display: flex;
                align-items: center;
                // padding-bottom: 24px;
                gap: 16px;
                justify-content: flex-end;
            }
            .save-mobile{
                margin-top: 24px;
                margin-bottom: 8px;
            }
        }
    }
    input[type=password]::-ms-reveal,
    input[type=password]::-ms-clear
    {
        display: none;
    }
}