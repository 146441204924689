.mobile-verification-letter{
    display: flex;
    flex-direction: column;
    .loading-box{
        background-color: var(--grey5);
        border-radius: 8px;
        height: 300px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
    }
    .card-wrapper{
        background-color: var(--white);
        border-radius: 8px;
        padding: 24px 16px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
    }
    .header-wrap{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 16px;
        margin-bottom: 36px;
        border-bottom: 1px solid var(--grey5);
        .header-text{
            color: var(--neutralGrey85);
        }
        .sub-header-text{
            color: var(--neutralGrey75);
        }
    }
    .letter-text{
        display: flex;
        flex-wrap: wrap;
        color: var(--primaryBrandBlue);
        text-decoration: underline;
        cursor: pointer;
    }
}