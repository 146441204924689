.show-value-wrap{
    .display-account-value{
        color: var(--white);
    }
    .eye-icon{
        position: relative;
        top: 2px;
        margin-left: 8px;
        cursor: pointer;
        &.closed{
            height: 18px;
            width: 18px;
            top: 4px;
        }
    }
}
