.mobile-transaction-filter-wrap{
    position: fixed;
    left: 0;
    right: 0;
    bottom: calc(0px + var(--mobileSideBarHeight) - 1px);
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 992;
    .right-arrow{
        cursor: pointer;
    }
    .bottom-border{
        border-bottom: 1px solid var(--grey2);
    }
    .header-row{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 14px;
        padding-left: 14px;
        padding-right: 14px;
        .option-text{
            cursor: pointer;
            color: var(--primaryBrandBlue);
        }
        .header-text{
            color: var(--headerBlack);
        }
    }
    .row-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 14px;
        padding-right: 14px;
        .left-side{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            .selection-text{
                color: var(--neutralGrey55);
            }
            .filter-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: var(--grey5);
                padding: 2px 12px;
                border-radius: 67px;
                gap: 10px;
                .selected-text{
                    color: var(--black);
                }
                .cancel-filter{
                    cursor: pointer;
                }
            }
        }
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 18px;
        .apply-button{
            width: 343px;
            height: 32px;
            background-color: var(--primaryBrandBlue);
            color: var(--white);
            border: 1px solid var(--primaryBrandBlue);
            border-radius: 27px;
            cursor: pointer;
        }
    }
}