.mobile-manage-accounts-wrap{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .header-wrap{
        display: flex;
        align-items: center;
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        .image{
            cursor: pointer;
        }
        .blue-text{
            color: var(--primaryBrandBlue);
            padding-left: 8px;
            cursor: pointer;
        }
    }
    .card-wrapper{
        display: flex;
        flex-direction: column;
        padding: 24px 16px;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        .header{
            color: var(--neutralGrey85);
            margin-bottom: 32px;
            &.border{
                padding-bottom: 24px;
                margin-bottom: 16px;
                border-bottom: 1px solid var(--grey5);
            }
        }
        .input-label-wrap{
            display: flex;
            flex-direction: column;
            gap: 4px;
            .input-box{
                border: 1px solid var(--neutralGrey25);
                padding: 8px 16px;
                border-radius: 4px;
            }
        }
        .label-value-wrap{
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            margin-bottom: 16px;
            &.no-border{
                border: none;
            }
            &.row{
                flex-direction: row;
                justify-content: space-between;
            }
            .row-stack{
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            .label{
                color: var(--neutralGrey85);
            }
            .value{
                color: var(--neutralGrey75);
            }
            .edit-pencil{
                cursor: pointer;
            }
            // .pill-wrap{
            //     color: var(--primaryBrandBlue);
            //     padding-top: 4px;
            //     padding-bottom: 4px;
            //     padding-left: 12px;
            //     padding-right: 12px;
            //     background-color: var(--fleetBlue);
            //     border-radius: 20px;
            //     width: 100%;
            //     max-width: 64px;
            //     &.verify{
            //         width: 100%;
            //         max-width: 96px;
            //         color: var(--white);
            //         background-color: var(--primaryBrandBlue);
            //         padding: 6px 16px;
            //         cursor: pointer;
            //     }
            // }
        }
        .remove-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            .image{
                cursor: pointer;
            }
            .red-text{
                color: var(--darkRed);
                cursor: pointer;
            }
        }
        .Button{
            &.mobile{
                margin-top: 16px;
            }
        }
    }
    .loading-box{
        margin-left: 16px;
        margin-right: 16px;
        background-color: var(--grey5);
        height: 300px;
        margin-top: 8px;
        margin-bottom: 64px;
        border-radius: 8px;
    }
    .info-card-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 8px;
        padding: 16px 16px 24px;
        .message-wrapper{
            display: flex;
            flex-direction: column;
            padding: 24px 16px;
            align-items: center;
            .message-image{
                height: 64px;
                width: 64px;
            }
            .message-title{
                margin-top: 16px;
                margin-bottom: 8px;
                color: var(--neutralGrey85);
            }
            .message-text{
                color: var(--neutralGrey75);
            }
        }
        .bottom-border{
            margin-top: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
        }
        .data-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--grey5);
            cursor: pointer;
            &.gap{
                padding-bottom: 16px;
                margin-bottom: 16px;
            }
            .left-side{
                display: flex;
                flex-direction: column;
                gap: 2px;
                .nickname-text{
                    color: var(--neutralGrey85);
                }
                .value-text{
                    color: var(--neutralGrey55);
                }
                .mailing-pill{
                    padding: 4px 12px;
                    background-color: var(--grey5);
                    border-radius: 20px;
                    max-width: 67px;
                    color: var(--neutralGrey85);
                }
                .tooltip-wrap{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                .pill-wrap{
                    color: var(--primaryBrandBlue);
                    padding-top: 4px;
                    padding-bottom: 4px;
                    padding-left: 12px;
                    padding-right: 12px;
                    background-color: var(--fleetBlue);
                    border-radius: 20px;
                    width: 100%;
                    max-width: 64px;
                    &.verify{
                        width: 100%;
                        max-width: 96px;
                        color: var(--white);
                        background-color: var(--primaryBrandBlue);
                        padding: 6px 16px;
                        cursor: pointer;
                    }
                    &.processing{
                        max-width: 84px;
                        color: var(--neutralGrey75);
                        background-color: var(--neutralGrey15);
                    }
                }    
            }
            .edit-image{
                height: 20px;
                width: 20px;
                cursor: pointer;
            }
        }
        .add-value-wrap{
            display: flex;
            align-items: center;
            .add-image{
                cursor: pointer;
            }
            .link-text{
                color: var(--primaryBrandBlue);
                padding-left: 8px;
                cursor: pointer;
            }

        }
    }
    .mobile-edit-header-wrap{
        display: flex;
        align-items: center;
        padding: 16px;
        .edit-image{
            cursor: pointer;
        }
        .blue-text{
            padding-left: 8px;
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        .button-wrap{
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 16px;
            .Button{
                width: 100%;
            }
        }
    }
}