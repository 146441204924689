.tax-withholdings-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    .tax-page-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 48px;
        padding-right: 56px;
        .w9-loading-card-wrap{
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            margin-bottom: 64px;
            width: 100%;
            max-width: 1120px;
            gap: 16px;
            .w9-loading-card{
                border-radius: 8px;
                background-color: var(--grey5);
                &.top{
                    height: 200px;
                }
                &.bottom{
                    height: 100px;
                }
            }
        }
        .top-card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 24px;
            padding-bottom: 24px;
            width: 100%;
            max-width: 1120px;
            .sign-in-header{
                color: var(--neutralGrey85);
            }
            .bottom-border{
                margin-top: 32px;
                margin-bottom: 32px;
                border: 1px solid var(--grey5);
            }
            .data-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
                .left-side{
                    display: flex;
                    gap: 8px;
                    margin-right: 54px;
                    position: relative;
                    .sign-in-text{
                        color: var(--neutralGrey85);
                    }
                    .tooltip-message-wrap{
                        position: absolute;
                        left: 296px;
                    }
                }
            }
            .api-error-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                .header-text{
                    color: var(--neutralGrey85);
                    margin-top: 16px;
                    margin-bottom: 8px;
                }
                .sub-text{
                    color: var(--neutralGrey75);
                }
            }
        }
        .bottom-card{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 64px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 24px;
            padding-bottom: 24px;
            width: 100%;
            max-width: 1120px;
            cursor: pointer;
            .left-side{
                display: flex;
                flex-direction: row;
                .text-wrap{
                    displaY: flex;
                    flex-direction: column;
                    margin-left: 24px;
                    gap: 8px;
                    .header-text{
                        color: var(--black);
                    }
                    .info-text{
                        color: var(--neutralGrey75);
                    }
                }
            }
            .right-arrow{
                height: 22px;
                width: 20px;
                cursor: pointer;
            }
        }
    }
}