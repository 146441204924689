:root { 
  --primaryDarkBlue: #0e4594;
  --secondaryDarkBlue: #0e4594;
  --secondaryDarkestBlue: #00244e;
  --primaryBrandBlue: #0a5ec0;
  --primaryBrandBlueHover: #0955AD;
  --secondayLightBlue: #cee6ff;
  --fleetBlue: #ecf2f9;
  --bluePill: #f0f6fc;
  --primaryGrey75: #424242;
  --backgroundGrey: #c9c9c9;
  --neutralBackgroundGrey: #f5f6fa;
  --neutralGrey15: #d8d8d8;
  --neutralGrey25: #bebebe;
  --neutralGrey55: #767676;
  --neutralGrey75: #424242;
  --neutralGrey85: #262626;
  --cardBorderGrey: #e4e5e9;
  --darkGrey: #c9c9c9;
  --grey1: #f5f5f5;
  --grey2: #e8e8e8;
  --grey3: #9f9f9f;
  --grey4: #d8d8d8;
  --grey5: #e8eaf0;
  --grey6: #f7f7f7;
  --white: #ffffff;
  --backgroundOrange: #ffeed9;
  --warmOrange: #c05621;
  --burntOrange: #c71d18;
  --warningOrange: #b24e18;
  --lightGreen: #09bb9f;
  --fadedGreen: #dbfbda;
  --green: #007a4b;
  --offBlack: #00244e;
  --black: #000000;
  --fleetBlack: #262626;
  --headerBlack: #231f20;
  --alertSalmon: #f1e1e4;
  --secondaryRed: #c71d18;
  --paginationSelectedBoxOffWhite: #ecf2f9;
  --headerHeight: 68px;
  --mobileNavHeight: 64px;
  --mobileHeaderHeight: 44px;
  --letterSpacing: -0.107143px;
  --mobileSideBarHeight: 64px;
  --footerHeight: 67px;
  --successGreen: #008638;
  --darkRed: #C60F13;
  --fadedGreen: #E6F2ED;
  --softRed: #FFE2DD;
  --durButtonHover: 75ms;
  --lightestBlue: #E5F1FF;
  --borderBlue: #BED4ED;
  --errorBorder: #F0CACB;
  --errorBackground: #FFF4F4;
  --softGreen: #F1F8F5;
  --borderGreen: #B9DBC7;
  --lightWarningYellow: #fff5ea;
  --lightWarningYellowBorder: #e7d4b6;
  --fdicBlue: #084891;
  --fdicHeight: 32px;
  --mobileFDICHeight: 48px;
}

.h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: var(--letterSpacing);
}

.h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: var(--letterSpacing);
}

.h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.semibold-large{
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.boldSmall {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}
.bold-x-large {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}
.boldLarge {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: var(--letterSpacing);
}

.boldRegular {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.semibold-Alert {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: var(--letterSpacing);
}

.semiboldRegular {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.semiboldLarge {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.semiboldCustom {
  font-weight: 600;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: var(--letterSpacing);
}

.semiboldxLarge {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.boldButtonLarge {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.x-large {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.small {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.smallCustom {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: var(--letterSpacing);
}

.x-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: var(--letterSpacing);
}

.x-x-small{
  font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: var(--letterSpacing);
}

.mobileX-Small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.mobileSmall {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.mobileRegular {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileLarge {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.mobileh1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: var(--letterSpacing);
}

.mobileh2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: var(--letterSpacing);
}

.mobileh3{
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: var(--letterSpacing);
}

.mobileh4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobile-h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: var(--letterSpacing);
}

.mobile-h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: var(--letterSpacing);
}

.mobileBoldRegular {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileBoldSmall {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileboldextraSmall {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileSemiboldSmall {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileSemiboldError {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: var(--letterSpacing);
}

.mobileSemibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobile-x-small-semibold{
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: var(--letterSpacing);
}

.mobileSmallSemibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;  
  letter-spacing: var(--letterSpacing);
}

.mobileSemiboldRegular {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileBoldLarge {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: var(--letterSpacing);
}

.mobileParagraphRegular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);
}

.mobileButtonBold {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: var(--letterSpacing);
}

.hybridSmall {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: var(--letterSpacing);  
}

.smallBoldButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: var(--letterSpacing);  
}

::placeholder {
  color: #BEBEBE;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased !important;

    -moz-osx-font-smoothing: grayscale !important;

    text-rendering: optimizeLegibility !important;

    font-smooth: always;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

questionCircle.flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flex > * {
  margin-bottom: 10px;
  margin-right: 10px;
}

.top-container > * {
  margin-bottom: 5px;
  color: rgb(0, 78, 129);
}

.error-message {
  color: #c60f13;
  font-weight: 600;
}

body,
#root,
.App {
  min-height: 100%;
  height: 100%;
  background-color: #fafafa;
}

html {
  min-height: 100%;
  height: 100%;
}


/*=============================== 
GLOBAL EDITS (START) - SAM HUBBELL  
===============================*/

h1, h2, h3, h4, h5, p {
	letter-spacing: -0.11px !important;
}

h1 {
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 32px;
}

h2 {
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 32px;
}

h3 {
	font-weight: 700;
	font-size: 18px;
	line-height: 32px;
	margin-top: 16px;
	margin-bottom: 24px;
}

h4 {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 16px;
}

p {
  font-size: 16px;
  line-height: 24px;
  color: var(--primaryGrey75);
  font-family: 'Open Sans';
}

a {
  font-size: inherit;
  color: var(--primaryBrandBlue);

  &:hover {
    color: var(--primaryBrandBlueHover);
  }
}

/* === Progress Indicator === */

.progress-indicator {
  display: block;
  width: 100%;
  height: 6px;
  background-color: #CEE6FF;

  .progress-bar {
    display: block;
    //width: 33.333%;
    min-height: 100%;
    background-color: var(--primaryBrandBlue);
    border-radius: 0 3px 3px 0;
  }
}

/* === Title lock up variations === */

.title-lockup {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;

  .pre-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;

    .content {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.11px !important;
      color: #767676;
    }

    .step-count {
      font-size: 14px;
      line-height: 1;
      color: #767676;
      letter-spacing: -0.11px !important;
    }
  }
  
  h1 {
    margin-bottom: 8px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 32px;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  &.with-buttons {
    flex-flow: row nowrap;
    align-items: flex-start;

    .title {
  
      h1 {
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  
    .btn-group {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }


  }
}

/* === Bottom control bar === */

.control-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E8EAF0 !important;
  margin-top: 0;
  padding: 24px 0 8px;
  background-color: #fff;
  gap: 16px;

  button {
    margin: 0 !important;
  }

  .btn-group {
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 16px;
    
    button {
      flex: 1;
      white-space: nowrap;
    }
  }

  .btn-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
}

.grey-line {
    display: block;
    width: 100%;
    margin-bottom: 32px;
    border: none !important;
    border-bottom: 1px solid #E8EAF0 !important;
}


/* === Global buttons === */

.Button {
	display: inline-flex;
	padding: 12px 32px;
	border-radius: 30px;
	border-width: 1px;
	border-style: solid;
	background-color: transparent;
	font-size: 18px;
	line-height: 1;
	font-weight: 700;
	justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all var(--durButtonHover) ease-in-out;

	&.btn-primary {
		background-color: var(--primaryBrandBlue);
		border-color: var(--primaryBrandBlue);
		color: #fff;

    &:hover {
      background-color: var(--primaryBrandBlueHover);
    }

    &.inactive {
      background-color: var(--lightGrey);
      border-color: var(--lightGrey);
      pointer-events: none;
    }
	}
	
	&.btn-secondary {
		border-color: var(--grey5);
		color: var(--primaryBrandBlue);

    &:hover {
      border-color: var(--lightGrey);
		  color: var(--primaryBrandBlueHover);
    }
	}

  &.btn-openaccount {
    display: flex;
    width: 100%;
    padding: 24px;
    border-radius: 8px;
		border-color: rgba(#0A5EC0, .5);
    border-style: dashed;
		color: var(--primaryBrandBlue);

    &:hover {
      border-color: rgba(#0A5EC0, .75);
		  color: var(--primaryBrandBlueHover);
      background-color: rgba(#ffffff, .3);
    }
  }

}

/* === Text Button Colors */
.text {
  color: var(--black);
  &.blue{
    color: var(--primaryBrandBlue);
  }
  &.white{
    color: var(--white);
  }
  &.grey{
    color: var(--neutralGrey15);
  }
}


/* === Global cards === */

.cards-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 16px;
  &.mobile{
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
  }
  .card {
    // flex: 1;
    width: calc(50% - 8px);
    &.mobile{
      width: 100%;
    }
    .icon {

    }

    .text {

    }
  }
}

.card-loading-state{
  background-color: var(--grey5);
  max-width: 352px;
  height: 506px;
  width: 100%;
  @media screen and (max-width: 768px){
    max-width: 100%;
  }
}

.card-error-state{
  display: flex;
  flex-direction: column;
  height: 506px;
  .title-text{
      margin-top: 24px;
      margin-left: 32px;
  }
  .image-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 101px;
      .error-image{
          width: 72px;
          height: 72px;
          margin-bottom: 24px;
      }
      .sub-title-text{
          margin-bottom: 14px;
      }
      .text{
          margin-left: 20px;
          margin-right: 20px;
          text-align: center;
      }
  }
}

.transaction-loading{
  height: 200px;
  background-color: var(--grey5);
  border-radius: 8px;
  &.mobile{
    margin-bottom: 16px;
  }
}


/* === Global forms === */




/*

.card {
  border-radius: 3px;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: white;
  padding: 20px 20px;
  border: var(--borderLine);
}

*/

/*=============================== 
GLOBAL EDITS (END) - SAM HUBBELL  
===============================*/



/* STANDARD MEDIA QUERY SIZES  */
/* // Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {} */

* {
  letter-spacing: var(--XSLetterSpacing);
  -webkit-font-smoothing: antialiased;
}

:root {
  --darkBlue: #00456a;
  --blue: #076191;
  --lightBlue: #5394be;
  --hoverBlue: #daebf5;
  --yellow: #f1c400;
  --orange: #ff9f2b;
  --darkOrange: #c05621;
  --red: #c60f13;
  --green: #008638;
  --fontColor: #424242;
  --black: #262626;
  --nearBlack: #333333;
  --grey: #767676;
  --lightGrey: #d8d8d8;
  --lighterGrey: #f1f1f1;
  --nearWhite: #f5f5f5;
  --white: #ffffff;
  --borderLine: 1px solid #bebebe;
  --svgBlue: invert(23%) sepia(42%) saturate(3454%) hue-rotate(180deg)
    brightness(97%) contrast(94%);
  --svgDarkBlue: invert(16%) sepia(53%) saturate(3563%) hue-rotate(183deg)
    brightness(90%) contrast(101%);
  --svgLightBlue: invert(56%) sepia(18%) saturate(1153%) hue-rotate(160deg)
    brightness(93%) contrast(87%);
  --svgHoverBlue: invert(96%) sepia(2%) saturate(2292%) hue-rotate(173deg)
    brightness(92%) contrast(109%);
  --svgYellow: invert(67%) sepia(83%) saturate(648%) hue-rotate(4deg)
    brightness(100%) contrast(101%);
  --svgOrange: invert(77%) sepia(17%) saturate(4629%) hue-rotate(336deg)
    brightness(100%) contrast(102%);
  --svgDarkOrange: invert(41%) sepia(36%) saturate(2723%) hue-rotate(354deg)
    brightness(81%) contrast(81%);
  --svgRed: invert(16%) sepia(73%) saturate(4395%) hue-rotate(349deg)
    brightness(85%) contrast(105%);
  --svgGreen: invert(31%) sepia(85%) saturate(1430%) hue-rotate(123deg)
    brightness(87%) contrast(101%);
  --svgFontColor: invert(24%) sepia(0%) saturate(352%) hue-rotate(134deg)
    brightness(93%) contrast(88%);
  --svgBlack: #262626;
  --svgNearBlack: invert(11%) sepia(1%) saturate(0%) hue-rotate(272deg)
    brightness(96%) contrast(88%);
  --svgGrey: invert(48%) sepia(0%) saturate(1218%) hue-rotate(231deg)
    brightness(97%) contrast(98%);
  --svgLightGrey: invert(98%) sepia(1%) saturate(7483%) hue-rotate(190deg)
    brightness(122%) contrast(69%);
  --svgLighterGrey: invert(100%) sepia(0%) saturate(1106%) hue-rotate(225deg)
    brightness(121%) contrast(89%);
  --svgNearWhite: invert(97%) sepia(4%) saturate(567%) hue-rotate(226deg)
    brightness(116%) contrast(92%);
  --svgWhite: invert(100%) sepia(100%) saturate(0%) hue-rotate(214deg)
    brightness(110%) contrast(102%);
  --backgroundBlueGradient0deg: linear-gradient(
    200deg,
    rgba(9, 86, 174, 1) 50%,
    rgba(10, 94, 192, 1) 100%
  );
  --backgroundBlueGradient200deg: linear-gradient(
    200deg,
    rgba(9, 86, 174, 1) 50%,
    rgba(10, 94, 192, 1) 100%
  );
  --backgroundBlueSolid: rgb(9, 86, 174);

  --XSLetterSpacing: -0.03rem;
  --smallLetterSpacing: -0.05rem;
  --mediumLetterSpacing: -0.07rem;
  --largeLetterSpacing: -0.1rem;

  --box-shadow__1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --box-shadow__2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --box-shadow__3: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  --box-shadow__4: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow__5: 0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
}

@media only screen and (min-width: 320px) {
  html {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 768px) {
  * {
    letter-spacing: 0.03rem;
    -webkit-font-smoothing: antialiased;
  }

  html {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 14px !important;
  }
}

/* //DEFAULT COLORS */
.color__blue {
  color: var(--blue);
}

.color__lightBlue {
  color: var(--lightBlue);
}

.color__darkBlue {
  color: var(--darkBlue);
}

.color__orange {
  color: var(--orange);
}

.color__darkOrange {
  color: var(--darkOrange);
}

.color__lightOrange {
  color: var(--lightOrange);
}

.color__red {
  color: var(--red);
}

.color__darkGray {
  color: var(--darkGray);
}

/* //DEFAULT BACKGROUND COLORS */
.background-color__blue {
  background-color: var(--blue);
  color: white;
}

.background-color__lightBlue {
  background-color: var(--lightBlue);
}

.background-color__darkBlue {
  background-color: var(--darkBlue);
}

.background-color__orange {
  background-color: var(--orange);
  color: white;
}

.background-color__darkOrange {
  background-color: var(--darkOrange);
}

.background-color__lightOrange {
  background-color: var(--lightOrange);
}

.background-color__darkGrey {
  background-color: var(--darkGrey);
}

.background-color__lightGrey {
  background-color: var(--lightGray);
}

.ellipsis-overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-size__14 {
  font-size: 14px;
}

.font-size__13 {
  font-size: 13px;
}

.font-size__12 {
  font-size: 12px;
}

.font-weight__600 {
  font-weight: 600;
}

.font-weight__400 {
  font-weight: 400;
}

.margin-top__4 {
  margin-top: 4px;
}

.margin-bottom__4 {
  margin-bottom: 4px;
}

.padding__10 {
  padding: 10px;
}

.border-radius__2 {
  border-radius: 2px;
}

.box-shadow__1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box-shadow__1:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.box-shadow__2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.box-shadow__3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.box-shadow__4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-shadow__5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* // ALIGN-CONTENT */
.align-content__stretch {
  align-content: stretch;
}

.align-content__center {
  align-content: center;
}

.align-content__flex-start {
  align-content: flex-start;
}

.align-content__flex-end {
  align-content: flex-end;
}

.align-content__space-between {
  align-content: space-between;
}

.align-content__space-around {
  align-content: space-around;
}

.align-content__initial {
  align-content: initial;
}

.align-content__inherit {
  align-content: inherit;
}

/* //ALIGN-ITEMS */
.align-items__stretch {
  align-items: stretch;
}

.align-items__center {
  align-items: center;
}

.align-items__flex-start {
  align-items: flex-start;
}

.align-items__flex-end {
  align-items: flex-end;
}

.align-items__baseline {
  align-items: baseline;
}

.align-items__initial {
  align-items: initial;
}

.align-items__inherit {
  align-items: inherit;
}

/* //ALIGN-SELF */
.align-self__auto {
  align-self: auto;
}

.align-self__stretch {
  align-self: stretch;
}

.align-self__center {
  align-self: center;
}

.align-self__flex-start {
  align-self: flex-start;
}

.align-self__flex-end {
  align-self: flex-end;
}

.align-self__baseline {
  align-self: baseline;
}

.align-self__initial {
  align-self: initial;
}

.align-self__inherit {
  align-self: inherit;
}

/* //ALL */
.all_initial {
  all: initial;
}

.all_inherit {
  all: inherit;
}

.all_unset {
  all: unset;
}

/* //BACKFACE VISIBILITY */
.backface-visibility__hidden {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.backface-visibility__visible {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.backface-visibility__initial {
  -webkit-backface-visibility: initial;
  backface-visibility: initial;
}

.backface-visibility__inherit {
  -webkit-backface-visibility: inherit;
  backface-visibility: inherit;
}

/* //BOX SIZING */
.box-sizing__content-box {
  box-sizing: content-box;
}

.box-sizing__border-box {
  box-sizing: border-box;
}

.box-sizing__initial {
  box-sizing: initial;
}

.box-sizing__inherit {
  box-sizing: inherit;
}

/* //DISPLAY */
.display__inline {
  display: inline;
}

.display__block {
  display: block;
}

.display__contents {
  display: contents;
}

.display__flex {
  display: flex;
}

.display__grid {
  display: grid;
}

.display__inline-block {
  display: inline-block;
}

.display__inline-flex {
  display: inline-flex;
}

.display__inline-grid {
  display: inline-grid;
}

.display__inline-table {
  display: inline-table;
}

.display__list-item {
  display: list-item;
}

.display__run-in {
  display: run-in;
}

.display__table {
  display: table;
}

.display__table-caption {
  display: table-caption;
}

.display__table-column-group {
  display: table-column-group;
}

.display__table-row-group {
  display: table-row-group;
}

.display__table-cell {
  display: table-cell;
}

.display__table-column {
  display: table-column;
}

.display__table-footer-group {
  display: table-footer-group;
}

.display__table-row {
  display: table-row;
}

.display__none {
  display: none;
}

.display__initial {
  display: initial;
}

.display__inherit {
  display: inherit;
}

/* //FLEX */
.flex__auto {
  flex: auto;
}

.flex__initial {
  flex: initial;
}

.flex__inherit {
  flex: inherit;
}

.flex__0-0 {
  flex: 0 0;
}

.flex__0-1 {
  flex: 0 1;
}

.flex__1-1 {
  flex: 1 1;
}

.flex__1-0 {
  flex: 1 0;
}

/* //FLEX DIRECTION */
.flex-direction__row {
  flex-direction: row;
}

.flex-direction__row-reverse {
  flex-direction: row-reverse;
}

.flex-direction__column {
  flex-direction: column;
}

.flex-direction__column-reverse {
  flex-direction: column-reverse;
}

.flex-direction__initial {
  flex-direction: initial;
}

.flex-direction__inherit {
  flex-direction: inherit;
}

.flex-wrap__nowrap {
  flex-wrap: nowrap;
}

.flex-wrap__wrap {
  flex-wrap: wrap;
}

.flex-wrap__wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-wrap__initial {
  flex-wrap: initial;
}

.flex-wrap__inherit {
  flex-wrap: inherit;
}

.justify-content__flex-start {
  justify-content: flex-start;
}

.justify-content__flex-end {
  justify-content: flex-end;
}

.justify-content__center {
  justify-content: center;
}

.justify-content__space-between {
  justify-content: space-between;
}

.justify-content__space-around {
  justify-content: space-around;
}

.justify-content__space-evenly {
  justify-content: space-evenly;
}

.justify-content__initial {
  justify-content: initial;
}

.justify-content__inherit {
  justify-content: inherit;
}

.object-fit__fill {
  object-fit: fill;
}

.object-fit__contain {
  object-fit: contain;
}

.object-fit__cover {
  object-fit: cover;
}

.object-fit__none {
  object-fit: none;
}

.object-fit__scale-down {
  object-fit: scale-down;
}

.object-fit__initial {
  object-fit: initial;
}

.object-fit__inherit {
  object-fit: inherit;
}

.overflow__scroll {
  overflow: scroll;
}

.overflow__visible {
  overflow: visible;
}

.overflow__hidden {
  overflow: hidden;
}

.overflow__auto {
  overflow: auto;
}

.overflow__initial {
  overflow: initial;
}

.overflow__inherit {
  overflow: inherit;
}

.overflow-x__scroll {
  overflow-x: scroll;
}

.overflow-x__visible {
  overflow-x: visible;
}

.overflow-x__hidden {
  overflow-x: hidden;
}

.overflow-x__auto {
  overflow-x: auto;
}

.overflow-x__initial {
  overflow-x: initial;
}

.overflow-x__inherit {
  overflow-x: inherit;
}

.overflow-y__scroll {
  overflow-y: scroll;
}

.overflow-y__visible {
  overflow-y: visible;
}

.overflow-y__hidden {
  overflow-y: hidden;
}

.overflow-y__auto {
  overflow-y: auto;
}

.overflow-y__initial {
  overflow-y: initial;
}

.overflow-y__inherit {
  overflow-y: inherit;
}

.position__static {
  position: static;
}

.position__absolute {
  position: absolute;
}

.position__fixed {
  position: fixed;
}

.position__relative {
  position: relative;
}

.position__sticky {
  position: sticky;
}

.position__initial {
  position: initial;
}

.position__inherit {
  position: inherit;
}

.resize__none {
  resize: none;
}

.resize__both {
  resize: both;
}

.resize__horizontal {
  resize: horizontal;
}

.resize__vertical {
  resize: vertical;
}

.resize__initial {
  resize: initial;
}

.resize__inherit {
  resize: inherit;
}

.scroll-behavior__auto {
  scroll-behavior: auto;
}

.scroll-behavior__smooth {
  scroll-behavior: smooth;
}

.scroll-behavior__initial {
  scroll-behavior: initial;
}

.scroll-behavior__inherit {
  scroll-behavior: inherit;
}

.text-align__left {
  text-align: left;
}

.text-align__right {
  text-align: right;
}

.text-align__center {
  text-align: center;
}

.text-align__justify {
  text-align: justify;
}

.text-align__initial {
  text-align: initial;
}

.text-align__inherit {
  text-align: inherit;
}

.text-align-last__left {
  text-align: justify;
  text-align-last: left;
}

.text-align-last__auto {
  text-align: justify;
  text-align-last: auto;
}

.text-align-last__right {
  text-align: justify;
  text-align-last: right;
}

.text-align-last__center {
  text-align: justify;
  text-align-last: center;
}

.text-align-last__justify {
  text-align: justify;
  text-align-last: justify;
}

.text-align-last__start {
  text-align: justify;
  text-align-last: start;
}

.text-align-last__end {
  text-align: justify;
  text-align-last: end;
}

.text-align-last__initial {
  text-align: justify;
  text-align-last: initial;
}

.text-align-last__inherit {
  text-align: justify;
  text-align-last: inherit;
}

.text-overflow__clip {
  text-overflow: clip;
}

.text-overflow__ellipsis {
  text-overflow: ellipsis;
}

.text-overflow__initial {
  text-overflow: initial;
}

.text-overflow__inherit {
  text-overflow: inherit;
}

.text-transform__none {
  text-transform: none;
}

.text-transform__capitalize {
  text-transform: capitalize;
}

.text-transform__uppercase {
  text-transform: uppercase;
}

.text-transform__lowercase {
  text-transform: lowercase;
}

.text-transform__initial {
  text-transform: initial;
}

.text-transform__inherit {
  text-transform: inherit;
}

.user-select__none {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.user-select__auto {
  -webkit-user-select: auto;
  /* Safari */
  -ms-user-select: auto;
  /* IE 10 and IE 11 */
  user-select: auto;
  /* Standard syntax */
}

.user-select__text {
  -webkit-user-select: text;
  /* Safari */
  -ms-user-select: text;
  /* IE 10 and IE 11 */
  user-select: text;
  /* Standard syntax */
}

.user-select__all {
  -webkit-user-select: all;
  /* Safari */
  -ms-user-select: all;
  /* IE 10 and IE 11 */
  user-select: all;
  /* Standard syntax */
}

.vertical-align__baseline {
  vertical-align: baseline;
}

.vertical-align__sub {
  vertical-align: sub;
}

.vertical-align__super {
  vertical-align: super;
}

.vertical-align__top {
  vertical-align: top;
}

.vertical-align__text-top {
  vertical-align: text-top;
}

.vertical-align__middle {
  vertical-align: middle;
}

.vertical-align__bottom {
  vertical-align: bottom;
}

.vertical-align__text-bottom {
  vertical-align: text-bottom;
}

.vertical-align__initial {
  vertical-align: initial;
}

.vertical-align__inherit {
  vertical-align: inherit;
}

.visibility__visible {
  visibility: visible;
}

.visibility__hidden {
  visibility: hidden;
}

.visibility__collapse {
  visibility: collapse;
}

.visibility__initial {
  visibility: initial;
}

.visibility__inherit {
  visibility: inherit;
}

.white-space__normal {
  white-space: normal;
}

.white-space__nowrap {
  white-space: nowrap;
}

.white-space__pre {
  white-space: pre;
}

.white-space__pre-line {
  white-space: pre-line;
}

.white-space__initial {
  white-space: initial;
}

.white-space__pre-wrap {
  white-space: pre-wrap;
}

.white-space__inherit {
  white-space: inherit;
}

.word-break__normal {
  word-break: normal;
}

.word-break__break-all {
  word-break: break-all;
}

.word-break__keep-all {
  word-break: keep-all;
}

.word-break__break-word {
  word-break: break-word;
}

.word-break__initial {
  word-break: initial;
}

.word-break__inherit {
  word-break: inherit;
}

.word-wrap__normal {
  word-wrap: normal;
}

.word-wrap__break-word {
  word-wrap: break-word;
}

.word-wrap__initial {
  word-wrap: initial;
}

.word-wrap__inherit {
  word-wrap: inherit;
}

