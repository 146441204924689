.mobile-document-account-statements{
    display: flex;
    flex-direction: column;
    .mobile-account-statements-list{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 64px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        .statements-wrap{
            margin-top: 40px;
            padding-left: 16px;
            padding-right: 16px;
            .account-row{
                display: flex;
                align-items: center;
                border-bottom: 1px solid var(--grey5);
                padding-top: 16px;
                padding-bottom: 16px;
                &:last-child{
                    border: none;
                }
                .account-statement-text{
                    padding-left: 8px;
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .download-image{
                    height: 24px;
                    width: 24px;
                    margin-left: 16px;
                    cursor: pointer;
                }
            }
            .message-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                .message-icon{
                    height: 64px;
                    width: 64px;
                }
                .header-text{
                    color: var(--neutralGrey85);
                    text-align: center;
                    &.margin{
                        margin-bottom: 16px;
                    }
                }
                .sub-text{
                    color: var(--neutralGrey75);
                    margin-bottom: 16px;
                }
            }
            .loading-box{
                height: 160px;
                width: 100%;
                margin-bottom: 16px;
                background-color: var(--grey5);
                border-radius: 8px;
            }
        }
    }
}