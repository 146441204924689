.Desktop-Beneficiary-Edit-Trust{
    display: flex;
    flex-direction: column;
    .text-input-wrap, .ssn-input-wrap {
        // margin: 0 !important;
        // padding-bottom: 24px;
        
        &.quarter {
          width: 25% !important;
        }
        
        &.third {
          width: 33.333% !important;
          flex: 1;
        }
        
        &.half {
          width: 50% !important;
          flex: 1;
        }
        
        &.twothird {
          width: 66.666% !important;
        }

        &.fullwidth {
          width: 100% !important;
        }

        input {
          width: 100% !important;
        }
    }
    .Chart-Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 32px;
        margin-top: 32px;
        margin-right: 32px;
        gap: 16px;
        .Chart-Settings{
            display: flex;
            flex-direction: column;
            .Header-Text{
                padding-bottom: 8px;
                color: var(--headerBlack);
            }
            .Regular-Text{
                color: var(--neutralGrey85);
            }
        }
        .margin{
            margin-right: 16px;
        }
        .Button{
            width: 200px;
        }
        .button-wrap{
            display: inline-flex;
            flex-flow: row nowrap;
        }
    }
    .edit-trust-page-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;
        .Edit-Trust-Details{
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            margin-bottom: 64px;
            background-color: var(--white);
            border-radius: 8px;
            width: 100%;
            max-width: 1120px;
            .form-wrapper{
                display: flex;
                flex-direction: column;
                padding-bottom: 32px;
                .Trust-Info-Header{
                    margin-top: 37px;
                    margin-left: 32px;
                }
                .Additional-Info-Header{
                    margin-top: 40px;
                    margin-left: 32px;
                }
                .inputs-wrapper{
                    display: flex;
                    flex-direction: row;
                    padding-right: 32px;
                    padding-left: 32px;
                    padding-top: 32px;
                    gap: 16px;
                    >div{
                        // flex: 1;
                        // &:last-child{
                        //     flex: 1;
                        // }
                        input{
                            width: 100%;
                        }
                    }
                }
            }
        }    
    }
}
