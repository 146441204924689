.desktop-document-accounts-wrapper{
    display: flex;
    flex-direction: column;
    .statements-card-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 8px;   
        .statement-row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 23px;
            padding-bottom: 23px;
            border-bottom: 1px solid var(--grey5);        
            &:last-child{
                border-bottom: none;
            }
            .left-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                .document-image{
                    margin-left: 22px;
                    cursor: pointer;
                }
                .row-text{
                    padding-left: 16px;
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .loading-box{
            width: 100%;
            height: 300px;
            background-color: var(--grey5);
            border-radius: 8px;
        }
        .message-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            .message-image{
                width: 64px;
                height: 64px;
            }
            .header-text{
                margin-top: 16px;
                margin-bottom: 8px;
                color: var(--neutralGrey85);
            }
            .sub-text{
                color: var(--neutralGrey75);
            }
        }
    } 
}