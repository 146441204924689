.select-input-wrap {
  &.half {
    width: 50% !important;
    flex: 1 1;
  }
  .input-box-wrapper{
    display: flex;
    align-items: center;
    border: 1px solid var(--neutralGrey25);
    border-radius: 4px;
    padding: 12px;
    margin-top: 4px;
    position: relative;
    // width: 46%;
    &.error{
      border: 2px solid var(--darkRed);
    }
    .input-box{
      width: 100%;
      padding-left: 8px;
      border: none;
      color: var(--neutralGrey75);
      &.no-padding{
        padding-left: 3px;
      }
    }
    .cancel-circle{
      cursor: pointer;
    }
  }
  .swap-text{
    margin-top: 4px;
    color: var(--primaryBrandBlue);
    cursor: pointer;
  }
  .test {
    position: absolute;
    margin-top: 68px;
    // top: 75%;
    width:100%;
    z-index:999;
    background-color: white;
    .dropdown {
      position: static !important;
      transform: none !important;
      translate: none !important;
      transition: all .2s;
      border-radius: 8px;
    }
  
    .loquate-description {
      color: var(--neutralGrey55) !important;
    }
  
    .back-container {
      border-top: 1px solid var(--grey5);
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
    .selection-row {
      display: flex;
      align-items: center;
      // padding: 16px;
      &.mobile{
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        padding: 16px;
      }
      &.back-button {
        justify-content: flex-start !important;
        img {
          transform: rotate(90deg);
        }
      }
      .option-text{
        color: var(--neutralGrey85);
        text-decoration: underline;
        padding-top: 8px;
        padding-bottom: 8px;
        &.no-decoration{
          text-decoration: none;
        }
        &.blue{
          color: var(--primaryBrandBlue);
        }
      }
      .right-side{
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .loquate-description{
        color: var(--neutralGrey55);
        flex: 1;
        &.desktop{
          text-align: right;
        }
      }
      img {
        color: var(--grey5);
      }
    }
  }

}


.address-input-wrapper {
    width:100%;

    .search-text-wrapper{
      display: flex;
      align-items: center;
      max-width: 208px;
      gap: 4px;
      cursor: pointer;
      .search-text{
        color: var(--primaryBrandBlue);
      }
    }
      .row, .row.mobile {
        display: flex;
        flex-flow: row nowrap;
        gap: 16px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .text-input-wrap, .ssn-input-wrap {
          margin: 0 !important;
          padding-bottom: 24px;
          
        }
        .quarter {
          width: 25% !important;
        }
        
        .third {
          width: 33.333% !important;
          flex: 1;
        }
        
        .half {
          width: 50% !important;
          flex: 1;
        }
        
        .twothird {
          width: 66.666% !important;
        }

        .fullwidth {
          width: 100% !important;
        }

        input {
          width: 100% !important;
        }

        .ssn-input-wrap,
        .phone-input-wrap,
        .date-input-wrap,
        .tin-input-wrap,
        .number-input-wrap,
        .select-input-wrap {
          flex: none;
          margin: 0 !important;
          padding-bottom: 16px;

          .input-label-wrap {
            //width: 100%;

            .ssn-wrap,
            .inputs-wrap {
              padding: 12px 16px;
              //width: 100%;

              input {
                padding: 0;
                text-align: center;
                //width: auto !important;
                //flex: 1;
              }
              span {
                padding: 0;
              }
            }
          }
        }
      }
}