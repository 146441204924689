.ButtonDB {
  display: inline-flex;
  padding: 12px 32px;
  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all var(--durButtonHover) ease-in-out;
  &.btn-primaryDB {
    background-color: var(--primaryBrandBlue);
    border-color: var(--primaryBrandBlue);
    color: #fff;
    &:not(:disabled):hover {
      background-color: var(--primaryBrandBlueHover);
    }

  }
  &.btn-secondaryDB {
    border-color: var(--grey5);
    color: var(--primaryBrandBlue);

    &:not(:disabled):hover {
      border-color: var(--lightGrey);
      color: var(--primaryBrandBlueHover);
    }

    &.squareDB {
      padding: 12px;
      border-radius: 4px;
    }
  }
  &.textDB {
    padding: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: none;
    color: var(--primaryBrandBlue);
  }
  &:disabled {
    background-color: var(--lightGrey);
    border-color: var(--lightGrey);
    color: var(--white);
    cursor: not-allowed;
  }
}
