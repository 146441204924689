.page-layout-card-wrapper{
    display: flex;
    margin-top: 40px;
    margin-bottom: 64px;
    padding-left: 48px;
    padding-right: 56px;
    width: 100%;
    justify-content: center;
    &.Mobile{
        margin-top: 24px;
        margin-bottom: 32px;
        margin-left: 16px;
        margin-right: 16px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .page-layout-card{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1120px;
        padding: 32px;
        background-color: var(--white);
        border-radius: 8px;
        position: relative;
        &.Mobile{
            padding-top: 24px;
            padding-bottom: 32px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}