.Dropdown-Wrapper{
    width: 255px;
    min-height: 316px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 990;
    position: absolute;
    top: calc(75px + var(--fdicHeight));
    right: 16px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
    .Option-List{
       display: flex;
       flex-direction: column;
       justify-content: center;
       flex-grow: 1;
        .Option-Row{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 20px;
            cursor: pointer;
            .Text{
                color: var(--neutralGrey85);
            }
            .Sign-Out{
                color: var(--burntOrange)
            }
        }
        .image-padding{
            padding-right: 11px;
            width: 35px;
            height: 35px;
        }
    }
}