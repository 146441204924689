.product-modal-wrap{
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 994;
    &.mobile{
        align-items: flex-end;
        background-color: transparent;
    }
    .products-card-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--white);
        border-radius: 8px;
        padding: 40px;
        gap: 24px;
        overflow: auto;
        max-width: 948px;
        max-height: 736px;
        margin-left: 16px;
        margin-right: 16px;
        &::-webkit-scrollbar {
            width: 8px;
        }    
        &.mobile{
            max-height: calc(100vh - var(--mobileFDICHeight) - var(--mobileHeaderHeight));
            padding: 16px;
            width: 100%;
            margin: 0px;
            padding-bottom: 64px;
            border-radius: 0px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;    
        }
        .header-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.mobile{
                position: relative;
                justify-content: center;
                margin-left: -16px;
                margin-right: -16px;
                padding-bottom: 16px;
                border-bottom: 1px solid var(--grey5);
            }
            .header-text{
                color: var(--neutralGrey85);
            }
            .close{
                cursor: pointer;
            }
            .done-button{
                position: absolute;
                right: calc(0px + 16px);
                color: var(--primaryBrandBlue);
                cursor: pointer;
            }
        }
        .products-wrap{
            display: flex;
            justify-content: space-between;
            gap: 24px;
            align-items: flex-start;
        }
        .products-right{
            display: flex;
            flex-direction: column;
            gap: 32px;
            flex: 1;
        }
        .product-list{
            display: flex;
            flex-direction: column;
            padding: 24px;
            border: 1px solid var(--grey5);
            border-radius: 8px;
            flex: 1;
            .data-headers{
                display: flex;
                align-items: center;
                margin-top: 8px;
                padding: 16px;
                border-bottom: 1px solid var(--grey5);
                .data-header-text{
                    flex: 1;
                    color: var(--neutralGrey75);
                }
            }
            .show-products{
                display: flex;
                flex-direction: column;
                .product-row{
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    border-bottom: 1px solid var(--grey5);
                    &:last-child{
                        border: none;
                    }
                    &.savings{
                        padding: 0px;
                        border: none;
                        &.desktop{
                            padding-top: 8px;
                        }
                    }
                    .data-value{
                        flex: 1;
                        color: var(--neutralGrey75);
                    }
                }
            }
        }
        .disclaimer-text{
            color: var(--neutralGrey55);
        }
    }
}