.Button-Component {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border-radius: 26px;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  letter-spacing: auto;
  cursor: pointer;
  will-change: width;
  transition: width .3s;
}

.button-loader {
  font-size: .2rem;
  width: .2rem;
  height: .2rem;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin .9s ease infinite;
  transform: translateZ(0);
  margin-right: 1rem;
  min-width: .2rem!important
}

@keyframes mulShdSpin {
  0%,to {
      box-shadow: 0 -2.6em 0 0 transparent,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  12.5% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 transparent,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  25% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 transparent,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  37.5% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 transparent,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  50% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 transparent,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  62.5% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 transparent,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 #fff
  }

  75% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 transparent,-1.8em -1.8em 0 0 #fff
  }

  87.5% {
      box-shadow: 0 -2.6em 0 0 #fff,1.8em -1.8em 0 0 #fff,2.5em 0 0 0 #fff,1.75em 1.75em 0 0 #fff,0 2.5em 0 0 #fff,-1.8em 1.8em 0 0 #fff,-2.6em 0 0 0 #fff,-1.8em -1.8em 0 0 transparent
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg)
  }

  to {
      transform: rotate(359deg);
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg)
  }
}
