@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
.toast-message{
  display: flex;
  text-align:center;
  max-width:90vw !important;
  width:auto;
  position: fixed;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, 0%);
  bottom: 64px;
  align-self: center;
  justify-content: center;
  color: white;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  box-shadow: 0px 8px 25px rgba(0,69,186,0.1);
  border-radius: 8px;
  animation: fadeOut;
  animation-fill-mode: forwards;
  &.no-animation{
    animation: none;
  }
  &.red{
    background-color: #c60f13;
  }
  &.black{
    background-color: #262626;
  }
  button{
    display: flex;
    background-color: inherit;
    cursor: pointer;
    color: white;
    border: none;
    padding: 0;
    .close-icon{
      &:hover{
        border-color: var(#d8d8d8);
      }
    }
  }
}

@media (max-width: 768px) {
  .toast-message {
    display:flex;
    width: 90vw;
    justify-content: space-between;

  }
}