.beneficiary-review-page-wrap{
    display: flex;
    flex-direction: column;
    .card-wrap{
        display: flex;
        flex-direction: row;
        padding: 32px;
        background-color: var(--neutralBackgroundGrey);
        border-radius: 8px;
        border: 1px solid var(--neutralGrey15);
        gap: 64px;
        &.mobile{
            flex-direction: column;
            gap: 24px;
        }
        .info-header{
            margin-bottom: 16px;
        }
        .beneficiary-review-left-side{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .right-side{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .data-wrap{
            display: flex;
            flex-direction: column;
            .data-text{
                color: var(--neutralGrey85);
            }
            .data-value{
                color: var(--neutralGrey75);
            }
        }
        .bottom-border{
            border-bottom: 1px solid var(--grey5);
        }
    }

    .control-bar {
        border-top: none;
    }
}