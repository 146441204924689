.MobileLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .Children {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: var(--mobileHeaderHeight);
    min-height: calc(100vh - var(--mobileHeaderHeight) - var(--mobileSideBarHeight));
    background-color: var(--neutralBackgroundGrey);
    margin-bottom: var(--mobileNavHeight);
    margin-top: 48px;
    &.error {
      margin-bottom: 0px;
    }
  }
  .Navigation {
    position: fixed;
    z-index: 990;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: white;
    z-index: 993;
  }
}
