.otp-modal-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 996;
    &.Mobile{
        padding-left: 16px;
        padding-right: 16px;
    }
    .otp-modal-details-wrap{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-radius: 8px;
        padding: 48px;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 556px;
        height: 100%;
        max-height: 684px;
        overflow: hidden;
        &.mobile{
            padding: 32px;
            max-height: 75vh;
            overflow-y: hidden;
        }
        .cancel-image{
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }    
    }
}