.Header-Wrapper{
    width: 100%;
    min-height: 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    background-color: var(--primaryBrandBlue);
    .Left-Side{
        padding-left: 24px;
        width: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-right: 1px solid rgba(#FFF,.15);
        min-height: 64px;
        flex-grow: 1;
        .right{
            display: flex;
            flex-direction: row;
            align-items: center;
            .Text{
                color: var(--white);
                cursor: pointer;
            }
            .Help-Text{
                padding-right: 16px;
            }
            .Icon{
                cursor: pointer;
            }
            .Plus-Circle{
                padding-right: 8px;
            }
            .Question-Mark{
                margin-left: 26px;
                padding-right: 10px;
            }
        }
    }
    .Customer-Dropdown{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        padding: 0 1rem;
        .User-Name{
            color: var(--white);
            // padding-left: 8px;
        }
        .Person-Circle{
            padding-left: 16px;
        }
        .Drop-Down-Arrow{
            padding-left: 8px;
            padding-right: 18px;
        }
    }
}

.Open-Dropdown{
    animation: openMenu .3s ease-in forwards;
    z-index: 990;
}
.Close-Dropdown{
    animation: closeMenu .3s ease-out forwards;
    z-index: 990;
}

@keyframes openMenu {
    0% {
        opacity: 0.01;
    }
    100% {
        opacity: 1;
    }
}
@keyframes closeMenu{
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
