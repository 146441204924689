.setup-beneficies {
  .setup-wrapper {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    display: flex;
    justify-content: center;
  }
  .description {
    text-align: center;
  }
  .img {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .add-beneficiary {
      cursor: pointer;
      border-radius: 54px;
      background-color: var(--primaryBrandBlue);
      color: var(--white);
      align-items: center;
      border: none;
    }
  }
  .setup-page-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .desktop {
    display: flex;
    flex-direction: column;
    padding: 32px 48px;
    .setup-wrapper {
      width: 100%;
      max-width: 1120px;
      margin-top: 16px;
      padding: 45px 48px 51px 48px;
      border-radius: 8px;
      border: 1px solid var(--grey5);
    }
    .img {
      height: 200px;
    }
    .button-wrapper {
      margin-top: 48px;

      .add-beneficiary {
        padding: 12px 32px;
        max-width: 266px;
        width: 100%;
      }
    }
  }
  .title-wrap{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 32px;
    align-items: flex-start;
    width: 100%;
    .empty-text{
      color: var(--neutralGrey85);
    }
  }
  .mobile {
    margin-top: 16px;
    .setup-wrapper {
      padding: 32px 34px 34px 34px;
      margin-bottom: 5px;
      margin-left: 16px;
      margin-right: 16px;
      border-radius: 8px;
    }
    .img {
      height: 108px;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      justify-content: center;
      position: relative !important;
      padding: 0px !important;
      bottom: 0px !important;
      .add-beneficiary {
        padding: 10px 24px;
        &.mobile{
          width: 100%;
        }
      }
    }
  }
}
