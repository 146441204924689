.desktop-basic-edit-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    width: 100%;
    align-items: center;
    .basic-header-wrap{
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: var(--secondaryDarkestBlue);
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 48px;
        padding-right: 56px;
        .basic-edit-header{
            display: flex;
            align-items: center;
            background-color: var(--secondaryDarkestBlue);
            width: 100%;
            max-width: 1120px;
            .header-text{
                padding-left: 16px;
                color: var(--white);
                cursor: pointer;
            }
            .back-image{
                height: 20px;
                width: 20px;
                cursor: pointer;
            }
        }
    }
    .data-page-wrap{
        display: flex;
        padding-left: 48px;
        padding-right: 56px;
        justify-content: center;
        width: 100%;
        .data-card-wrap{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1120px;
            background-color: var(--white);
            padding: 32px;
            margin-top: 40px;
            border-radius: 8px;
            .error-text{
                color: var(--darkRed);
            }
            .title-text{
                color: var(--neutralGrey85);
                &.margin{
                    margin-bottom: 8px;
                }
            }
            .current-new-data-wrap{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                max-width: 712px;
                .current-data-stack{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }
            .bottom-border{
                margin-top: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
            }
            .label-text{
                color: var(--neutralGrey85);
                display: flex;
                flex-direction: column;
                padding-top: 4px;
                gap: 4px;
                width: 336px;
                &.error{
                    color: var(--darkRed);
                }
                .input-box{
                    padding: 8px 16px;
                    border: 1px solid var(--neutralGrey25);
                    border-radius: 4px;
                    &.error{
                        border: 1px solid var(--darkRed);
                    }
                }
            }
            .bottom-row-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left-side{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    .red-text{
                        color: var(--darkRed);
                    }
                }
            }
            .button-wrap{
                display: flex;
                align-items: center;
                gap: 16px;
                justify-content: flex-end;
            }
        }
    }
}