.grace-landing-card-wrap{
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 40px 32px;
    width: 100%;
    max-width: 1120px;
    border-radius: 8px;
    .header-text-wrap{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .header-text{
            color: var(--neutralGrey85);
        }
        .sub-header-text{
            width: 100%;
            max-width: 604px;
            color: var(--neutralGrey75);
        }
    }
    .card-details{
        display: flex;
        flex-direction: column;
        padding: 32px;
        gap: 24px;
        border: 1px solid var(--grey5);
        border-radius: 8px;
        margin-top: 40px;
        .title{
            color: var(--neutralGrey75);
        }
        .data-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .left-side{
                display: flex;
                flex-direction: column;
                gap: 2px;
                .subject{
                    color: var(--neutralGrey75);
                }
                .bottom-row{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                }
                .value{
                    color: var(--neutralGrey85);
                }
                .pill-wrap{
                    padding-left: 16px;
                    padding-right: 16px;
                    border-radius: 24px;
                    background-color: var(--bluePill);
                    .maturity-pill{
                        color: var(--primaryBrandBlue);
                    }
                }
            }
            .right-side{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                .link-text{
                    color: var(--primaryBrandBlue);
                    &.remove{
                        color: var(--darkRed);
                    }
                }
            }
        }
        .bottom-border{
            border-bottom: 1px solid var(--grey5);
        }
        .close-text{
            width: 200px;
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
        .close-wrapper{
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .close-chevron{
            cursor: pointer;
            &.close{
                transform: rotate(0deg);
                transition: .3s ease-in-out;
            }
            &.open{
                transform: rotate(180deg);
                transition: .3s ease-in-out;
            }
        }
        .phone-text{
            margin-top: -8px;
            margin-left: 34px;
            color: var(--neutralGrey75);
            .blue-text{
                color: var(--primaryBrandBlue);
                text-decoration: none;
            }
        }
    }
}
.maturity-link-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
    padding: 24px;
    margin-top: 24px;
    background-color: var(--white);
    border-radius: 8px;
    cursor: pointer;
    .left-side{
        display: flex;
        align-items: center;
        gap: 24px;
        .column-stack{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .header-text{
                color: var(--black);
            }
            .sub-header-text{
                color: var(--neutralGrey75);
            }
        }
    }
}
.grace-landing-loading-wrap{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    align-items: center;
    width: 100%;
    .loading-box{
        width: 100%;
        max-width: 1120px;
        border-radius: 8px;
        background-color: var(--grey5);
        &.top{
            height: 400px;
        }
        &.bottom{
            height: 100px;
        }
    }
}
