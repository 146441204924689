.user-contact-page-wrap{
    display: flex;
    padding-left: 48px;
    padding-right: 56px;
    justify-content: center;
    .contact-information-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1120px;
        .loading-box{
            background-color: var(--grey5);
            border-radius: 8px;
            height: 284px;
            width: 100%;
            margin-top: 32px;
            margin-bottom: 64px;
        }
        .bottom-card{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            margin-bottom: 64px;
            .email-section{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-grow: 1;
                padding-bottom: 24px;
                margin-top: 36px;
                border-bottom: 1px solid var(--cardBorderGrey);
                .left-side{
                    display: flex;
                    flex-direction: row;
                    margin-left: 36px;
                    gap: 54px;
                    .left{
                        display: flex;
                        flex-direction: column;
                        width: 202px;
                        gap: 8px;
                        .email-address{
                            color: var(--neutralGrey75);
                        }
                    }
                    .right{
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        position: relative;
                        top: 2px;
                        .email{
                            color: var(--black);
                        }
                    }
                }
                .right-side{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    margin-right: 36px;
                    position: relative;
                    top: 4px;
                    .edit-pencil{
                        width: 19px;
                        height: 19px;
                        cursor: pointer;
                    }
                    .edit-text{
                        height: 19px;
                        color: var(--primaryBrandBlue);
                        cursor: pointer;
                        position: relative;
                        bottom: 2px;
                    }
                }
            }
            .phone-section{
                margin-top: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid var(--cardBorderGrey);
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                gap: 54px;
                flex-grow: 1;
                .left-side{
                    display: flex;
                    flex-direction: row;
                    margin-left: 32px;
                    .left{
                        display: flex;
                        flex-direction: column;
                        width: 202px;
                        gap: 8px;
                        .phone-number{
                            color: var(--neutralGrey75);
                        }
                        .phone-message{
                            color: var(--neutralGrey55);
                        }   
                    }
                }
                .column{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .row-wrapper{
                        display: flex;
                        flex-direction: row;
                        flex-grow: 1;
                        justify-content: space-between;
                        margin-bottom: 32px;
                        .column-wrapper{
                            display: flex;
                            flex-direction: column;
                            .mobile-text{
                                color: var(--neutralGrey85);
                                margin-bottom: 4px;
                            }
                            .mobile-number{
                                color: var(--black);
                                margin-bottom: 8px;
                            }
                            .add-number{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 11px;
                                margin-bottom: -24px;
                                margin-top: 32px;
                                cursor: pointer;
                                .blue-plus-icon{
                                    
                                }
                                .add-text{
                                    color: var(--primaryBrandBlue);
                                }
                            }
                        }
                        .right-side{
                            margin-right: 36px;
                            position: relative;
                            top: 4px;
                            height: 20px;
                            cursor: pointer;
                            .edit-pencil{
                                padding-right: 10px;
                            }
                            .edit-text{
                                color: var(--primaryBrandBlue);
                                position: relative;
                                bottom: 2px;
                            }
                        }
                    }
                }
            }
            .address-section{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-grow: 1;
                margin-top: 24px;
                padding-bottom: 24px;
                .left-side{
                    display: flex;
                    flex-direction: column;
                    margin-left: 32px;
                    margin-right: 54px;
                    width: 202px;
                    gap: 8px;
                    .top-row{
                        display: flex;
                        flex-direction: row;
                        .addresses-text{
                            margin-right: 5px;
                            color: var(--neutralGrey75);
                        }
                        // .image-wrapper{
                        //     display: flex;
                        //     flex-direction: column;
                        //     .information-image{
                        //         width: 24px;
                        //         height: 24px;        
                        //     }
                        //     .tooltip-wrapper{
                        //         margin-left: -10px;
                        //     }  
                        // }
                    }
                    .address-info-text{
                        color: var(--neutralGrey55);
                    }
                }
                .right-side{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    margin-right: 35px;
                    .addresses{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-grow: 1;
                        .address{
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 36px;
                            .type-text{
                                margin-bottom: 4px;
                                color: var(--neutralGrey85);
                            }
                            .street-text{
                                color: var(--neutralGrey85);
                                margin-bottom: 4px;
                            }
                            .city-state-zip{
                                color: var(--neutralGrey85);
                            }
                            .pill-wrapper{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                width: 67px;
                                height: 22px;
                                background-color: var(--grey5);
                                border-radius: 20px;
                                padding: 2px 12px 4px;
                                margin-top: 6px;
                                .mailing-pill{
                                    color: var(--neutralGrey75);
                                }
                            }
                        }
                        .right{
                            display: flex;
                            flex-direction: row;
                            position: relative;
                            top: 10px;
                            .edit-pencil{
                                height: 19px;
                                width: 19px;
                                cursor: pointer;
                            }
                            .edit-text{
                                height: 20px;
                                position: relative;
                                bottom: 2px;
                                padding-left: 10px;
                                color: var(--primaryBrandBlue);
                                cursor: pointer;
                            }
                        }
                    }
                    .add-address{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 11px;
                        cursor: pointer;
                        .add-mailing-text{
                            color: var(--primaryBrandBlue);
                        }
                    }
                }
            }
        }
    }
}