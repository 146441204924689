.mobile-transaction-type-selection-filter{
    display: flex;
    flex-direction: column;
    margin-bottom: -20px;
    .row-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .selection-text{
            color: var(--neutralGrey85);
            &.selected{
                color: var(--primaryBrandBlue);
            }
        }
    }
}