.cd-details-wrapper{
    display: flex;
    flex-direction: column;
    .header-text-title{
        color: var(--neutralGrey85);
    }
    .peronal-cd-wrap{
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 24px;
        .personal-cd-header{
            color: var(--neutralGrey85);
        }
        .personal-cd{
            color: var(--neutralGrey75);
        }
    }
    .term-apy-row-wrap{
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 24px;
        .current-term-wrap{
            display: flex;
            flex-direction: column;
            gap: 2px;
            .curernt-term-header{
                color: var(--neutralGrey85);
            }
            .current-term{
                color: var(--neutralGrey75);
            }
        }
        .apy-wrap{
            display: flex;
            flex-direction: column;
            gap: 2px;
            .current-apy-header{
                color: var(--neutralGrey85);
            }
            .current-apy{
                color: var(--neutralGrey75);
            }
        }
    }
    .grace-period-link-wrap{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid var(--neutralGrey15);
        .header-wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            .header-text{
                color: var(--neutralGrey85)
            }    
        }
        .details-text{
            color: var(--neutralGrey85);
            margin-top: 24px;
        }
        .link-wrap{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 16px;
            cursor: pointer;
            &.mobile{
                justify-content: center;
                margin-top: 32px;
                margin-bottom: 16px;
            }
            .change-text{
                color: var(--primaryBrandBlue);
            }
        }
    }
}