.desktop-bump-rate-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    .account-header-wrap{
        display: flex;
        width: 100%;
        justify-content: center;
        padding-left: 48px;
        background-color: var(--secondaryDarkestBlue);
    }
    .bump-rate-page-wrap{
        display: flex;
        flex-direction: row;
        padding-left: 48px;
        padding-right: 56px;
        width: 100%;
        justify-content: center;
        .bump-rate-card-wrap{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            margin-top: 32px;
            padding: 32px;
            border-radius: 8px;
            margin-bottom: 64px;
            width: 100%;
            max-width: 1120px;
            .header-wrap{
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-bottom: 32px;
                margin-bottom: 32px;
                border-bottom: 1px solid var(--grey5);
                .header-text{
                    color: var(--black);
                }
                .sub-header-text{
                    color: var(--neutralGrey75);
                }
            }
            .info-wrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                .info-header-text{
                    color: var(--neutralGrey85);
                    margin-top: 40px;
                }
                .info-header-subtext{
                    color: var(--neutralGrey85);
                    margin-top: 8px;
                    margin-bottom: 24px;
                    max-width: 542px;
                    text-align: center;
                }
            }
            .apy-info-wrap{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                .left-side{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 16px 24px;
                    &.selected{
                        border: 1px solid var(--grey5);
                        border-radius: 8px;
                    }
                    .current-apy{
                        color: var(--neutralGrey85);
                    }
                    .current-apy-text{
                        color: var(--neutralGrey75);
                    }
                }
                .right-side{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 16px 37px;
                    &.selected{
                        border: 1px solid var(--grey5);
                        border-radius: 8px;
                    }
                    .new-apy-text{
                        color: var(--neutralGrey75);
                    }
                    .top{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 7px;
                        .apy-text{
                            color: var(--neutralGrey85);
                        }
                    }
                }
                .right-arrow{
                    position: relative;
                    right: 182px;
                    background-color: var(--white);
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
            .button-wrap{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;
                .activate-button{
                    background-color: var(--primaryBrandBlue);
                    color: var(--white);
                    border: 1px solid var(--primaryBrandBlue);
                    width: 266px;
                    height: 48px;
                    border-radius: 54px;
                    cursor: pointer;
                }
            }
        }
        .bump-page-loading-state{
            display: flex;
            background-color: var(--grey5);
            // height: 300px;
            height: 46vh;
            width: 100%;
            max-width: 1120px;
            border-radius: 8px;
            margin-top: 32px;
        }
    }
}