.desktop-statements-landing-wrap{
    display: flex;
    flex-direction: column;

    .filter-box-wrapper{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 224px;
        .text-label{
            color: var(--neutralGrey85);
        }
    }
    .card-wrapper{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin-top: 16px;
        // margin-bottom: 34px;
        border-radius: 8px;
        .row-wrapper{
            display: flex;
            flex-direction: row;
            padding: 21px;
            .document-image{
                cursor: pointer;
            }
            .statement-text{
                padding-left: 17px;
                color: var(--primaryBrandBlue);
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .border-line{
            height: 1px;
            background-color: #E8EAF0;
        }
        .pagination-wrapper{
            margin-top: 24px;
        }
        .empty-statements-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-top: 32px;
            .empty-image{
                margin-bottom: 8px;
            }
            .text{
                color: var(--neutralGrey85);
            }
        }
    }
}