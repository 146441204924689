.post-yodlee-loading-box{
    position: absolute;
    // width: 100%;
    // max-width: 1120px;
    min-height: 392px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--grey5);
    border-radius: 8px;
    &.Mobile{
        top: -36px;
        right: -2px;
    }
}

.verified-accounts-error-screen-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .image{
        margin-top: 32px;
    }
    .header-text{
        margin-top: 24px;
        margin-bottom: 24px;
        color: var(--neutralGrey85);
        &.mobile{
            margin-bottom: 16px;
            text-align: center;
            width: 100%;
            max-width: 304px;
        }
    }
    .sub-text{
        text-align: center;
        color: var(--neutralGrey75);
        &.Mobile{
            width: 100%;
            max-width: 284px;
        }
    }
    .Button{
        margin-top: 48px;
        &.Mobile{
            width: 100%;
            max-width: 304px;
        }
    }
    .border-line{
        width: 100%;
        border-top: 1px solid var(--grey5);
        margin-top: 72px;
    }
    .static-footer{
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        padding-top: 32px;
        // border-top: 1px solid var(--grey5);
        &.Mobile{
            border: none;
        }
        .footer-text{
            color: var(--neutralGrey85);
        }
        .footer-link{
            color: var(--primaryBrandBlue);
            cursor: pointer;
        }
    }
}