.mobile-confirm-header-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    // background-color: var(--secondaryDarkestBlue);
    .left-side{
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        .back-text{
            color: var(--primaryBrandBlue);
        }
    }
    .cancel-text{
        color: var(--primaryBrandBlue);
        cursor: pointer;
    }
}
.grace-confirm-term-wrap{
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 40px 32px;
    gap: 32px;
    width: 100%;
    max-width: 1120px;
    border-radius: 8px;
    &.mobile{
        width: auto;
        padding: 24px 16px;
        margin-left: 16px;
        margin-right: 16px;
        // margin-top: 24px;
        margin-bottom: 32px;
    }
    .header-wrap{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .header-text{
            color: var(--neutralGrey55);
        }
        .sub-header-text{
            color: var(--neutralGrey85);
        }
    }
    .details-card{
        display: flex;
        flex-direction: column;
        padding: 32px;
        width: 100%;
        border-radius: 8px;
        background-color: var(--neutralBackgroundGrey);
        border: 1px solid var(--grey5);
        &.Mobile{
            padding: 16px;
        }
        .title-text{
            color: var(--neutralGrey75);
            margin-bottom: 24px;
        }
        .options-wrap{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .info-wrap{
                display: flex;
                flex-direction: column;
                gap: 4px;
                &.gap{
                    margin-top: 8px;
                }
                .option{
                    color: var(--neutralGrey75);
                }
                .value{
                    color: var(--neutralGrey85);
                }
            }
            .fund-transfer-account-wrap{
                display: flex;
                align-items: center;
                height: auto;
                gap: 16px;
                &.Mobile{
                    flex-direction: column;
                    align-items: flex-start;
                }
                .account-card{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding: 16px 12px;
                    background-color: var(--white);
                    border-radius: 8px;
                    border: 1px solid var(--grey5);
                    min-width: 290px;
                    &.Mobile{
                        width: 100%;
                    }
                    .card-title{
                        color: var(--neutralGrey85);
                    }
                    .info-card{
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .info-stack{
                            display: flex;
                            flex-direction: column;
                            .account-title{
                                color: var(--neutralGrey85);
                            }
                            .account-values{
                                text-transform: capitalize;
                                color: var(--neutralGrey55);
                            }
                        }
                    }
                }
            }
        }
    }
    .border-line{
        border-bottom: 1px solid var(--grey5);
        margin-top: 16px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left-side{
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            .back-button{
                color: var(--primaryBrandBlue);
            }
        }
        .right-side{
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    .mobile-continue-button-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 16px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: var(--white);
        box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
        border-top: 1px solid var(--grey5);
        z-index: 994;
        .Button{
            width: 100%;
        }
    }
}