.mobile-statements-landing-wrap{
    display: flex;
    flex-direction: column;
    .loading-box{
        background-color: var(--grey5);
        height: 400px;
        border-radius: 8px;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 32px;
    }  
    .encapsulation-card{
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        margin: 24px 16px;
        margin-bottom: 64px;
        border-radius: 8px;
        min-height: 352px;
        padding: 24px 16px;
        .title-wrap{
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;
            border-bottom: 1px solid var(--grey5);
            margin-bottom: 24px;
            .statements-text{
                color: var(--neutralGrey85);
            }
            .sub-text{
                color: var(--neutralGrey75);
            }
        }
        .filter-wrapper{
            display: flex;

            align-items: center;
            justify-content: space-between;
            background-color: var(--white);
            border-radius: 4px;
            padding: 10px 12px;
            border: 1px solid var(--neutralGrey25);
            width: fit-content;
            margin-bottom: 24px;
            cursor: pointer;
            .filter-text{
                color: var(--neutralGrey55);
                margin-left: 5px;
            }
        }
        .card-wrapper{
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            .row-wrapper{
                display: flex;
                flex-direction: row;
                background-color: var(--white);
                padding: 20px 16px;
                cursor: pointer;
                .statement-text{
                    color: var(--primaryBrandBlue);
                    text-decoration: underline;
                }
            }
            .border-line{
                height: 1px;
                background-color: var(--grey5);
            }
        }
        .overlay{
            background-color: rgba(0,0,0,0.2);
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 992;
        }
        .message-statements-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            gap: 24px;
            .message-image{
                height: 72px;
                width: 72px;
                margin-bottom: 8px;
            }
            .text{
                text-align: center;
                color: var(--neutralGrey85);
            }
        }  
        .pagination-wrapper{
            margin-top: 24px;
        }        
    }
}