.row-wrap{
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    border-bottom: 1px solid var(--grey5);
    &.no-border{
        border-bottom: none;
    }
    .left-side{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 261px;
        max-width: calc(86%);
        .dot{
            height: 16px;
            width: 16px;
            border-radius: 50%;
        }
        .member-name{
            display: inline-block;
            color: var(--neutralGrey85);
            padding-left: 8px;
            padding-right: 8px;
            width: 248px;
            max-width: calc(80%);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .member-percentage{
        color: var(--neutralGrey85);
        padding-left: 8px;
    }
}
