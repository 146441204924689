.overview-component{
    width: 100%;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    justify-content: center;
    background-color: var(--secondaryDarkestBlue);
    &.mobile{
        padding-left: 16px;
        padding-right: 16px;
    }
}