.desktop-document-center-header{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 24px;
    .display-pages{
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        background-color: var(--neutralBackgroundGrey);
        border-bottom: 1px solid var(--neutralGrey15);
        position: relative;
        gap: 24px;
        .show-page{
            padding: 16px 0px;
            cursor: pointer;
            border-bottom: 6px solid transparent;
            position: relative;
            bottom: -1px;
            display: block;
            span, &:after{
                font: 16px/18px 'Open Sans';
                text-align: center;
                display: block;
                width: 100%;
            }
            span{
                width: 100%;
                color: var(--neutralGrey75);
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%,-50%);
                letter-spacing: .35px;
            }
            &:after {
                content:attr(title);
                font-weight: 700;
                opacity: 0;
                color: inherit;
                pointer-events: none;
                padding: 0px 16px;
            }
            &.active {
                border-bottom-color: var(--primaryBrandBlue);
            
                span{
                    color: var(--primaryBrandBlue);
                    font-weight: 700;
                    letter-spacing: -.25px;
                }
            }
        }
    }
}