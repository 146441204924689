.beneficiary-summary{

    .container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 48px;
        padding-right: 56px;

        .desktop{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: var(--white);
            border-radius: 8px;
            margin-top: 32px;
            width: 100%;
            max-width: 1120px;
            margin-bottom: 64px;
            min-height: 607px;
            .content-wrapper {
                border-radius: 8px !important;
                width: 100%;

                .title-lockup {
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 32px;
                }

                .content {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 16px;

                    .Beneficiary-Details{
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .Chart-Header-Line {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: 16px;

                            .Header-Text{
                                color: var(--neutralGrey85);
                            }
                            .Allocation{
                                padding-right: 160px;
                            }
                        }

                        .Display-Beneficiaries{
                            display: flex;
                            flex-direction: column;
                            border-bottom: 1px solid var(--grey5);
                            padding: 16px 0;
                            .Row-Wrapper{
                                display: flex;
                                flex-direction: row;
                                flex-grow: 1;
                                position: relative;
                                justify-content: space-between;
                                .Name-Percentage-Arrow-Wrap{
                                    display: flex;
                                    flex-direction: row;
                                    flex-grow: 1;
                                    align-items: center;
                                    justify-content: space-between;
                                    .Dot{
                                        position: absolute;
                                        margin-top: 6px;
                                        top: 12px;
                                        transform: translate(-200%, -50%);
                                        height: 16px;
                                        width: 16px;
                                        border-radius: 50%;
                                        margin-left: 32px;
                                    }
                                    .No-Dot{
                                        position: absolute;
                                    }
                                    .Member-Name{
                                        display: flex;
                                        flex-flow: column;
                                        color: var(--headerBlack);
                                        position: relative;
                                        margin-left: 24px;
                                        &.one{
                                            margin-left: 0px;
                                        }
                                        .Member-Name:only-of-type{
                                            margin-left: 0px;
                                        }
                                        .Person-Text{
                                            font-size: 14px;
                                            line-height: 16px;
                                            color: var(--grey);
                                        }
                                    }   
                                    .Percentage-Arrow-Wrap{
                                        display: flex;
                                        flex-direction: row;

                                        .Member-Percentage{
                                            margin-right: 139px;
                                            font-size: 18px;
                                            line-height: 32px;
                                            font-weight: 600;
                                        }
                                    }             
                                    .Arrow{
                                        cursor: pointer;
                                    }
                                }
                                .menu-wrap{
                                    position: absolute;
                                    margin-left: 60px;
                                    top: -1px;
                                    z-index: 991;
                                    display: flex;
                                    flex-direction: column;
                                    height: 108px;
                                    width: 153px;
                                    background-color: var(--white);
                                    box-shadow: 0px 8px 25px rgba(0, 69, 106, 0.1);
                                    .menu-row-wrap{
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        gap: 8px;
                                        padding: 16px;
                                        border-bottom: 1px solid var(--grey5);
                                        cursor: pointer;
                                        &:last-child{
                                            border: none;
                                        }
                                        .edit-profile-text{
                                            color: var(--primaryBrandBlue);
                                        }
                                        .delete-text{
                                            color: var(--darkRed);
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                border: none !important;
                            }
                        }
                    }
                    .Pie-Chart{
                        margin-right: 52px;
                        margin-left: 88px;
                        min-width: 260px;
                        .Pie-Size{
                            height: 260px;
                            width: 260px;
                        }
                        @media only screen and (max-width: 1114px) {
                            padding-top: 0px;
                            display: flex;
                            flex-grow: 1;
                            flex-direction: row;
                            justify-content: center;
                            margin-bottom: 48px;
                        }    
                    }
                }
            }
            
        }
    }
    // @keyframes closeAlertMessage{
    //     0% {
    //         height: 56px;
    //     }
    //     100% {
    //         height: 0px;
    //     }
    // }
} 
.beneficiary-page-api-error-state-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 64px;
    padding-left: 48px;
    padding-right: 56px;
    .beneficary-page-api-error-state{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1120px;
        background-color: var(--white);
        padding: 48px;
        border-radius: 8px;
        .sub-text{
            margin-top: -16px;
        }
        .message-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 32px;
            .error-image{
                height: 64px;
                width: 64px;
                margin-bottom: 24px;
            }
            .title-text{
                color: var(--neutralGrey85);
                margin-bottom: 8px;
            }
            .sub-title-text{
                color: var(--neutralGrey75);
            }
        }
    }
}

.beneficiary-summary-loading-state{
    display: flex;
    // flex-direction: row;
    justify-content: center;
    width: 100%;
    // padding-left: 48px;
    // padding-right: 56px;
    .container{
        display: flex;
        flex-direction: column;
        margin-left: 48px;
        margin-right: 56px;
        width: 100%;
        max-width: 1120px;
        min-height: 607px;
        background-color: var(--white);
        border-radius: 8px;
        margin-top: 32px;
        margin-bottom: 64px;
        .loading-box{
            flex: 1;
            background-color: var(--grey5);
            border-radius: 8px;
        }
    }
    // .top-line{
    //     height: 32px;
    //     width: 278px;
    //     background-color: var(--grey5);
    //     margin-top: 32px;
    // }
    // .bottom-box{
    //     height: 365px;
    //     width: 100%;
    //     background-color: var(--grey5);
    //     margin-top: 24px;
    // }
}
