.results-accounts-created-wrap{
    &.Mobile{
        display: flex;
        flex-direction: column;
        min-height: 565px;
        justify-content: center;
    }
    .Button{
        &.mobile{
            max-width: 448px;
        }
    }
}

.yodlee-account-created-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .image{
        height: 64px;
        width: 64px;
    }
    .title-text{
        color: var(--primaryGrey75);
        margin-top: 24px;
        &.Mobile{
            margin-bottom: 16px;
        }
        &.margin{
            margin-bottom: 24px;
        }
    }
    .sub-title-text{
        color: var(--primaryGrey75);
        text-align: center;
        max-width: 615px;
        &.margin{
            margin-top: 24px;
            margin-bottom: 24px;
        }
        &.Mobile{
            max-width: 448px;
        }
    }
}

.bank-linkage-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}

.yodlee-return-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
}
