.yodlee-priming-screen-wrap{
    display: flex;
    flex-direction: column;
    .header-wrap{
        display: flex;
        flex-direction: column;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid var(--grey5);
        gap: 4px;
        .header{
            color: var(--neutralGrey55);
        }
        .sub-header{
            color: var(--neutralGrey85);
        }
        &.Mobile{
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
    }
    .data-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .content-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 616px;
            .image{
                margin-bottom: 24px;
            }
            .title-text{
                width: 100%;
                max-width: 400px;
                text-align: center;
                margin-bottom: 24px;
            }
            .text-wrap{
                display: flex;
                align-self: flex-start;
                gap: 8px;
                &.margin{
                    margin-bottom: 16px;
                }
                .checkmark{
                    height: 24px;
                    width: 24px;
                }
                .right-side{
                    display: flex;
                    flex-direction: column;
                }
            }
            .consent-text{
                color: var(--neutralGrey55);
                margin-top: 32px;
                margin-bottom: 48px;
            }
        }
        .button-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-top: 24px;
            border-top: 1px solid var(--grey5);
            .left-side{
                display: flex;
                gap: 8px;
                cursor: pointer;
                .blue-text{
                    color: var(--primaryBrandBlue);
                }
            }
            .right-side{
                display: flex;
                gap: 16px;
            }
        }
        .Button{
            &.mobile{
                width: 100%;
            }
        }
    }
}