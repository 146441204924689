.notification-wrapper {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
}

.notification-wrapper.information-notification {
    border: 1px solid var(--borderBlue);
    background-color: var(--lightestBlue);
    &.graceperiod{
        cursor: pointer;
    }
}

.notification-wrapper.warning-notification {
    border: 1px solid var(--lightWarningYellowBorder);
    background-color: var(--lightWarningYellow);
}

.notification-wrapper.confirmation-notification {
    border: 1px solid var(--borderGreen);
    background-color: var(--softGreen);
}

.notification-wrapper.error-notification {
    border: 1px solid var(--errorBorder);
    background-color: var(--errorBackground);

    .notification-header-text {
        color: var(--darkRed);
    }
}

.message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    &.bumpuprate{
        flex-direction: row;
    }
}

.sub-header-text {
    color: var(--neutralGrey85);
}

.notification-link {
    color: var(--primaryBrandBlue);
    cursor: pointer;
}

.notification-link:hover {
    text-decoration: underline;
}

.notification-bump-row{
    display: flex;
    align-items: center;
    &.Mobile{
        display: inline-block;
        .dash-line{
            width: 16px;
            border-top: 1px solid var(--primaryGrey75);
        }
    }
    .dash-line{
        // width: 100%;
        width: 16px;
        border-top: 1px solid var(--primaryGrey75);
        &.Mobile{
            display: inline-block;
            min-width: 16px;
            max-width: 16px;
            vertical-align: middle;    
        }
    }
}

.title-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.bumpuprate{
        width: 100%;
        align-items: center;
    }
    &.mobile-bump{
        align-items: flex-start;
    }

    .close-button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        &.mobile-bump{
            width: 32px;
            height: 32px;
            margin-top: -4px;
        }
    }

    .left-side {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px;
        &.bumpuprate{
            display: inline-block;
        }        
    }

    .right-side {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 3px;
        &.graceperiod{
            padding-top: 0px;
            align-items: center;
        }
    }
}

.grace-period-image-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.Mobile{
        justify-content: flex-start;
    }
}

.notification-icon {
    height: 32px;
    width: 32px;
    &.grace-period{
        height: 36px;
        width: 36px;
    }
}