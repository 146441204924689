.Mobile-SideNavigation-Wrapper{
    width: 100%;
    height: var(--mobileSideBarHeight);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    border-top: 1px solid var(--grey2);
    border-bottom: 1px solid var(--grey2);
    .Navigation-Line{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-around;
        align-items: center;
        .Nav-Wrap{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            cursor: pointer;
            .Nav-Text{
                padding-top: 4px;
            }
        }
        .Focused{
            color: var(--primaryBrandBlue) !important;
        }
    }
}