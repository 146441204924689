.mobile-account-overview {
  min-height: calc(100vh - var(--mobileHeaderHeight) - var(--mobileSideBarHeight));
  .toast-wrap{
    position: fixed;
    bottom: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .accounts-displayed {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;
    .account-type-wrapper {
      margin-top: 24px;
      .account-title-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }
      .account-title {
        color: var(--neutralGrey85);
      }
    }
    .account-box-wrapper {
      margin-bottom: 10px;
    }
    .banner-wrap{
      display: flex;
      margin-top: 16px;
      position: relative;
      .banner-image{
        resize: horizontal;
        width: 100%;
      }
      .Button{
        position: absolute;
        cursor: pointer;
        left: 3.6%;
        bottom: 6%;
        height: 20.6%;
        width: 66%;
      }
      .tooltip-wrap-one{
        width: 28px;
        height: 28px;
        position: absolute;
        left: 78.6%;
        top: 36.5%;
        background-color: var(--secondayLightBlue);
      }
      .tooltip-wrap-two{
        position: absolute;
        left: 84%;
        top: 50%;
        background-color: var(--secondayLightBlue);
        width: 28px;
        height: 28px;
        border-radius: 13px;
      }
      .tooltip-wrap-three{
        position: absolute;
        left: 75.5%;
        top: 34%;
        width: 28px;
        height: 28px;
        background-color: var(--secondayLightBlue);
      }
      .tooltip{
        cursor: pointer;
      }
    }
  }
  .products-modal-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 32px;
    .products-text{
      color: var(--neutralGrey75);
    }
    .products-link{
      color: var(--primaryBrandBlue);
      cursor: pointer;
    }
  }
  .new-account-button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .customer-notifications-wrapper{
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
  .customer-notifications-row{
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
}
